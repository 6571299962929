import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTimeISO: { input: string; output: string };
};

export type Anonymous = {
  __typename?: "Anonymous";
  uuid: Scalars["String"]["output"];
};

export type AppRefreshResult = AppRefreshReturn | ErrorReturn;

export type AppRefreshReturn = {
  __typename?: "AppRefreshReturn";
  accessToken: Scalars["String"]["output"];
  sessionId: Scalars["String"]["output"];
};

export type AssignNfcTagToQrCodeResult = ErrorReturn | NfcTag;

export type AuthUser = {
  __typename?: "AuthUser";
  email: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

/** Generic interface to be implemented by specific error returns */
export type BackendError = {
  i18nKey: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  statusCode: StatusCodes;
};

export type Booking = {
  __typename?: "Booking";
  cancelledAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  endsAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  externalId: Maybe<Scalars["String"]["output"]>;
  featureType: BookingFeatureType;
  id: Scalars["String"]["output"];
  provider: Maybe<BookingProvider>;
  startsAt: Scalars["DateTimeISO"]["output"];
  status: Maybe<Scalars["String"]["output"]>;
  subscriptionAmount: Scalars["Float"]["output"];
  timestamp: Timestamp;
  trialEndsAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  type: BookingType;
  user: User;
  venue: Venue;
};

export enum BookingFeatureType {
  Basic = "basic",
  Free = "free",
  Premium = "premium",
}

export type BookingFilterInput = {
  active: InputMaybe<Scalars["Boolean"]["input"]>;
  inactive: InputMaybe<Scalars["Boolean"]["input"]>;
  upcoming: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum BookingProvider {
  Manual = "manual",
  Paypal = "paypal",
  Stripe = "stripe",
}

export enum BookingType {
  Free = "free",
  Payment = "payment",
  Subscription = "subscription",
}

export type CancelBookingResult = Booking | ErrorReturn;

export type Chat = {
  __typename?: "Chat";
  id: Scalars["String"]["output"];
  messages: Array<ChatMessage>;
};

export type ChatMessage = {
  __typename?: "ChatMessage";
  date: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  sender: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  type: MessageType;
};

export type Circle = {
  __typename?: "Circle";
  center: RequiredLatLng;
  id: Scalars["String"]["output"];
  radius: Scalars["Float"]["output"];
};

export type CircleInput = {
  center: LatLngRequiredInput;
  id: Scalars["String"]["input"];
  radius: Scalars["Float"]["input"];
};

export type ColorType = {
  cyan: Scalars["Float"]["input"];
  key: Scalars["Float"]["input"];
  magenta: Scalars["Float"]["input"];
  yellow: Scalars["Float"]["input"];
};

export type CoordinatesInput = {
  altitude: InputMaybe<Scalars["Float"]["input"]>;
  lat: InputMaybe<Scalars["Float"]["input"]>;
  lng: InputMaybe<Scalars["Float"]["input"]>;
};

export type CoordinatesNonNull = {
  __typename?: "CoordinatesNonNull";
  address: Scalars["String"]["output"];
  altitude: Scalars["Float"]["output"];
  lat: Scalars["Float"]["output"];
  lng: Scalars["Float"]["output"];
  radius: Scalars["Float"]["output"];
};

export type CoordinatesRequiredInput = {
  altitude: Scalars["Float"]["input"];
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
};

export type CreateBookingResult = CreateBookingSuccess | ErrorReturn;

export type CreateBookingSuccess = {
  __typename?: "CreateBookingSuccess";
  url: Maybe<Scalars["String"]["output"]>;
};

export type CreateLocationResult = ErrorReturn | Location;

export type CreateManualPaymentResult = ErrorReturn | GraphQlBooking;

export type CreateManualSubscriptionResult = ErrorReturn | GraphQlBooking;

export type CreateMultipleLocationsResult = ErrorReturn | LocationList;

export type CreatePermissionInput = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  role: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
  venueName: Scalars["String"]["input"];
};

export type CreatePermissionResult = ErrorReturn | Permission;

export type CreatePositionBatchedInput = {
  count: Scalars["Int"]["input"];
  countInternal: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type CreatePrintJobInput = {
  color: PrintJobColorType;
  count: Scalars["Int"]["input"];
  format: Format;
  settings: PrintJobSettingsType;
  template: Template;
  venueId: Scalars["String"]["input"];
};

export type CreateVenueResult = CreateVenueSuccessResult | ErrorReturn;

export type CreateVenueSuccessResult = {
  __typename?: "CreateVenueSuccessResult";
  accessToken: Scalars["String"]["output"];
  venue: Venue;
};

export type CurrentPosition = {
  __typename?: "CurrentPosition";
  createdAt: Scalars["DateTimeISO"]["output"];
  lat: Scalars["Float"]["output"];
  lng: Scalars["Float"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CurrentSecurityPositionsReturn = CurrentSecurityPositionsSuccessResult | ErrorReturn;

export type CurrentSecurityPositionsSuccessResult = {
  __typename?: "CurrentSecurityPositionsSuccessResult";
  positions: Array<RequiredLatLng>;
};

export type DeleteFloorResult = ErrorReturn | SuccessReturn;

export type DeletePositionResult = ErrorReturn | SuccessReturn;

export type EditLocationResult = ErrorReturn | Location;

export type EditPermissionResult = ErrorReturn | Permission;

export type EditPositionResult = ErrorReturn | Position;

export type EditVenueDetailsResult = ErrorReturn | VenueDetails;

/** Generic error return, currently used in many placed. Replace by more specific errors */
export type ErrorReturn = BackendError & {
  __typename?: "ErrorReturn";
  i18nKey: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  statusCode: StatusCodes;
};

export enum Format {
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A6 = "A6",
}

export type GeofenceAreaInput = {
  circles: Array<CircleInput>;
  polygons: Array<PolygonInput>;
};

export type Geofencing = {
  __typename?: "Geofencing";
  circles: Array<Circle>;
  polygons: Array<Polygon>;
  requirePosition: Scalars["Boolean"]["output"];
  requirePositionForQRCodes: Scalars["Boolean"]["output"];
  requirePositionForVenue: Scalars["Boolean"]["output"];
};

export type GetQrCodeReturn = ErrorReturn | GetQrCodeSuccessResult;

export type GetQrCodeSuccessResult = {
  __typename?: "GetQRCodeSuccessResult";
  qRCode: QrCode;
};

export type GraphQlBooking = {
  __typename?: "GraphQLBooking";
  cancelledAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  endsAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  startsAt: Scalars["DateTimeISO"]["output"];
  status: Maybe<Scalars["String"]["output"]>;
  timestamp: Timestamp;
  trialEndsAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  type: BookingType;
  user: User;
  venue: Venue;
};

export type HelpRequest = {
  __typename?: "HelpRequest";
  date: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  location: Maybe<Scalars["String"]["output"]>;
  person: Person;
  position: Maybe<Scalars["String"]["output"]>;
  situation: Situation;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type HelpStream = {
  __typename?: "HelpStream";
  entries: Array<HelpStreamEntry>;
  venueId: Scalars["String"]["output"];
};

export type HelpStreamEntry = {
  __typename?: "HelpStreamEntry";
  date: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  isOutdated: Scalars["Boolean"]["output"];
  language: Scalars["String"]["output"];
  lastMessageFrom: Maybe<Scalars["DateTimeISO"]["output"]>;
  location: Maybe<Scalars["String"]["output"]>;
  person: Maybe<Person>;
  position: Maybe<Scalars["String"]["output"]>;
  situation: Maybe<Situation>;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  uuid: Scalars["String"]["output"];
  venue: Scalars["String"]["output"];
  venueId: Scalars["String"]["output"];
};

export type HelpStreamReturn = ErrorReturn | PaginatedHelpStreamResponse;

export enum Items {
  OneTimeFixed = "ONE_TIME_FIXED",
  SubscriptionFixed = "SUBSCRIPTION_FIXED",
}

export enum InputValueType {
  Boolean = "boolean",
  BooleanArray = "booleanArray",
  Float = "float",
  FloatArray = "floatArray",
  Int = "int",
  IntArray = "intArray",
  Number = "number",
  NumberArray = "numberArray",
  String = "string",
  StringArray = "stringArray",
}

export type LatLngRequiredInput = {
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
};

export type Location = {
  __typename?: "Location";
  coordinates: Maybe<CoordinatesNonNull>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  positions: Array<Position>;
  timestamp: Timestamp;
  venue: Venue;
};

export type LocationList = {
  __typename?: "LocationList";
  locations: Array<Location>;
};

export type MagicLinkForAppResult = ErrorReturn | MagicLinkReturn;

export type MagicLinkReturn = {
  __typename?: "MagicLinkReturn";
  uuid: Scalars["String"]["output"];
};

export type MarginType = {
  bleed: Scalars["Float"]["input"];
  margin: Scalars["Float"]["input"];
};

export type MeResult = Anonymous | ErrorReturn | User;

export enum MessageType {
  PositionEnded = "positionEnded",
  PositionShared = "positionShared",
  Text = "text",
}

export type Mutation = {
  __typename?: "Mutation";
  /**
   *
   *     # Activate QR Codes for a certain amount of hours
   *
   *     ## Used by
   *
   *     - Security (Mobile)
   *
   */
  activateHours: Venue;
  /**
   *
   *     # Refresh access token
   *
   *     Since access tokens are short-lived, they need to be refreshed regularly. This can be done by
   *     providing a valid session id which in stored in Redis. A session is valid for 7 days and will
   *     be refresh also, meaning valid 7 days after the last refresh.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  appRefresh: AppRefreshResult;
  assignNfcTagToQrCode: AssignNfcTagToQrCodeResult;
  assignQRCode: Scalars["Boolean"]["output"];
  /**
   *
   *     # Cancel a booking
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  cancelBooking: Maybe<CancelBookingResult>;
  /**
   *
   *     # TODO: Add description
   *
   *     ## Used by
   *
   *     -
   *
   */
  createAutomaticResponse: SuccessOrErrorReturnResult;
  /**
   *
   *     # TODO: Add description
   *
   *     ## Used by
   *
   *     -
   *
   */
  createHelpRequestResponse: Scalars["Boolean"]["output"];
  /**
   *
   *     # Create a new area/location for a venue
   *
   *     This mutation allows authorized users to create a new location (or area) within a specific venue.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *     - Security (Mobile)
   *
   *     ## Permissions Required
   *     To create a location, the user must meet **one** of the following conditions:
   *
   *     ### ** Global Permission**
   *     - **user:role=Admin** → Grants global admin access (can manage all venues).
   *
   *     ### ** Venue-Specific Permissions**
   *     For users without global admin rights, they must have **one** of the following roles **for the venue where the location is being created**:
   *
   *     - **permission:venueId,role=Admin** → Can fully manage the venue.
   *     - **permission:venueId,role=Owner** → Venue owner, has full permissions.
   *     - **permission:venueId,role=Security** → May need to create locations when assigning QR Codes.
   *     - **permission:venueId,role=SecurityTeam** → May need to create locations when assigning QR Codes.
   *     - **permission:venueId,role=Awareness** → May need to create locations when assigning QR Codes.
   *     - **permission:venueId,role=AwarenessTeam** → May need to create locations when assigning QR Codes.
   *
   */
  createLocation: CreateLocationResult;
  /**
   *
   *     # Create a manual payment for a booking
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  createManualPayment: CreateManualPaymentResult;
  /**
   *
   *     # Create a manual subscription for a booking
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  createManualSubscription: CreateManualSubscriptionResult;
  /**
   *
   *     # Create multiple new areas/locations for a venue
   *
   *     This mutation allows authorized users to create new locations (or areas) within a specific venue.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *     - Security (Mobile)
   *
   *     ## Permissions Required
   *     To create locations, the user must meet **one** of the following conditions:
   *
   *     ### ** Global Permission**
   *     - **user:role=Admin** → Grants global admin access (can manage all venues).
   *
   *     ### ** Venue-Specific Permissions**
   *     For users without global admin rights, they must have **one** of the following roles **for the venue where the location is being created**:
   *
   *     - **permission:venueId,role=Admin** → Can fully manage the venue.
   *     - **permission:venueId,role=Owner** → Venue owner, has full permissions.
   *
   */
  createMultipleLocations: CreateMultipleLocationsResult;
  /**
   *
   *     # Create a payment for a venue
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  createPayment: CreateBookingResult;
  createPermission: CreatePermissionResult;
  createPositionBatched: Array<Location>;
  createPrintJob: PrintJob;
  /**
   *
   *     # Create a dynamic QR code
   *
   *     ## Used by
   *
   *     - Admin (web)
   *
   */
  createQRCodeDynamic: QrCodeDynamic;
  createQRCodeHelp: QrCodeHelp;
  createQRCodeInternalChat: QrCodeInternalChat;
  /**
   *
   *     # Create a subscription for a venue.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  createSubscription: CreateBookingResult;
  /**
   *
   *     # Creates a test venue by name only and adds 2 test QR Codes
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  createTestVenue: Venue;
  /**
   *
   *     # Create a trial for a venue.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  createTrial: CreateBookingResult;
  createVenue: CreateVenueResult;
  /**
   *
   *     # Delete a location
   *
   *     This mutation allows authorized users to delete a location.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   *     ## Permissions Required
   *     To delete a location, the user must meet **one** of the following conditions:
   *
   *     ### ** Global Permission**
   *     - **user:role=Admin** → Grants global admin access (can manage all venues).
   *
   *     ### ** Venue-Specific Permissions**
   *     For users without global admin rights, they must have **one** of the following roles **for the venue where the location is being deleted**:
   *
   *     - **permission:venueId,role=Admin** → Can fully manage the venue.
   *     - **permission:venueId,role=Owner** → Venue owner, has full permissions.
   *
   */
  deleteLocation: DeleteFloorResult;
  deletePermission: Scalars["String"]["output"];
  deletePosition: DeletePositionResult;
  deleteUser: AuthUser;
  deleteUserCodeConfirmation: Scalars["Boolean"]["output"];
  deleteUserTokenConfirmation: Scalars["Boolean"]["output"];
  /**
   *
   *     # Edit a location's name
   *
   *     This mutation allows authorized users to edit a location's name.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   *     ## Permissions Required
   *     To edit a location's name, the user must meet **one** of the following conditions:
   *
   *     ### ** Global Permission**
   *     - **user:role=Admin** → Grants global admin access (can manage all venues).
   *
   *     ### ** Venue-Specific Permissions**
   *     For users without global admin rights, they must have **one** of the following roles **for the venue where the location is being edited**:
   *
   *     - **permission:venueId,role=Admin** → Can fully manage the venue.
   *     - **permission:venueId,role=Owner** → Venue owner, has full permissions.
   *     - **permission:venueId,role=Security** → May need to edit a location's name when assigning QR Codes.
   *     - **permission:venueId,role=SecurityTeam** → May need to edit a location's name when assigning QR Codes.
   *     - **permission:venueId,role=Awareness** → May need to edit a location's name when assigning QR Codes.
   *     - **permission:venueId,role=AwarenessTeam** → May need to edit a location's name when assigning QR Codes.
   *
   */
  editLocationName: EditLocationResult;
  editPermission: EditPermissionResult;
  editPosition: EditPositionResult;
  editPositionCoordinates: EditPositionResult;
  editPositionLocation: EditPositionResult;
  editVenueActive: Venue;
  editVenueDetails: EditVenueDetailsResult;
  editVenueImage: Venue;
  editVenueName: Venue;
  editVenuePhoneNumber: Venue;
  /**
   *
   *     # Update inactivity timeout
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  editVenueSettingsInactivityTimeout: Venue;
  /**
   *
   *     # Get MagicLink [DEPRECATED]
   *
   *     ⚠️ This mutation is deprecated and should be removed after 2025-08-01.
   *
   *     Legacy description:
   *     Creates a 5-digit code that is stored in redis with a generated uuid for 15 minutes and sends
   *     the code to the given email address. If successful, it returns the uuid for the subsequent
   *     verification request. If no user is found with the given email address, it returns an error
   *     instead.
   *
   *     ## Used by
   *
   *     - Security (Mobile)
   *
   * @deprecated Use login with email or oauth instead
   */
  getMagicLinkForApp: MagicLinkForAppResult;
  /** @deprecated Switch to Query to allow for refetch */
  healthCheck: Scalars["String"]["output"];
  /**
   *
   *     # Logout
   *
   *     Logging out will clear the fcm token to not send push notifications to useres anymore as well as
   *     clearing their current session.
   *
   *     ## Used by
   *
   *     - Security (Mobile)
   *     - Admin (Web)
   *
   */
  logoutMobile: SuccessReturn;
  reassignNfcTagToQrCode: ReassignNfcTagToQrCodeResult;
  /**
   *
   *     # TODO: Add description
   *
   *     ## Used by
   *
   *     -
   *
   */
  resetCurrentPosition: Scalars["Boolean"]["output"];
  resetVenueArchived: Venue;
  retryPrintJob: PrintJob;
  sendMail: SuccessReturn;
  /**
   *
   *     # Sends a link to the given email address to reset the password
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  sendPasswordResetLink: SendPasswordResetLinkReturnType;
  setVenueArchived: Venue;
  setVenueDeleted: Venue;
  setVenueGeofence: SetVenueGeofenceResult;
  setVenueGeofenceSettings: SetVenueGeofenceResult;
  setVenueSituationsSettings: SetVenueSituationsSettingsResult;
  /**
   *
   *     # Verifies an idToken from firebase WITHOUT creating a new user
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  signInWithIdToken: SignInWithIdTokenReturnType;
  /**
   *
   *     # Register
   *
   *     Registers a new user with the given email address. If a user with the email address already
   *     exists, it return an error response instead. Note, all users are currently registered as owners
   *     by default. Security staff has to be invited after the creation of a venue. Any user,
   *     irrespective if he or shes has been registered globally as an owner, can be given permissions
   *     as 'security' or 'owner' for a given venue.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   *     ## Side effects
   *
   *     - Sends out a notification to 'saferspaces' via Slack with the registered email address.
   *
   */
  signup: RegisterResult;
  /**
   *
   *     # Register
   *
   *     Registers a new user with the given email address from the idToken. If a user with the email
   *     address already exists, it return an error response instead. Note, all users are currently
   *     registered as owners by default. Security staff has to be invited after the creation of a venue.
   *     Any user, irrespective if he or shes has been registered globally as an owner, can be given
   *     permissions as 'security' or 'owner' for a given venue.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   *     ## Side effects
   *
   *     - Sends out a notification to 'saferspaces' via Slack with the registered email address.
   *
   */
  signupWithIdToken: RegisterResult;
  /**
   *
   *     # TODO: Add description
   *
   *     ## Used by
   *
   *     -
   *
   */
  submitDynamicRequest: Scalars["String"]["output"];
  /**
   *
   *     # Scan of a visitor code
   *
   *     ## Used by
   *
   *     -
   *
   */
  submitHelpRequest: Scalars["String"]["output"];
  /**
   *
   *     # Scan of a staff code
   *
   *     ## Used by
   *
   *     -
   *
   */
  submitInternalHelpRequest: Scalars["String"]["output"];
  /**
   *
   *     # Scan of a venue code
   *
   *     ## Used by
   *
   *     -
   *
   */
  submitVenueHelpRequest: Scalars["String"]["output"];
  /**
   *
   *     # Toggle active status of QR Codes
   *
   *     ## Used by
   *
   *     - Security (Mobile)
   *     - Admin (Web)
   *
   */
  toggleIsActive: Venue;
  toggleNotifications: Notification;
  /**
   *
   *     # Activate QR Codes for a certain amount of hours
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  toggleVenueActiveFeedback: Venue;
  unassignNfcTagFromQrCode: UnassignNfcTagFromQrCodeResult;
  /**
   *
   *     # TODO: Add description
   *
   *     ## Used by
   *
   *     -
   *
   */
  updateCurrentPosition: Scalars["String"]["output"];
  updateFCMToken: UpdateUserResult;
  updateLanguage: Scalars["String"]["output"];
  updateLastCharacterTypedAt: Scalars["Boolean"]["output"];
  updateLocationCoordinates: Location;
  updateName: UpdateUserResult;
  updateNotificationChannel: NotificationChannel;
  updatePhoneNumber: UpdateUserResult;
  updateSteps: QrCodeDynamic;
  /**
   *
   *     # Activate QR Codes for a certain amount of hours
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  updateVenueActiveFeedback: Venue;
  updateVenueCoordinates: Venue;
  updateVenueTheme: Maybe<VenueTheme>;
  updateVenueThemeText: Maybe<VenueTheme>;
  updateVenueThemeTextBulk: Maybe<VenueTheme>;
  upsertNotificationChannel: NotificationChannel;
  /**
   *
   *     # Verifies an idToken from firebase WITH creating a new user if not exists
   *
   *     ## Used by
   *
   *     - Security (Mobile)
   *
   */
  verifyIdToken: VerifyIdTokenReturnType;
  /**
   *
   *     # Verify MagicLink [DEPRECATED]
   *
   *     ⚠️ This mutation is deprecated and should be removed after 2025-08-01.
   *
   *     Legacy description:
   *     Verifies the code stored in redis given uuid. If successfull, returns an accessToken (short
   *     lived for 15 minutes) and sessionId (valid for 7 days, stored in redis). Important: To not
   *     create multiple sessions for the same user, the session id currently equals the user id. This is
   *     highly unsatisfactory and should be adjusted as soon as possible.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *     - Security (Mobile)
   *
   *     ## Side effects
   *
   *     - Publishes an update for the onboarding step after a successful login
   *
   * @deprecated Use login with email or oauth instead
   */
  verifyMagicLink: VerifyMagicLinkResult;
  writeMessage: ChatMessage;
  writeVenueFeedback: Scalars["Boolean"]["output"];
};

export type MutationActivateHoursArgs = {
  hoursToActivate: Scalars["Int"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationAppRefreshArgs = {
  sessionId: Scalars["String"]["input"];
};

export type MutationAssignNfcTagToQrCodeArgs = {
  qrCodeId: Scalars["String"]["input"];
};

export type MutationAssignQrCodeArgs = {
  coordinates: InputMaybe<CoordinatesRequiredInput>;
  locationId: Scalars["String"]["input"];
  positionName: Scalars["String"]["input"];
  qrCodeId: Scalars["String"]["input"];
};

export type MutationCancelBookingArgs = {
  bookingId: Scalars["String"]["input"];
};

export type MutationCreateAutomaticResponseArgs = {
  uuid: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationCreateHelpRequestResponseArgs = {
  language: Scalars["String"]["input"];
  uuid: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationCreateLocationArgs = {
  name: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationCreateManualPaymentArgs = {
  bookingFeatureType: BookingFeatureType;
  capacity: Scalars["Int"]["input"];
  email: Scalars["String"]["input"];
  endsAt: Scalars["DateTimeISO"]["input"];
  startsAt: Scalars["DateTimeISO"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationCreateManualSubscriptionArgs = {
  bookingFeatureType: BookingFeatureType;
  capacity: Scalars["Int"]["input"];
  email: Scalars["String"]["input"];
  startsAt: Scalars["DateTimeISO"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationCreateMultipleLocationsArgs = {
  names: Array<Scalars["String"]["input"]>;
  venueId: Scalars["String"]["input"];
};

export type MutationCreatePaymentArgs = {
  capacity: Scalars["Int"]["input"];
  endsAt: Scalars["DateTimeISO"]["input"];
  startsAt: Scalars["DateTimeISO"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};

export type MutationCreatePositionBatchedArgs = {
  positions: Array<CreatePositionBatchedInput>;
  venueId: Scalars["String"]["input"];
};

export type MutationCreatePrintJobArgs = {
  input: CreatePrintJobInput;
};

export type MutationCreateQrCodeDynamicArgs = {
  receiver: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationCreateQrCodeHelpArgs = {
  hasChat: Scalars["Boolean"]["input"];
  locationId: Scalars["String"]["input"];
  name: InputMaybe<Scalars["String"]["input"]>;
  showQuestions: Scalars["Boolean"]["input"];
};

export type MutationCreateQrCodeInternalChatArgs = {
  hasPin: Scalars["Boolean"]["input"];
  locationId: Scalars["String"]["input"];
  name: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateSubscriptionArgs = {
  capacity: Scalars["Int"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationCreateTestVenueArgs = {
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  userId: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateTrialArgs = {
  venueId: Scalars["String"]["input"];
};

export type MutationCreateVenueArgs = {
  details: VenueDetailsInput;
  name: Scalars["String"]["input"];
};

export type MutationDeleteLocationArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeletePermissionArgs = {
  permissionId: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationDeletePositionArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteUserArgs = {
  email: Scalars["String"]["input"];
};

export type MutationDeleteUserCodeConfirmationArgs = {
  code: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
};

export type MutationDeleteUserTokenConfirmationArgs = {
  token: Scalars["String"]["input"];
};

export type MutationEditLocationNameArgs = {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationEditPermissionArgs = {
  permissionId: Scalars["String"]["input"];
  role: Roles;
  venueId: Scalars["String"]["input"];
};

export type MutationEditPositionArgs = {
  coordinates: InputMaybe<CoordinatesInput>;
  id: Scalars["String"]["input"];
  name: InputMaybe<Scalars["String"]["input"]>;
  venueId: Scalars["String"]["input"];
};

export type MutationEditPositionCoordinatesArgs = {
  coordinates: CoordinatesRequiredInput;
  positionId: Scalars["String"]["input"];
};

export type MutationEditPositionLocationArgs = {
  locationId: Scalars["String"]["input"];
  positionId: Scalars["String"]["input"];
};

export type MutationEditVenueActiveArgs = {
  endsAt: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  isActive: Scalars["Boolean"]["input"];
  isRequired: Scalars["Boolean"]["input"];
  startsAt: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  venueId: Scalars["String"]["input"];
};

export type MutationEditVenueDetailsArgs = {
  details: VenueDetailsInput;
  venueId: Scalars["String"]["input"];
};

export type MutationEditVenueImageArgs = {
  image: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationEditVenueNameArgs = {
  name: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationEditVenuePhoneNumberArgs = {
  phoneNumber: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationEditVenueSettingsInactivityTimeoutArgs = {
  inactivityTimeout: Scalars["Int"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationGetMagicLinkForAppArgs = {
  email: Scalars["String"]["input"];
};

export type MutationLogoutMobileArgs = {
  sessionId: Scalars["String"]["input"];
};

export type MutationReassignNfcTagToQrCodeArgs = {
  nfcTagId: Scalars["String"]["input"];
  qrCodeId: Scalars["String"]["input"];
};

export type MutationResetCurrentPositionArgs = {
  uuid: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationResetVenueArchivedArgs = {
  venueId: Scalars["String"]["input"];
};

export type MutationRetryPrintJobArgs = {
  id: Scalars["String"]["input"];
};

export type MutationSendMailArgs = {
  subject: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
};

export type MutationSendPasswordResetLinkArgs = {
  email: Scalars["String"]["input"];
};

export type MutationSetVenueArchivedArgs = {
  venueId: Scalars["String"]["input"];
};

export type MutationSetVenueDeletedArgs = {
  venueId: Scalars["String"]["input"];
};

export type MutationSetVenueGeofenceArgs = {
  input: SetVenueGeofenceInput;
};

export type MutationSetVenueGeofenceSettingsArgs = {
  input: SetVenueGeofenceSettingsInput;
};

export type MutationSetVenueSituationsSettingsArgs = {
  input: SetVenueSituationsSettingsInput;
};

export type MutationSignInWithIdTokenArgs = {
  idToken: Scalars["String"]["input"];
};

export type MutationSignupArgs = {
  email: Scalars["String"]["input"];
};

export type MutationSignupWithIdTokenArgs = {
  idToken: Scalars["String"]["input"];
};

export type MutationSubmitDynamicRequestArgs = {
  coordinates: CoordinatesInput;
  input: Array<SubmitDynamicRequestInput>;
  language: Scalars["String"]["input"];
  qrCodeId: Scalars["String"]["input"];
};

export type MutationSubmitHelpRequestArgs = {
  coordinates: CoordinatesInput;
  language: Scalars["String"]["input"];
  person: InputMaybe<Person>;
  qrCodeId: Scalars["String"]["input"];
  situation: InputMaybe<Situation>;
};

export type MutationSubmitInternalHelpRequestArgs = {
  language: Scalars["String"]["input"];
  qrCodeId: Scalars["String"]["input"];
  severe: Scalars["Boolean"]["input"];
};

export type MutationSubmitVenueHelpRequestArgs = {
  input: SubmitVenueHelpRequestInput;
};

export type MutationToggleIsActiveArgs = {
  isActive: Scalars["Boolean"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationToggleNotificationsArgs = {
  enabled: Scalars["Boolean"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationToggleVenueActiveFeedbackArgs = {
  venueId: Scalars["String"]["input"];
};

export type MutationUnassignNfcTagFromQrCodeArgs = {
  nfcTagId: Scalars["String"]["input"];
};

export type MutationUpdateCurrentPositionArgs = {
  coordinates: CoordinatesRequiredInput;
  uuid: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationUpdateFcmTokenArgs = {
  token: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateLanguageArgs = {
  language: Scalars["String"]["input"];
};

export type MutationUpdateLastCharacterTypedAtArgs = {
  input: UpdateTypingInput;
};

export type MutationUpdateLocationCoordinatesArgs = {
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  locationId: Scalars["String"]["input"];
  radius: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateNameArgs = {
  name: Scalars["String"]["input"];
};

export type MutationUpdateNotificationChannelArgs = {
  input: UpdateNotificationChannelInput;
};

export type MutationUpdatePhoneNumberArgs = {
  phoneNumber: Scalars["String"]["input"];
};

export type MutationUpdateStepsArgs = {
  id: Scalars["String"]["input"];
  steps: Scalars["String"]["input"];
};

export type MutationUpdateVenueActiveFeedbackArgs = {
  input: UpdateVenueActiveFeedbackInput;
  venueId: Scalars["String"]["input"];
};

export type MutationUpdateVenueCoordinatesArgs = {
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  radius: InputMaybe<Scalars["Float"]["input"]>;
  venueId: Scalars["String"]["input"];
};

export type MutationUpdateVenueThemeArgs = {
  colors: VenueThemeColorsInput;
  venueId: Scalars["String"]["input"];
};

export type MutationUpdateVenueThemeTextArgs = {
  input: UpdateVenueThemeTextInput;
  venueId: Scalars["String"]["input"];
};

export type MutationUpdateVenueThemeTextBulkArgs = {
  input: Array<UpdateVenueThemeTextInput>;
  venueId: Scalars["String"]["input"];
};

export type MutationUpsertNotificationChannelArgs = {
  id: InputMaybe<Scalars["String"]["input"]>;
  notificationChannelType: NotificationChannelType;
  receiver: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationVerifyIdTokenArgs = {
  idToken: Scalars["String"]["input"];
};

export type MutationVerifyMagicLinkArgs = {
  code: Scalars["String"]["input"];
  uuid: Scalars["String"]["input"];
};

export type MutationWriteMessageArgs = {
  text: Scalars["String"]["input"];
  uuid: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type MutationWriteVenueFeedbackArgs = {
  input: Array<Array<VenueFeedbackInput>>;
  venueId: Scalars["String"]["input"];
};

export type NfcTag = {
  __typename?: "NFCTag";
  id: Scalars["String"]["output"];
  qrCode: Maybe<QrCode>;
};

export type Notification = {
  __typename?: "Notification";
  enabled: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  notificationChannels: Array<NotificationChannel>;
  timestamp: Maybe<Timestamp>;
  type: NotificationType;
  typeId: Maybe<Scalars["String"]["output"]>;
};

export type NotificationChannel = {
  __typename?: "NotificationChannel";
  enabled: Scalars["Boolean"]["output"];
  errorAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  notification: Maybe<Notification>;
  receiver: Scalars["String"]["output"];
  timestamp: Maybe<Timestamp>;
  type: NotificationChannelType;
};

/** The channel for the notification */
export enum NotificationChannelType {
  Email = "email",
  Firebase = "firebase",
  Firestore = "firestore",
  Slack = "slack",
  Webhook = "webhook",
}

/** The type of the notification */
export enum NotificationType {
  Location = "location",
  Position = "position",
  QrCode = "qrCode",
  Venue = "venue",
}

export type OffsetType = {
  bottom: Scalars["Float"]["input"];
  left: Scalars["Float"]["input"];
};

export type PaginatedBookingResponse = {
  __typename?: "PaginatedBookingResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<Booking>;
  total: Scalars["Int"]["output"];
};

export type PaginatedBookingResult = ErrorReturn | PaginatedBookingResponse;

export type PaginatedHelpStreamResponse = {
  __typename?: "PaginatedHelpStreamResponse";
  items: Array<HelpRequest>;
  nextCursor: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Float"]["output"];
};

export type PaginatedPermissionResponse = {
  __typename?: "PaginatedPermissionResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<Permission>;
  total: Scalars["Int"]["output"];
};

export type PaginatedPrintJobResponse = {
  __typename?: "PaginatedPrintJobResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<PrintJob>;
  total: Scalars["Int"]["output"];
};

export type PaginatedQrCodeDynamicResponse = {
  __typename?: "PaginatedQRCodeDynamicResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<QrCodeDynamic>;
  total: Scalars["Int"]["output"];
};

export type PaginatedUserResponse = {
  __typename?: "PaginatedUserResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<User>;
  total: Scalars["Int"]["output"];
};

export type PaginatedVenueResponse = {
  __typename?: "PaginatedVenueResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<Venue>;
  total: Scalars["Int"]["output"];
};

export type PaginationInput = {
  skip: Scalars["Int"]["input"];
  take: Scalars["Int"]["input"];
};

export type Permission = {
  __typename?: "Permission";
  id: Scalars["String"]["output"];
  role: Roles;
  /** @deprecated Use `role` instead */
  roles: Array<Roles>;
  scopes: Array<Scopes>;
  user: User;
  userId: Scalars["Int"]["output"];
  venue: Venue;
  venueId: Scalars["String"]["output"];
};

export type PermissionForVenueResult = ErrorReturn | PermissionForVenueSuccess;

export type PermissionForVenueSuccess = {
  __typename?: "PermissionForVenueSuccess";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<Permission>;
  total: Scalars["Int"]["output"];
};

/** The role of the person in the situation */
export enum Person {
  /** Jemand vom Personal hat etwas beobachtet. */
  Staff = "staff",
  /** Es gibt keine näheren Information. */
  Unknown = "unknown",
  /** Die Person ist selbst betroffen. */
  Victim = "victim",
  /** Die Person hat etwas beobachtet. */
  Witness = "witness",
}

export type Polygon = {
  __typename?: "Polygon";
  coordinates: Array<RequiredLatLng>;
  id: Scalars["String"]["output"];
};

export type PolygonInput = {
  coordinates: Array<LatLngRequiredInput>;
  id: Scalars["String"]["input"];
};

export type Position = {
  __typename?: "Position";
  coordinates: Maybe<CoordinatesNonNull>;
  id: Scalars["String"]["output"];
  location: Location;
  name: Maybe<Scalars["String"]["output"]>;
  qrCodes: Maybe<Array<QrCode>>;
  timestamp: Timestamp;
};

export type Pricing = {
  __typename?: "Pricing";
  price: Scalars["Float"]["output"];
};

export type PrintJob = {
  __typename?: "PrintJob";
  format: Format;
  id: Scalars["String"]["output"];
  numberOfQRCodes: Scalars["Int"]["output"];
  path: Maybe<Scalars["String"]["output"]>;
  size: Maybe<Scalars["Int"]["output"]>;
  status: PrintJobStatus;
  template: Template;
  timestamp: Timestamp;
  venue: Venue;
};

export type PrintJobColorType = {
  background: InputMaybe<ColorType>;
  foreground: ColorType;
};

export type PrintJobSettingsType = {
  margin: MarginType;
  offset: OffsetType;
  showCropMarks: Scalars["Boolean"]["input"];
  showCutContour: Scalars["Boolean"]["input"];
  size: Scalars["Float"]["input"];
};

export enum PrintJobStatus {
  Cancelled = "CANCELLED",
  Error = "ERROR",
  Pending = "PENDING",
  Printed = "PRINTED",
  Printing = "PRINTING",
}

export type PrintJobsInput = {
  formats: Array<Format>;
  venueId: Scalars["String"]["input"];
};

export type QrCode = {
  __typename?: "QRCode";
  id: Scalars["String"]["output"];
  lastScan: Maybe<Scalars["DateTimeISO"]["output"]>;
  nfcTags: Maybe<Array<NfcTag>>;
  notifications: Array<Notification>;
  position: Maybe<Position>;
  qrCode: QrCodes;
  scanCount: Scalars["Int"]["output"];
  shortCode: Maybe<Scalars["String"]["output"]>;
  timestamp: Maybe<Timestamp>;
  venue: Maybe<Venue>;
};

export type QrCodeDynamic = {
  __typename?: "QRCodeDynamic";
  id: Scalars["String"]["output"];
  qrCode: QrCode;
  rawJson: Scalars["String"]["output"];
  steps: Array<QrCodeDynamicStep>;
};

export type QrCodeDynamicBinaryChoiceComponent = {
  __typename?: "QRCodeDynamicBinaryChoiceComponent";
  display: QrCodeDynamicBinaryChoiceComponentDisplay;
  state: QrCodeDynamicBinaryChoiceComponentState;
};

export type QrCodeDynamicBinaryChoiceComponentDisplay = {
  __typename?: "QRCodeDynamicBinaryChoiceComponentDisplay";
  color: QrCodeDynamicColor;
  labels: QrCodeDynamicBinaryChoiceComponentDisplayLabels;
  margin: Maybe<QrCodeDynamicMargin>;
  size: QrCodeDynamicSize;
};

export type QrCodeDynamicBinaryChoiceComponentDisplayLabels = {
  __typename?: "QRCodeDynamicBinaryChoiceComponentDisplayLabels";
  option1: Translatable;
  option2: Translatable;
};

export type QrCodeDynamicBinaryChoiceComponentState = {
  __typename?: "QRCodeDynamicBinaryChoiceComponentState";
  key: Scalars["String"]["output"];
  next: Maybe<Scalars["String"]["output"]>;
  values: QrCodeDynamicBinaryChoiceComponentStateValues;
};

export type QrCodeDynamicBinaryChoiceComponentStateValues = {
  __typename?: "QRCodeDynamicBinaryChoiceComponentStateValues";
  option1: Scalars["String"]["output"];
  option2: Scalars["String"]["output"];
};

export type QrCodeDynamicButtonComponent = {
  __typename?: "QRCodeDynamicButtonComponent";
  display: QrCodeDynamicButtonComponentDisplay;
  state: QrCodeDynamicButtonComponentState;
};

export type QrCodeDynamicButtonComponentDisplay = {
  __typename?: "QRCodeDynamicButtonComponentDisplay";
  color: QrCodeDynamicColor;
  label: Translatable;
  margin: Maybe<QrCodeDynamicMargin>;
  size: QrCodeDynamicSize;
};

export type QrCodeDynamicButtonComponentSelectState = {
  __typename?: "QRCodeDynamicButtonComponentSelectState";
  key: Scalars["String"]["output"];
  next: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type QrCodeDynamicButtonComponentState =
  | QrCodeDynamicButtonComponentSelectState
  | QrCodeDynamicButtonComponentSubmitState;

export type QrCodeDynamicButtonComponentSubmitState = {
  __typename?: "QRCodeDynamicButtonComponentSubmitState";
  key: Scalars["String"]["output"];
  next: Maybe<Scalars["String"]["output"]>;
};

export enum QrCodeDynamicColor {
  Primary = "primary",
  Secondary = "secondary",
  TextPrimary = "textPrimary",
}

export type QrCodeDynamicFlexBoxComponent = {
  __typename?: "QRCodeDynamicFlexBoxComponent";
  display: QrCodeDynamicFlexBoxComponentDisplay;
};

export type QrCodeDynamicFlexBoxComponentDisplay = {
  __typename?: "QRCodeDynamicFlexBoxComponentDisplay";
  children: Array<QrCodeDynamicStepComponent>;
  margin: Maybe<QrCodeDynamicMargin>;
};

export type QrCodeDynamicImageComponent = {
  __typename?: "QRCodeDynamicImageComponent";
  display: QrCodeDynamicImageComponentDisplay;
  key: Scalars["String"]["output"];
};

export type QrCodeDynamicImageComponentDisplay = {
  __typename?: "QRCodeDynamicImageComponentDisplay";
  margin: Maybe<QrCodeDynamicMargin>;
  src: Scalars["String"]["output"];
};

export type QrCodeDynamicInfoComponent = {
  __typename?: "QRCodeDynamicInfoComponent";
  display: QrCodeDynamicInfoComponentDisplay;
};

export type QrCodeDynamicInfoComponentDisplay = {
  __typename?: "QRCodeDynamicInfoComponentDisplay";
  color: QrCodeDynamicColor;
  margin: Maybe<QrCodeDynamicMargin>;
  text: Translatable;
  textAlign: QrCodeDynamicTextAlign;
  textVariant: QrCodeDynamicTextComponentVariant;
  title: Translatable;
  titleVariant: QrCodeDynamicTextComponentVariant;
};

export type QrCodeDynamicLinkComponent = {
  __typename?: "QRCodeDynamicLinkComponent";
  display: QrCodeDynamicLinkComponentDisplay;
  state: QrCodeDynamicLinkComponentState;
};

export type QrCodeDynamicLinkComponentDisplay = {
  __typename?: "QRCodeDynamicLinkComponentDisplay";
  color: QrCodeDynamicColor;
  label: Translatable;
  margin: Maybe<QrCodeDynamicMargin>;
  size: QrCodeDynamicSize;
};

export type QrCodeDynamicLinkComponentState = {
  __typename?: "QRCodeDynamicLinkComponentState";
  external: Scalars["Boolean"]["output"];
  path: Scalars["String"]["output"];
};

export type QrCodeDynamicMargin = {
  __typename?: "QRCodeDynamicMargin";
  bottom: Maybe<Scalars["Int"]["output"]>;
  left: Maybe<Scalars["Int"]["output"]>;
  right: Maybe<Scalars["Int"]["output"]>;
  top: Maybe<Scalars["Int"]["output"]>;
};

export type QrCodeDynamicResult = ErrorReturn | QrCodeDynamic;

export type QrCodeDynamicScrollBoxComponent = {
  __typename?: "QRCodeDynamicScrollBoxComponent";
  components: Array<QrCodeDynamicTextComponent>;
  display: QrCodeDynamicScrollBoxComponentDisplay;
  key: Scalars["String"]["output"];
};

export type QrCodeDynamicScrollBoxComponentDisplay = {
  __typename?: "QRCodeDynamicScrollBoxComponentDisplay";
  background: Maybe<Scalars["String"]["output"]>;
  backgroundImage: Maybe<Scalars["String"]["output"]>;
  margin: Maybe<QrCodeDynamicMargin>;
};

export type QrCodeDynamicSelectComponent = {
  __typename?: "QRCodeDynamicSelectComponent";
  display: QrCodeDynamicSelectComponentDisplay;
  state: QrCodeDynamicSelectComponentState;
};

export type QrCodeDynamicSelectComponentDisplay = {
  __typename?: "QRCodeDynamicSelectComponentDisplay";
  color: QrCodeDynamicColor;
  labels: Array<Translatable>;
  type: QrCodeDynamicSelectComponentType;
};

export type QrCodeDynamicSelectComponentState = {
  __typename?: "QRCodeDynamicSelectComponentState";
  defaultValue: Maybe<Scalars["String"]["output"]>;
  key: Scalars["String"]["output"];
  multiSelect: Scalars["Boolean"]["output"];
  next: Maybe<Scalars["String"]["output"]>;
  required: Scalars["Boolean"]["output"];
  valueType: InputValueType;
  values: Array<Scalars["String"]["output"]>;
};

export enum QrCodeDynamicSelectComponentType {
  Button = "button",
  Checkbox = "checkbox",
  Radio = "radio",
}

export enum QrCodeDynamicSize {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export type QrCodeDynamicStep = {
  __typename?: "QRCodeDynamicStep";
  components: Array<QrCodeDynamicStepComponent>;
  cs: Array<QrCodeDynamicStepComponent>;
  key: Scalars["String"]["output"];
};

export type QrCodeDynamicStepComponent =
  | QrCodeDynamicBinaryChoiceComponent
  | QrCodeDynamicButtonComponent
  | QrCodeDynamicFlexBoxComponent
  | QrCodeDynamicImageComponent
  | QrCodeDynamicInfoComponent
  | QrCodeDynamicLinkComponent
  | QrCodeDynamicScrollBoxComponent
  | QrCodeDynamicSelectComponent
  | QrCodeDynamicTextBoxComponent
  | QrCodeDynamicTextComponent
  | QrCodeDynamicTextfieldComponent;

export enum QrCodeDynamicTextAlign {
  Center = "center",
  Left = "left",
  Right = "right",
}

export type QrCodeDynamicTextBoxComponent = {
  __typename?: "QRCodeDynamicTextBoxComponent";
  display: QrCodeDynamicTextBoxComponentDisplay;
};

export type QrCodeDynamicTextBoxComponentDisplay = {
  __typename?: "QRCodeDynamicTextBoxComponentDisplay";
  background: Maybe<Scalars["String"]["output"]>;
  backgroundImage: Maybe<Scalars["String"]["output"]>;
  color: QrCodeDynamicColor;
  margin: Maybe<QrCodeDynamicMargin>;
  text: Translatable;
  textAlign: QrCodeDynamicTextAlign;
  variant: QrCodeDynamicTextComponentVariant;
};

export type QrCodeDynamicTextComponent = {
  __typename?: "QRCodeDynamicTextComponent";
  display: QrCodeDynamicTextComponentDisplay;
};

export type QrCodeDynamicTextComponentDisplay = {
  __typename?: "QRCodeDynamicTextComponentDisplay";
  color: QrCodeDynamicColor;
  margin: Maybe<QrCodeDynamicMargin>;
  text: Translatable;
  textAlign: QrCodeDynamicTextAlign;
  variant: QrCodeDynamicTextComponentVariant;
};

export enum QrCodeDynamicTextComponentVariant {
  Caption = "caption",
  CopyLarge = "copyLarge",
  CopyLargeHero = "copyLargeHero",
  CopySmall = "copySmall",
  CopySmallHero = "copySmallHero",
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

export type QrCodeDynamicTextfieldComponent = {
  __typename?: "QRCodeDynamicTextfieldComponent";
  display: QrCodeDynamicTextfieldComponentDisplay;
  state: QrCodeDynamicTextfieldComponentState;
};

export type QrCodeDynamicTextfieldComponentDisplay = {
  __typename?: "QRCodeDynamicTextfieldComponentDisplay";
  label: Translatable;
  margin: Maybe<QrCodeDynamicMargin>;
  multiline: Maybe<Scalars["Boolean"]["output"]>;
  rows: Maybe<Scalars["Int"]["output"]>;
};

export type QrCodeDynamicTextfieldComponentState = {
  __typename?: "QRCodeDynamicTextfieldComponentState";
  key: Scalars["String"]["output"];
};

export type QrCodeDynamicsResult = ErrorReturn | PaginatedQrCodeDynamicResponse;

export type QrCodeHelp = {
  __typename?: "QRCodeHelp";
  id: Scalars["String"]["output"];
  qrCode: QrCode;
  showQuestions: Scalars["Boolean"]["output"];
};

export type QrCodeInternalChat = {
  __typename?: "QRCodeInternalChat";
  hasPin: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  qrCode: QrCode;
};

export type QrCodeReturn = ErrorReturn | QrCodeDynamic | QrCodeHelp | QrCodeInternalChat;

export type QrCodes = QrCodeDynamic | QrCodeHelp | QrCodeInternalChat;

export type Query = {
  __typename?: "Query";
  /**
   *
   *     # Get all bookings for all venues.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  bookings: PaginatedBookingResult;
  /**
   *
   *     # Get all bookings for a user.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  bookingsByUser: PaginatedBookingResult;
  /**
   *
   *     # Get all bookings for a venue.
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  bookingsByVenue: PaginatedBookingResult;
  chat: Chat;
  demoQRCode: QrCode;
  /**
   *
   *     # TODO: Add description
   *
   *     ## Used by
   *
   *     -
   *
   */
  getCurrentPosition: Maybe<CurrentPosition>;
  /**
   *
   *     ## Used by
   *
   *     - Visitor (Web)
   *
   */
  getCurrentSecurityPositions: CurrentSecurityPositionsReturn;
  getMyPermissions: Array<Permission>;
  getMyPermissionsPaginated: PaginatedPermissionResponse;
  getMyVenues: Array<Venue>;
  getNotifications: Maybe<Notification>;
  /** @deprecated Use `position` resolver for naming convention reason and current auth logic */
  getPosition: Position;
  /** @deprecated Once verified this is not in use anymore, remove */
  getPositionByQRCodeId: Position;
  /**
   *
   *     # getQRCode
   *
   *     Resolves a QR Code by the id of the QR Code parent entity
   *
   *     ## Used by
   *
   *     - Visitor v2 (Web)
   *
   */
  getQRCode: GetQrCodeReturn;
  getQRCodeByShortCode: Maybe<Scalars["String"]["output"]>;
  getQRCodes: QrCodeReturn;
  getStatistics: Statistics;
  getVenueFeedback: Array<VenueFeedback>;
  getVenueTheme: Maybe<VenueTheme>;
  healthCheck: Scalars["String"]["output"];
  /**
   *
   *     # Get a list of help requests for a venue
   *
   *     ## Used by
   *
   *     - Owner (Web)
   *
   */
  helpStream: Maybe<HelpStreamReturn>;
  me: MeResult;
  nearestVenueForTag: Maybe<Scalars["String"]["output"]>;
  permissionsForVenue: PermissionForVenueResult;
  position: Position;
  pricing: Pricing;
  printJob: PrintJob;
  printJobs: PaginatedPrintJobResponse;
  qrCode: QrCode;
  qrCodeDynamic: QrCodeDynamicResult;
  qrCodeDynamicsByVenueId: QrCodeDynamicsResult;
  users: PaginatedUserResponse;
  venue: Venue;
  venueBySlug: Maybe<Venue>;
  /**
   *
   *     # Get a paginated list of venues
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  venues: PaginatedVenueResponse;
  /**
   *
   *     # Get a paginated list of archived or deleted venues
   *
   *     ## Used by
   *
   *     - Admin (Web)
   *
   */
  venuesArchive: PaginatedVenueResponse;
  venuesWithCoordinates: VenueWithCoordinatesResult;
  verifyGeolocation: Scalars["Boolean"]["output"];
};

export type QueryBookingsArgs = {
  filter: InputMaybe<BookingFilterInput>;
  pagination: PaginationInput;
};

export type QueryBookingsByUserArgs = {
  filter: InputMaybe<BookingFilterInput>;
  pagination: PaginationInput;
};

export type QueryBookingsByVenueArgs = {
  pagination: PaginationInput;
  venueId: Scalars["String"]["input"];
};

export type QueryChatArgs = {
  helpRequestId: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type QueryDemoQrCodeArgs = {
  venueId: Scalars["String"]["input"];
};

export type QueryGetCurrentPositionArgs = {
  uuid: Scalars["String"]["input"];
};

export type QueryGetCurrentSecurityPositionsArgs = {
  uuid: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type QueryGetMyPermissionsPaginatedArgs = {
  name: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Int"]["input"];
  tags: InputMaybe<Array<Scalars["String"]["input"]>>;
  take: Scalars["Int"]["input"];
};

export type QueryGetNotificationsArgs = {
  venueId: Scalars["String"]["input"];
};

export type QueryGetPositionArgs = {
  positionId: Scalars["String"]["input"];
};

export type QueryGetPositionByQrCodeIdArgs = {
  qrCodeId: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type QueryGetQrCodeArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetQrCodeByShortCodeArgs = {
  shortCode: Scalars["String"]["input"];
};

export type QueryGetQrCodesArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetVenueFeedbackArgs = {
  venueId: Scalars["String"]["input"];
};

export type QueryGetVenueThemeArgs = {
  venueId: Scalars["String"]["input"];
};

export type QueryHelpStreamArgs = {
  cursor: InputMaybe<Scalars["String"]["input"]>;
  venueId: Scalars["String"]["input"];
};

export type QueryNearestVenueForTagArgs = {
  coordinates: LatLngRequiredInput;
  tag: Scalars["String"]["input"];
};

export type QueryPermissionsForVenueArgs = {
  venueId: Scalars["String"]["input"];
};

export type QueryPositionArgs = {
  id: Scalars["String"]["input"];
};

export type QueryPricingArgs = {
  capacity: Scalars["Int"]["input"];
  item: Items;
};

export type QueryPrintJobArgs = {
  id: Scalars["String"]["input"];
};

export type QueryPrintJobsArgs = {
  input: PrintJobsInput;
  pagination: PaginationInput;
};

export type QueryQrCodeArgs = {
  qrCodeId: Scalars["String"]["input"];
};

export type QueryQrCodeDynamicArgs = {
  id: Scalars["String"]["input"];
};

export type QueryQrCodeDynamicsByVenueIdArgs = {
  venueId: Scalars["String"]["input"];
};

export type QueryUsersArgs = {
  skip: Scalars["Int"]["input"];
  take: Scalars["Int"]["input"];
};

export type QueryVenueArgs = {
  venueId: Scalars["String"]["input"];
};

export type QueryVenueBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryVenuesArgs = {
  name: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Int"]["input"];
  tags: InputMaybe<Array<Scalars["String"]["input"]>>;
  take: Scalars["Int"]["input"];
  withArchived: InputMaybe<Scalars["Boolean"]["input"]>;
  withDeleted: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryVenuesArchiveArgs = {
  skip: Scalars["Int"]["input"];
  take: Scalars["Int"]["input"];
};

export type QueryVenuesWithCoordinatesArgs = {
  name: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Int"]["input"];
  tags: InputMaybe<Array<Scalars["String"]["input"]>>;
  take: Scalars["Int"]["input"];
  withArchived: InputMaybe<Scalars["Boolean"]["input"]>;
  withDeleted: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryVerifyGeolocationArgs = {
  input: VerifyGeolocationInput;
};

export type ReassignNfcTagToQrCodeResult = ErrorReturn | NfcTag;

export type RegisterResult = UnknownError | User | UserAlreadyExistsError;

export type RequiredLatLng = {
  __typename?: "RequiredLatLng";
  lat: Scalars["Float"]["output"];
  lng: Scalars["Float"]["output"];
};

/** The role of the user */
export enum Roles {
  Admin = "admin",
  Awareness = "awareness",
  AwarenessTeam = "awarenessTeam",
  Owner = "owner",
  Security = "security",
  SecurityTeam = "securityTeam",
  Visitor = "visitor",
}

/** The scope of the user */
export enum Scopes {
  Admin = "admin",
  AwarenessRead = "awarenessRead",
  AwarenessWrite = "awarenessWrite",
  PermissionRead = "permissionRead",
  PermissionWrite = "permissionWrite",
  SecurityRead = "securityRead",
  SecurityWrite = "securityWrite",
  VenueRead = "venueRead",
  VenueWrite = "venueWrite",
  VenueWriteActivateEdit = "venueWriteActivateEdit",
  VisitorRead = "visitorRead",
  VisitorWrite = "visitorWrite",
}

export type SendPasswordResetLinkReturnType = ErrorReturn | SendPasswordResetLinkSuccessResult;

export type SendPasswordResetLinkSuccessResult = {
  __typename?: "SendPasswordResetLinkSuccessResult";
  email: Scalars["String"]["output"];
};

export type SetVenueGeofenceInput = {
  areas: GeofenceAreaInput;
  venueId: Scalars["String"]["input"];
};

export type SetVenueGeofenceResult = ErrorReturn | Geofencing;

export type SetVenueGeofenceSettingsInput = {
  requirePosition: Scalars["Boolean"]["input"];
  requirePositionForQRCodes: Scalars["Boolean"]["input"];
  requirePositionForVenue: Scalars["Boolean"]["input"];
  venueId: Scalars["String"]["input"];
};

export type SetVenueSituationsSettingsInput = {
  situations: Array<Situation>;
  venueId: Scalars["String"]["input"];
};

export type SetVenueSituationsSettingsResult = ErrorReturn | VenueSettings;

export type SignInWithIdTokenReturnType =
  | ErrorReturn
  | SignInWithIdTokenSuccessResult
  | UserNotFoundError;

export type SignInWithIdTokenSuccessResult = {
  __typename?: "SignInWithIdTokenSuccessResult";
  accessToken: Scalars["String"]["output"];
  sessionId: Scalars["String"]["output"];
};

/** The situation in which the person is */
export enum Situation {
  /** Jemand wird angegriffen. */
  Attacked = "attacked",
  Discriminated = "discriminated",
  Emergency = "emergency",
  /** Jemand wird belästigt. */
  Harassed = "harassed",
  Notify = "notify",
  /** Jemand fühlt sich unwohl. */
  SomethingWrong = "somethingWrong",
  /** Es gibt keine näheren Information. */
  Unknown = "unknown",
}

export type Statistics = {
  __typename?: "Statistics";
  venues: Array<VenueStatistics>;
};

export enum StatusCodes {
  Conflict = "CONFLICT",
  Forbidden = "FORBIDDEN",
  InternalServerError = "INTERNAL_SERVER_ERROR",
  NotFound = "NOT_FOUND",
  Unauthorized = "UNAUTHORIZED",
}

export type SubmitDynamicRequestInput = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
  valueType: InputValueType;
};

export type SubmitVenueHelpRequestInput = {
  coordinates: InputMaybe<CoordinatesInput>;
  language: Scalars["String"]["input"];
  location: InputMaybe<Scalars["String"]["input"]>;
  person: Person;
  position: InputMaybe<Scalars["String"]["input"]>;
  situation: Situation;
  venueId: Scalars["String"]["input"];
};

export type Subscription = {
  __typename?: "Subscription";
  getHelpRequestBouncerResponse: Scalars["String"]["output"];
  newChatMessage: ChatMessage;
  onTypingStatusChanged: Scalars["DateTimeISO"]["output"];
};

export type SubscriptionGetHelpRequestBouncerResponseArgs = {
  uuid: InputMaybe<Scalars["String"]["input"]>;
};

export type SubscriptionNewChatMessageArgs = {
  uuid: Scalars["String"]["input"];
};

export type SubscriptionOnTypingStatusChangedArgs = {
  helpRequestId: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
};

export type SuccessOrErrorReturnResult = ErrorReturn | SuccessReturn;

export type SuccessReturn = {
  __typename?: "SuccessReturn";
  success: Scalars["Boolean"]["output"];
};

export enum Template {
  Custom = "CUSTOM",
  Default = "DEFAULT",
}

export type Timestamp = {
  __typename?: "Timestamp";
  createdAt: Scalars["DateTimeISO"]["output"];
  deletedAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type Translatable = {
  __typename?: "Translatable";
  /** German */
  de: Scalars["String"]["output"];
  /** English */
  en: Scalars["String"]["output"];
};

export type TranslatableInput = {
  /** German */
  de: Scalars["String"]["input"];
  /** English */
  en: Scalars["String"]["input"];
};

export type Translations = {
  __typename?: "Translations";
  /** The first message, that will be shown to the visitor automatically */
  automaticFirstMessage: Maybe<Scalars["String"]["output"]>;
  /** The first message, that will be shown to the visitor automatically, if no member reacted within XX seconds (currently direct chat only) */
  automaticUnavailableMessage: Maybe<Scalars["String"]["output"]>;
  /** // TODO */
  personStepHeadline: Maybe<Scalars["String"]["output"]>;
  /** // TODO */
  personStepSubtitle: Maybe<Scalars["String"]["output"]>;
  /** // TODO */
  situationStepSubtitle: Maybe<Scalars["String"]["output"]>;
  /** Title that is being displayed after the visitor scanned a code */
  waitingHeadline: Maybe<Scalars["String"]["output"]>;
  /** Title that is being after a security member accepted the call */
  waitingHeadlineAccepted: Maybe<Scalars["String"]["output"]>;
  /** Message that is being displayed after the visitor scanned a code */
  waitingMessage: Maybe<Scalars["String"]["output"]>;
  /** Message that is being displayed after a security member accepted the call */
  waitingMessageAccepted: Maybe<Scalars["String"]["output"]>;
};

export type TranslationsInput = {
  /** German translation */
  de: InputMaybe<Scalars["String"]["input"]>;
  /** English translation */
  en: InputMaybe<Scalars["String"]["input"]>;
};

export type UnassignNfcTagFromQrCodeResult = ErrorReturn | NfcTag;

/** Indicates an error due to unknown reasons. */
export type UnknownError = BackendError & {
  __typename?: "UnknownError";
  i18nKey: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  statusCode: StatusCodes;
};

export type UpdateNotificationChannelInput = {
  enabled: Scalars["Boolean"]["input"];
  id: Scalars["String"]["input"];
  receiver: Scalars["String"]["input"];
  type: NotificationChannelType;
};

export type UpdateTypingInput = {
  helpRequestId: Scalars["String"]["input"];
  userType: UserType;
  venueId: Scalars["String"]["input"];
};

export type UpdateUserResult = ErrorReturn | User;

export type UpdateVenueActiveFeedbackInput = {
  button: TranslatableInput;
  link: Scalars["String"]["input"];
  text: TranslatableInput;
};

export type UpdateVenueThemeTextInput = {
  text: TranslationsInput;
  type: Scalars["String"]["input"];
};

export type User = {
  __typename?: "User";
  email: Scalars["String"]["output"];
  fcmToken: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  image: Maybe<Scalars["String"]["output"]>;
  isVerified: Scalars["Boolean"]["output"];
  language: Scalars["String"]["output"];
  lastLogin: Maybe<Scalars["DateTimeISO"]["output"]>;
  lastRefresh: Maybe<Scalars["DateTimeISO"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  permissions: Array<Permission>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  role: Roles;
  stripeCustomer: Maybe<Scalars["String"]["output"]>;
  timestamp: Timestamp;
  venues: Array<Venue>;
};

/** Indicates, that a user with the given email address already exists. */
export type UserAlreadyExistsError = BackendError & {
  __typename?: "UserAlreadyExistsError";
  i18nKey: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  statusCode: StatusCodes;
};

/** Indicates, that a user with the given email address already exists. */
export type UserNotFoundError = BackendError & {
  __typename?: "UserNotFoundError";
  i18nKey: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  statusCode: StatusCodes;
};

export enum UserType {
  Staff = "staff",
  User = "user",
}

export type Venue = {
  __typename?: "Venue";
  active: VenueActive;
  activeSubscription: Maybe<Booking>;
  archivedAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  /** Returns the booking feature type of the venue */
  bookingFeatureType: BookingFeatureType;
  bookings: Array<Booking>;
  contactNumber: Maybe<Scalars["String"]["output"]>;
  contactUser: Maybe<User>;
  coordinates: Maybe<CoordinatesNonNull>;
  details: VenueDetails;
  geofencing: Geofencing;
  hasActiveLead: Maybe<Scalars["Boolean"]["output"]>;
  hasActivePaidSubscription: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  image: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  locations: Array<Location>;
  mayToggleActivate: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  permission: Permission;
  permissions: Array<Permission>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  settings: VenueSettings;
  slug: Maybe<Scalars["String"]["output"]>;
  theme: Maybe<VenueTheme>;
  timestamp: Timestamp;
};

export type VenueActive = {
  __typename?: "VenueActive";
  endsAt: Maybe<Scalars["String"]["output"]>;
  feedback: Maybe<VenueActiveFeedback>;
  isActive: Scalars["Boolean"]["output"];
  isRequired: Scalars["Boolean"]["output"];
  startsAt: Maybe<Scalars["String"]["output"]>;
};

export type VenueActiveFeedback = {
  __typename?: "VenueActiveFeedback";
  button: Translatable;
  link: Scalars["String"]["output"];
  text: Translatable;
};

export type VenueDetails = {
  __typename?: "VenueDetails";
  capacity: Maybe<Scalars["Int"]["output"]>;
  endsAt: Maybe<Scalars["String"]["output"]>;
  startsAt: Maybe<Scalars["String"]["output"]>;
  type: Maybe<VenueType>;
  weeklyOpen: Maybe<Scalars["Boolean"]["output"]>;
};

export type VenueDetailsInput = {
  capacity: Scalars["Int"]["input"];
  endsAt: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  startsAt: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  type: VenueType;
  weeklyOpen: Scalars["Boolean"]["input"];
};

export type VenueFeedback = {
  __typename?: "VenueFeedback";
  feedback: Array<Array<VenueFeedbackObject>>;
  id: Scalars["String"]["output"];
  timestamp: Timestamp;
  venue: Venue;
};

export type VenueFeedbackInput = {
  key: Scalars["String"]["input"];
  label: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type VenueFeedbackObject = {
  __typename?: "VenueFeedbackObject";
  key: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type VenueQrStatistics = {
  __typename?: "VenueQRStatistics";
  external: Scalars["Float"]["output"];
  internal: Scalars["Float"]["output"];
};

export type VenueSettings = {
  __typename?: "VenueSettings";
  allowDirectChat: Scalars["Boolean"]["output"];
  enablePositionSharing: Scalars["Boolean"]["output"];
  hasChat: Scalars["Boolean"]["output"];
  inactivityTimeout: Scalars["Int"]["output"];
  isWhiteLabel: Scalars["Boolean"]["output"];
  requirePosition: Scalars["Boolean"]["output"];
  requirePositionForQRCodes: Scalars["Boolean"]["output"];
  requirePositionForVenue: Scalars["Boolean"]["output"];
  showQuestions: Scalars["Boolean"]["output"];
  situations: Array<Situation>;
};

export type VenueStatistics = {
  __typename?: "VenueStatistics";
  helpStream: Maybe<HelpStream>;
  last24Hours: VenueQrStatistics;
  last28Days: VenueQrStatistics;
  lastWeek: VenueQrStatistics;
  lastYear: VenueQrStatistics;
  venue: Venue;
};

export type VenueTheme = {
  __typename?: "VenueTheme";
  colors: VenueThemeColors;
  id: Scalars["String"]["output"];
  images: Maybe<VenueThemeImages>;
  localizations: VenueThemeTexts;
  texts: Translations;
  timestamp: Maybe<Timestamp>;
  venue: Venue;
};

export type VenueThemeTextsArgs = {
  language: Scalars["String"]["input"];
};

export type VenueThemeColors = {
  __typename?: "VenueThemeColors";
  background: Scalars["String"]["output"];
  primaryColor: Scalars["String"]["output"];
  secondaryColor: Scalars["String"]["output"];
};

export type VenueThemeColorsInput = {
  background: Scalars["String"]["input"];
  primaryColor: Scalars["String"]["input"];
  secondaryColor: Scalars["String"]["input"];
};

export type VenueThemeImages = {
  __typename?: "VenueThemeImages";
  background: Maybe<Scalars["String"]["output"]>;
  logo: Maybe<Scalars["String"]["output"]>;
};

export type VenueThemeTexts = {
  __typename?: "VenueThemeTexts";
  /** German */
  de: Translations;
  /** English */
  en: Translations;
};

export enum VenueType {
  Bar = "bar",
  Club = "club",
  Event = "event",
  Festival = "festival",
  Other = "other",
  Stadium = "stadium",
}

export type VenueWithCoordinates = {
  __typename?: "VenueWithCoordinates";
  active: VenueActive;
  activeSubscription: Maybe<Booking>;
  archivedAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  /** Returns the booking feature type of the venue */
  bookingFeatureType: BookingFeatureType;
  bookings: Array<Booking>;
  contactNumber: Maybe<Scalars["String"]["output"]>;
  contactUser: Maybe<User>;
  coordinates: WithCoordinates;
  details: VenueDetails;
  geofencing: Geofencing;
  hasActiveLead: Maybe<Scalars["Boolean"]["output"]>;
  hasActivePaidSubscription: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  image: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  locations: Array<Location>;
  mayToggleActivate: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  permission: Permission;
  permissions: Array<Permission>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  settings: VenueSettings;
  slug: Maybe<Scalars["String"]["output"]>;
  theme: Maybe<VenueTheme>;
  timestamp: Timestamp;
};

export type VenueWithCoordinatesResult = ErrorReturn | VenueWithCoordinatesSuccessResult;

export type VenueWithCoordinatesSuccessResult = {
  __typename?: "VenueWithCoordinatesSuccessResult";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<VenueWithCoordinates>;
  total: Scalars["Int"]["output"];
};

export type VerifyGeolocationInput = {
  coordinates: LatLngRequiredInput;
  venueId: Scalars["String"]["input"];
};

export type VerifyIdTokenResult = {
  __typename?: "VerifyIdTokenResult";
  accessToken: Scalars["String"]["output"];
  sessionId: Scalars["String"]["output"];
};

export type VerifyIdTokenReturnType = ErrorReturn | VerifyIdTokenResult;

export type VerifyMagicLinkResult = ErrorReturn | VerifyMagicLinkReturn;

export type VerifyMagicLinkReturn = {
  __typename?: "VerifyMagicLinkReturn";
  accessToken: Scalars["String"]["output"];
  sessionId: Scalars["String"]["output"];
};

export type WithCoordinates = {
  __typename?: "WithCoordinates";
  altitude: Scalars["Float"]["output"];
  lat: Scalars["Float"]["output"];
  lng: Scalars["Float"]["output"];
  radius: Scalars["Float"]["output"];
};

export type EditVenueBasicDetailsMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  image: Scalars["String"]["input"];
}>;

export type EditVenueBasicDetailsMutation = {
  __typename?: "Mutation";
  editVenueName: { __typename: "Venue"; id: string; name: string };
  editVenueImage: { __typename: "Venue"; id: string; image: string };
};

export type EditVenueMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  details: VenueDetailsInput;
}>;

export type EditVenueMutation = {
  __typename?: "Mutation";
  editVenueName: { __typename: "Venue"; id: string; name: string };
  editVenueDetails:
    | { __typename?: "ErrorReturn"; message: string; i18nKey: string }
    | {
        __typename?: "VenueDetails";
        weeklyOpen: boolean | null;
        type: VenueType | null;
        capacity: number | null;
        startsAt: string | null;
        endsAt: string | null;
      };
};

export type _EditVenueDetailsModalFragment = {
  __typename?: "Venue";
  id: string;
  name: string;
  details: {
    __typename?: "VenueDetails";
    capacity: number | null;
    weeklyOpen: boolean | null;
    type: VenueType | null;
    startsAt: string | null;
    endsAt: string | null;
  };
};

export type BookingButtonComponentFragment = {
  __typename: "Venue";
  id: string;
  name: string;
  details: {
    __typename?: "VenueDetails";
    capacity: number | null;
    weeklyOpen: boolean | null;
    type: VenueType | null;
    startsAt: string | null;
    endsAt: string | null;
  };
  contactUser: { __typename: "User"; id: string; email: string } | null;
};

export type PrintJobComponentFragment = {
  __typename?: "PrintJob";
  path: string | null;
  size: number | null;
  status: PrintJobStatus;
  format: Format;
  template: Template;
  id: string;
  numberOfQRCodes: number;
  timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
};

export type PrintJobQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type PrintJobQuery = {
  __typename?: "Query";
  printJob: {
    __typename?: "PrintJob";
    path: string | null;
    size: number | null;
    status: PrintJobStatus;
    format: Format;
    template: Template;
    id: string;
    numberOfQRCodes: number;
    timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
  };
};

export type PrintJobsQueryVariables = Exact<{
  input: PrintJobsInput;
  pagination: PaginationInput;
}>;

export type PrintJobsQuery = {
  __typename?: "Query";
  printJobs: {
    __typename?: "PaginatedPrintJobResponse";
    items: Array<{
      __typename?: "PrintJob";
      path: string | null;
      size: number | null;
      status: PrintJobStatus;
      format: Format;
      template: Template;
      id: string;
      numberOfQRCodes: number;
      timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
    }>;
  };
};

export type LogoutMobileMutationVariables = Exact<{
  sessionId: Scalars["String"]["input"];
}>;

export type LogoutMobileMutation = {
  __typename?: "Mutation";
  logoutMobile: { __typename?: "SuccessReturn"; success: boolean };
};

export type AppRefreshMutationVariables = Exact<{
  sessionId: Scalars["String"]["input"];
}>;

export type AppRefreshMutation = {
  __typename?: "Mutation";
  appRefresh:
    | { __typename?: "AppRefreshReturn"; accessToken: string; sessionId: string }
    | { __typename?: "ErrorReturn"; message: string };
};

export type BaseBookingFragment = {
  __typename?: "Booking";
  id: string;
  startsAt: string;
  endsAt: string | null;
  trialEndsAt: string | null;
  cancelledAt: string | null;
  type: BookingType;
  status: string | null;
  timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
};

export type ExtendedBookingFragment = {
  __typename?: "Booking";
  subscriptionAmount: number;
  id: string;
  startsAt: string;
  endsAt: string | null;
  trialEndsAt: string | null;
  cancelledAt: string | null;
  type: BookingType;
  status: string | null;
  timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
};

export type CreateTrialMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type CreateTrialMutation = {
  __typename?: "Mutation";
  createTrial:
    | { __typename: "CreateBookingSuccess"; url: string | null }
    | { __typename: "ErrorReturn"; message: string };
};

export type CreateSubscriptionMutationVariables = Exact<{
  capacity: Scalars["Int"]["input"];
  venueId: Scalars["String"]["input"];
}>;

export type CreateSubscriptionMutation = {
  __typename?: "Mutation";
  createSubscription:
    | { __typename: "CreateBookingSuccess"; url: string | null }
    | { __typename: "ErrorReturn"; message: string };
};

export type CreatePaymentMutationVariables = Exact<{
  capacity: Scalars["Int"]["input"];
  venueId: Scalars["String"]["input"];
  startsAt: Scalars["DateTimeISO"]["input"];
  endsAt: Scalars["DateTimeISO"]["input"];
}>;

export type CreatePaymentMutation = {
  __typename?: "Mutation";
  createPayment:
    | { __typename: "CreateBookingSuccess"; url: string | null }
    | { __typename: "ErrorReturn"; message: string };
};

export type CancelBookingMutationVariables = Exact<{
  bookingId: Scalars["String"]["input"];
}>;

export type CancelBookingMutation = {
  __typename?: "Mutation";
  cancelBooking:
    | {
        __typename: "Booking";
        subscriptionAmount: number;
        id: string;
        startsAt: string;
        endsAt: string | null;
        trialEndsAt: string | null;
        cancelledAt: string | null;
        type: BookingType;
        status: string | null;
        timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
      }
    | { __typename: "ErrorReturn"; message: string }
    | null;
};

export type MinimalLocationFragment = { __typename?: "Location"; id: string; name: string };

export type LocationWithCoordsFragment = {
  __typename?: "Location";
  id: string;
  name: string;
  positions: Array<{
    __typename?: "Position";
    id: string;
    name: string | null;
    coordinates: { __typename?: "CoordinatesNonNull"; lat: number; lng: number } | null;
  }>;
};

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteLocationMutation = {
  __typename?: "Mutation";
  deleteLocation:
    | { __typename: "ErrorReturn"; message: string }
    | { __typename: "SuccessReturn"; success: boolean };
};

export type EditLocationNameMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}>;

export type EditLocationNameMutation = {
  __typename?: "Mutation";
  editLocationName:
    | { __typename: "ErrorReturn"; message: string }
    | { __typename: "Location"; id: string; name: string };
};

export type GetNotificationsQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type GetNotificationsQuery = {
  __typename?: "Query";
  getNotifications: {
    __typename: "Notification";
    id: string;
    enabled: boolean;
    notificationChannels: Array<{
      __typename: "NotificationChannel";
      id: string;
      type: NotificationChannelType;
      receiver: string;
      errorAt: string | null;
    }>;
  } | null;
};

export type ToggleNotificationsMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  enabled: Scalars["Boolean"]["input"];
}>;

export type ToggleNotificationsMutation = {
  __typename?: "Mutation";
  toggleNotifications: { __typename: "Notification"; id: string; enabled: boolean };
};

export type UpsertNotificationChannelMutationVariables = Exact<{
  id: InputMaybe<Scalars["String"]["input"]>;
  venueId: Scalars["String"]["input"];
  receiver: Scalars["String"]["input"];
  notificationChannelType: NotificationChannelType;
}>;

export type UpsertNotificationChannelMutation = {
  __typename?: "Mutation";
  upsertNotificationChannel: {
    __typename: "NotificationChannel";
    id: string;
    type: NotificationChannelType;
    receiver: string;
    errorAt: string | null;
  };
};

export type MinimalPermissionFragment = {
  __typename: "Permission";
  id: string;
  roles: Array<Roles>;
  role: Roles;
};

export type BasePermissionFragment = {
  __typename: "Permission";
  scopes: Array<Scopes>;
  venueId: string;
  id: string;
  roles: Array<Roles>;
  role: Roles;
};

export type UserPermissionFragment = {
  __typename: "Permission";
  id: string;
  scopes: Array<Scopes>;
  roles: Array<Roles>;
  role: Roles;
  user: { __typename: "User"; id: string; email: string; name: string | null; isVerified: boolean };
};

export type PermissionsForVenueQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type PermissionsForVenueQuery = {
  __typename?: "Query";
  permissionsForVenue:
    | { __typename: "ErrorReturn"; message: string }
    | {
        __typename: "PermissionForVenueSuccess";
        items: Array<{
          __typename: "Permission";
          id: string;
          scopes: Array<Scopes>;
          roles: Array<Roles>;
          role: Roles;
          user: {
            __typename: "User";
            id: string;
            email: string;
            name: string | null;
            isVerified: boolean;
          };
        }>;
      };
};

export type GetMyPermissionsPaginatedQueryVariables = Exact<{
  skip: Scalars["Int"]["input"];
  take: Scalars["Int"]["input"];
  tags: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetMyPermissionsPaginatedQuery = {
  __typename?: "Query";
  getMyPermissionsPaginated: {
    __typename?: "PaginatedPermissionResponse";
    hasMore: boolean;
    total: number;
    items: Array<{
      __typename: "Permission";
      scopes: Array<Scopes>;
      venueId: string;
      id: string;
      roles: Array<Roles>;
      role: Roles;
      venue: {
        __typename?: "Venue";
        phoneNumber: string | null;
        hasActivePaidSubscription: boolean;
        bookingFeatureType: BookingFeatureType;
        id: string;
        name: string;
        image: string;
        activeSubscription: {
          __typename?: "Booking";
          id: string;
          startsAt: string;
          endsAt: string | null;
          trialEndsAt: string | null;
          cancelledAt: string | null;
          type: BookingType;
          status: string | null;
          timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
        } | null;
        details: {
          __typename?: "VenueDetails";
          weeklyOpen: boolean | null;
          type: VenueType | null;
          capacity: number | null;
          startsAt: string | null;
          endsAt: string | null;
        };
        settings: {
          __typename?: "VenueSettings";
          requirePosition: boolean;
          isWhiteLabel: boolean;
          showQuestions: boolean;
          hasChat: boolean;
          enablePositionSharing: boolean;
          allowDirectChat: boolean;
          inactivityTimeout: number;
          situations: Array<Situation>;
        };
        timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
        contactUser: { __typename: "User"; id: string; isVerified: boolean } | null;
      };
    }>;
  };
};

export type DeletePermissionMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  permissionId: Scalars["String"]["input"];
}>;

export type DeletePermissionMutation = { __typename?: "Mutation"; deletePermission: string };

export type QrCodeBaseFragment = {
  __typename: "QRCode";
  id: string;
  shortCode: string | null;
  nfcTags: Array<{ __typename?: "NFCTag"; id: string }> | null;
  qrCode:
    | { __typename: "QRCodeDynamic"; id: string }
    | { __typename: "QRCodeHelp"; id: string; showQuestions: boolean }
    | { __typename: "QRCodeInternalChat"; id: string; hasPin: boolean };
};

export type QrCodeHelpFragmentFragment = {
  __typename: "QRCodeHelp";
  id: string;
  showQuestions: boolean;
};

export type QrCodeInternalChatFragmentFragment = {
  __typename: "QRCodeInternalChat";
  id: string;
  hasPin: boolean;
};

export type QrCodeDynamicFragmentFragment = { __typename: "QRCodeDynamic"; id: string };

export type CreateQrCodeHelpMutationVariables = Exact<{
  name: InputMaybe<Scalars["String"]["input"]>;
  locationId: Scalars["String"]["input"];
  hasChat: Scalars["Boolean"]["input"];
  showQuestions: Scalars["Boolean"]["input"];
}>;

export type CreateQrCodeHelpMutation = {
  __typename?: "Mutation";
  createQRCodeHelp: { __typename?: "QRCodeHelp"; id: string; showQuestions: boolean };
};

export type CreateQrCodeInternalChatMutationVariables = Exact<{
  locationId: Scalars["String"]["input"];
  hasPin: Scalars["Boolean"]["input"];
  name: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateQrCodeInternalChatMutation = {
  __typename?: "Mutation";
  createQRCodeInternalChat: { __typename?: "QRCodeInternalChat"; id: string; hasPin: boolean };
};

export type GetStatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatisticsQuery = {
  __typename?: "Query";
  getStatistics: {
    __typename?: "Statistics";
    venues: Array<{
      __typename?: "VenueStatistics";
      venue: { __typename?: "Venue"; id: string; name: string };
      helpStream: {
        __typename?: "HelpStream";
        entries: Array<{
          __typename?: "HelpStreamEntry";
          id: string;
          date: string;
          person: Person | null;
          situation: Situation | null;
          location: string | null;
          position: string | null;
          title: string;
          text: string;
        }>;
      } | null;
      last24Hours: { __typename?: "VenueQRStatistics"; internal: number; external: number };
      last28Days: { __typename?: "VenueQRStatistics"; internal: number; external: number };
      lastWeek: { __typename?: "VenueQRStatistics"; internal: number; external: number };
      lastYear: { __typename?: "VenueQRStatistics"; internal: number; external: number };
    }>;
  };
};

export type BaseHelpStreamEntryFragment = {
  __typename?: "HelpStreamEntry";
  id: string;
  date: string;
  person: Person | null;
  situation: Situation | null;
  location: string | null;
  position: string | null;
  title: string;
  text: string;
};

export type MinimalUserFragment = {
  __typename: "User";
  id: string;
  email: string;
  name: string | null;
};

export type BaseUserFragment = {
  __typename: "User";
  phoneNumber: string | null;
  fcmToken: string | null;
  role: Roles;
  isVerified: boolean;
  id: string;
  email: string;
  name: string | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me:
    | { __typename?: "Anonymous"; uuid: string }
    | { __typename?: "ErrorReturn"; message: string }
    | {
        __typename: "User";
        phoneNumber: string | null;
        fcmToken: string | null;
        role: Roles;
        isVerified: boolean;
        id: string;
        email: string;
        name: string | null;
        permissions: Array<{
          __typename: "Permission";
          scopes: Array<Scopes>;
          venueId: string;
          id: string;
          roles: Array<Roles>;
          role: Roles;
        }>;
      };
};

export type UpdateFcmTokenMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type UpdateFcmTokenMutation = {
  __typename?: "Mutation";
  updateFCMToken:
    | { __typename?: "ErrorReturn"; message: string }
    | {
        __typename: "User";
        id: string;
        email: string;
        phoneNumber: string | null;
        fcmToken: string | null;
        name: string | null;
        permissions: Array<{
          __typename: "Permission";
          id: string;
          roles: Array<Roles>;
          scopes: Array<Scopes>;
          venue: {
            __typename: "Venue";
            id: string;
            name: string;
            image: string;
            timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
          };
        }>;
      };
};

export type CreateVenueMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  details: VenueDetailsInput;
}>;

export type CreateVenueMutation = {
  __typename?: "Mutation";
  createVenue:
    | {
        __typename?: "CreateVenueSuccessResult";
        accessToken: string;
        venue: {
          __typename: "Venue";
          id: string;
          name: string;
          image: string;
          settings: {
            __typename?: "VenueSettings";
            requirePosition: boolean;
            isWhiteLabel: boolean;
            showQuestions: boolean;
            hasChat: boolean;
            enablePositionSharing: boolean;
            allowDirectChat: boolean;
            inactivityTimeout: number;
            situations: Array<Situation>;
          };
          activeSubscription: { __typename?: "Booking"; id: string } | null;
        };
      }
    | { __typename?: "ErrorReturn"; message: string };
};

export type EditVenueImageMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  image: Scalars["String"]["input"];
}>;

export type EditVenueImageMutation = {
  __typename?: "Mutation";
  editVenueImage: { __typename: "Venue"; id: string; image: string };
};

export type EditVenuePhoneNumberMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
}>;

export type EditVenuePhoneNumberMutation = {
  __typename?: "Mutation";
  editVenuePhoneNumber: { __typename: "Venue"; id: string; phoneNumber: string | null };
};

export type GetVenueFeedbackQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type GetVenueFeedbackQuery = {
  __typename?: "Query";
  getVenueFeedback: Array<{
    __typename?: "VenueFeedback";
    id: string;
    timestamp: { __typename?: "Timestamp"; createdAt: string };
    feedback: Array<
      Array<{ __typename?: "VenueFeedbackObject"; key: string; label: string; value: string }>
    >;
  }>;
};

export type TranslationsFragment = {
  __typename?: "Translations";
  automaticFirstMessage: string | null;
  automaticUnavailableMessage: string | null;
  waitingMessage: string | null;
  waitingMessageAccepted: string | null;
  waitingHeadline: string | null;
  waitingHeadlineAccepted: string | null;
};

export type GetVenueThemeQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
}>;

export type GetVenueThemeQuery = {
  __typename?: "Query";
  getVenueTheme: {
    __typename: "VenueTheme";
    id: string;
    images: {
      __typename?: "VenueThemeImages";
      background: string | null;
      logo: string | null;
    } | null;
    colors: {
      __typename?: "VenueThemeColors";
      primaryColor: string;
      secondaryColor: string;
      background: string;
    };
    texts: {
      __typename?: "Translations";
      automaticFirstMessage: string | null;
      automaticUnavailableMessage: string | null;
      waitingMessage: string | null;
      waitingMessageAccepted: string | null;
      waitingHeadline: string | null;
      waitingHeadlineAccepted: string | null;
    };
    venue: {
      __typename?: "Venue";
      id: string;
      name: string;
      phoneNumber: string | null;
      settings: {
        __typename?: "VenueSettings";
        requirePosition: boolean;
        isWhiteLabel: boolean;
        showQuestions: boolean;
        hasChat: boolean;
        enablePositionSharing: boolean;
        allowDirectChat: boolean;
        inactivityTimeout: number;
        situations: Array<Situation>;
      };
    };
  } | null;
};

export type GetVenueThemeTextsQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
}>;

export type GetVenueThemeTextsQuery = {
  __typename?: "Query";
  getVenueTheme: {
    __typename: "VenueTheme";
    id: string;
    texts: {
      __typename?: "Translations";
      automaticFirstMessage: string | null;
      automaticUnavailableMessage: string | null;
      waitingMessage: string | null;
      waitingMessageAccepted: string | null;
      waitingHeadline: string | null;
      waitingHeadlineAccepted: string | null;
    };
  } | null;
};

export type UpdateVenueThemeMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  colors: VenueThemeColorsInput;
  language: Scalars["String"]["input"];
}>;

export type UpdateVenueThemeMutation = {
  __typename?: "Mutation";
  updateVenueTheme: {
    __typename: "VenueTheme";
    id: string;
    images: {
      __typename?: "VenueThemeImages";
      background: string | null;
      logo: string | null;
    } | null;
    colors: {
      __typename?: "VenueThemeColors";
      primaryColor: string;
      secondaryColor: string;
      background: string;
    };
    texts: {
      __typename?: "Translations";
      automaticFirstMessage: string | null;
      automaticUnavailableMessage: string | null;
      waitingMessage: string | null;
      waitingMessageAccepted: string | null;
      waitingHeadline: string | null;
      waitingHeadlineAccepted: string | null;
    };
    venue: {
      __typename?: "Venue";
      id: string;
      name: string;
      settings: { __typename?: "VenueSettings"; requirePosition: boolean; isWhiteLabel: boolean };
    };
  } | null;
};

export type ErrorReturnFragment = { __typename: "ErrorReturn"; message: string };

export type TimestampFragment = { __typename?: "Timestamp"; createdAt: string; updatedAt: string };

export type MinimalVenueFragment = {
  __typename?: "Venue";
  id: string;
  name: string;
  image: string;
};

export type BaseVenueFragment = {
  __typename?: "Venue";
  phoneNumber: string | null;
  id: string;
  name: string;
  image: string;
  timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
  contactUser: { __typename: "User"; id: string; isVerified: boolean } | null;
};

export type VenueBookingFragment = {
  __typename?: "Venue";
  hasActivePaidSubscription: boolean;
  bookingFeatureType: BookingFeatureType;
};

export type VenueDetailsFragment = {
  __typename?: "Venue";
  phoneNumber: string | null;
  hasActivePaidSubscription: boolean;
  bookingFeatureType: BookingFeatureType;
  id: string;
  name: string;
  image: string;
  details: {
    __typename?: "VenueDetails";
    weeklyOpen: boolean | null;
    type: VenueType | null;
    capacity: number | null;
    startsAt: string | null;
    endsAt: string | null;
  };
  settings: {
    __typename?: "VenueSettings";
    requirePosition: boolean;
    isWhiteLabel: boolean;
    showQuestions: boolean;
    hasChat: boolean;
    enablePositionSharing: boolean;
    allowDirectChat: boolean;
    inactivityTimeout: number;
    situations: Array<Situation>;
  };
  timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
  contactUser: { __typename: "User"; id: string; isVerified: boolean } | null;
};

export type VenueSettingsFragment = {
  __typename?: "VenueSettings";
  requirePosition: boolean;
  isWhiteLabel: boolean;
  showQuestions: boolean;
  hasChat: boolean;
  enablePositionSharing: boolean;
  allowDirectChat: boolean;
  inactivityTimeout: number;
  situations: Array<Situation>;
};

export type VenueDetailsDetailsFragment = {
  __typename?: "VenueDetails";
  weeklyOpen: boolean | null;
  type: VenueType | null;
  capacity: number | null;
  startsAt: string | null;
  endsAt: string | null;
};

export type VenueThemeFragment = {
  __typename: "VenueTheme";
  colors: {
    __typename?: "VenueThemeColors";
    primaryColor: string;
    secondaryColor: string;
    background: string;
  };
};

export type UserFragment = { __typename: "User"; id: string; isVerified: boolean; email: string };

export type LocationFragment = { __typename: "Location"; id: string; name: string };

export type PositionFragment = {
  __typename: "Position";
  id: string;
  name: string | null;
  timestamp: { __typename?: "Timestamp"; createdAt: string };
  coordinates: { __typename?: "CoordinatesNonNull"; lat: number; lng: number } | null;
};

export type NfcTagFragment = { __typename: "NFCTag"; id: string };

export type QrCodeFragment = { __typename: "QRCode"; id: string; shortCode: string | null };

export type QrCodeHelpFragment = { __typename: "QRCodeHelp"; id: string; showQuestions: boolean };

export type QrCodeInternalChatFragment = {
  __typename: "QRCodeInternalChat";
  id: string;
  hasPin: boolean;
};

export type QrCodeDynamicFragment = { __typename: "QRCodeDynamic"; id: string };

export type BookingsQueryVariables = Exact<{
  filter: InputMaybe<BookingFilterInput>;
  pagination: PaginationInput;
}>;

export type BookingsQuery = {
  __typename?: "Query";
  bookings:
    | { __typename: "ErrorReturn" }
    | {
        __typename: "PaginatedBookingResponse";
        total: number;
        hasMore: boolean;
        items: Array<{
          __typename?: "Booking";
          id: string;
          startsAt: string;
          endsAt: string | null;
          trialEndsAt: string | null;
          type: BookingType;
          status: string | null;
          subscriptionAmount: number;
          provider: BookingProvider | null;
          featureType: BookingFeatureType;
          timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
          user: { __typename: "User"; id: string; email: string; name: string | null };
          venue: { __typename?: "Venue"; id: string; name: string; image: string };
        }>;
      };
};

export type ResetVenueArchivedMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type ResetVenueArchivedMutation = {
  __typename?: "Mutation";
  resetVenueArchived: { __typename?: "Venue"; id: string; archivedAt: string | null };
};

export type VenuesArchiveQueryVariables = Exact<{
  take: Scalars["Int"]["input"];
  skip: Scalars["Int"]["input"];
}>;

export type VenuesArchiveQuery = {
  __typename?: "Query";
  venuesArchive: {
    __typename?: "PaginatedVenueResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: "Venue";
      id: string;
      name: string;
      archivedAt: string | null;
      timestamp: { __typename?: "Timestamp"; deletedAt: string | null };
    }>;
  };
};

export type CreateManuaPaymentMutationVariables = Exact<{
  capacity: Scalars["Int"]["input"];
  endsAt: Scalars["DateTimeISO"]["input"];
  startsAt: Scalars["DateTimeISO"]["input"];
  email: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
  bookingFeatureType: BookingFeatureType;
}>;

export type CreateManuaPaymentMutation = {
  __typename?: "Mutation";
  createManualPayment:
    | { __typename: "ErrorReturn"; message: string }
    | {
        __typename: "GraphQLBooking";
        id: string;
        endsAt: string | null;
        startsAt: string;
        trialEndsAt: string | null;
        type: BookingType;
        user: { __typename?: "User"; id: string; email: string };
        venue: { __typename?: "Venue"; id: string; name: string };
      };
};

export type CreateManualSubscriptionMutationVariables = Exact<{
  capacity: Scalars["Int"]["input"];
  startsAt: Scalars["DateTimeISO"]["input"];
  email: Scalars["String"]["input"];
  venueId: Scalars["String"]["input"];
  bookingFeatureType: BookingFeatureType;
}>;

export type CreateManualSubscriptionMutation = {
  __typename?: "Mutation";
  createManualSubscription:
    | { __typename: "ErrorReturn"; message: string }
    | {
        __typename: "GraphQLBooking";
        id: string;
        endsAt: string | null;
        startsAt: string;
        trialEndsAt: string | null;
        type: BookingType;
        user: { __typename?: "User"; id: string; email: string };
        venue: { __typename?: "Venue"; id: string; name: string };
      };
};

export type SetVenueArchivedMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type SetVenueArchivedMutation = {
  __typename?: "Mutation";
  setVenueArchived: { __typename?: "Venue"; id: string };
};

export type GetAllVenuesQueryVariables = Exact<{
  take: Scalars["Int"]["input"];
  skip: Scalars["Int"]["input"];
  tags: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetAllVenuesQuery = {
  __typename?: "Query";
  venues: {
    __typename?: "PaginatedVenueResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: "Venue";
      hasActivePaidSubscription: boolean;
      id: string;
      name: string;
      image: string;
      details: {
        __typename?: "VenueDetails";
        weeklyOpen: boolean | null;
        type: VenueType | null;
        capacity: number | null;
        startsAt: string | null;
        endsAt: string | null;
      };
      contactUser: { __typename: "User"; id: string; email: string; name: string | null } | null;
      timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
      activeSubscription: {
        __typename?: "Booking";
        id: string;
        startsAt: string;
        endsAt: string | null;
        trialEndsAt: string | null;
        cancelledAt: string | null;
        type: BookingType;
        status: string | null;
        timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
      } | null;
    }>;
  };
};

export type GetAllUsersQueryVariables = Exact<{
  skip: Scalars["Int"]["input"];
  take: Scalars["Int"]["input"];
}>;

export type GetAllUsersQuery = {
  __typename?: "Query";
  users: {
    __typename?: "PaginatedUserResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename: "User";
      lastRefresh: string | null;
      isVerified: boolean;
      id: string;
      email: string;
      name: string | null;
      timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
      permissions: Array<{
        __typename: "Permission";
        id: string;
        roles: Array<Roles>;
        role: Roles;
        venue: { __typename?: "Venue"; id: string; name: string; image: string };
      }>;
    }>;
  };
};

export type BookingsByUserQueryVariables = Exact<{
  filter: InputMaybe<BookingFilterInput>;
  pagination: PaginationInput;
}>;

export type BookingsByUserQuery = {
  __typename?: "Query";
  bookingsByUser:
    | { __typename: "ErrorReturn"; message: string }
    | {
        __typename: "PaginatedBookingResponse";
        total: number;
        hasMore: boolean;
        items: Array<{
          __typename?: "Booking";
          subscriptionAmount: number;
          id: string;
          startsAt: string;
          endsAt: string | null;
          trialEndsAt: string | null;
          cancelledAt: string | null;
          type: BookingType;
          status: string | null;
          venue: { __typename?: "Venue"; id: string; name: string; image: string };
          timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
        }>;
      };
};

export type GetPricingQueryVariables = Exact<{
  capacity: Scalars["Int"]["input"];
  item: Items;
}>;

export type GetPricingQuery = {
  __typename?: "Query";
  pricing: { __typename?: "Pricing"; price: number };
};

export type UpdateNameMutationVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type UpdateNameMutation = {
  __typename?: "Mutation";
  updateName:
    | { __typename?: "ErrorReturn"; message: string }
    | { __typename: "User"; id: string; name: string | null };
};

export type UpdatePhoneNumberMutationVariables = Exact<{
  phoneNumber: Scalars["String"]["input"];
}>;

export type UpdatePhoneNumberMutation = {
  __typename?: "Mutation";
  updatePhoneNumber:
    | { __typename?: "ErrorReturn"; message: string }
    | {
        __typename: "User";
        id: string;
        email: string;
        phoneNumber: string | null;
        fcmToken: string | null;
        name: string | null;
        permissions: Array<{
          __typename: "Permission";
          id: string;
          roles: Array<Roles>;
          scopes: Array<Scopes>;
          venue: {
            __typename: "Venue";
            id: string;
            name: string;
            image: string;
            timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
          };
        }>;
      };
};

export type GetVenueContainerQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type GetVenueContainerQuery = {
  __typename?: "Query";
  venue: {
    __typename?: "Venue";
    id: string;
    name: string;
    image: string;
    phoneNumber: string | null;
    hasActivePaidSubscription: boolean;
    bookingFeatureType: BookingFeatureType;
    hasActiveLead: boolean | null;
    isActive: boolean;
    coordinates: {
      __typename?: "CoordinatesNonNull";
      lat: number;
      lng: number;
      radius: number;
    } | null;
    timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
    details: {
      __typename?: "VenueDetails";
      weeklyOpen: boolean | null;
      type: VenueType | null;
      capacity: number | null;
      startsAt: string | null;
      endsAt: string | null;
    };
    contactUser: { __typename: "User"; id: string; isVerified: boolean; email: string } | null;
  };
};

export type HelpStreamQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
  cursor: InputMaybe<Scalars["String"]["input"]>;
}>;

export type HelpStreamQuery = {
  __typename?: "Query";
  helpStream:
    | { __typename: "ErrorReturn" }
    | {
        __typename: "PaginatedHelpStreamResponse";
        total: number;
        nextCursor: string | null;
        items: Array<{
          __typename?: "HelpRequest";
          id: string;
          location: string | null;
          position: string | null;
          person: Person;
          situation: Situation;
          date: string | null;
        }>;
      }
    | null;
};

export type BookingsByVenueQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
  pagination: PaginationInput;
}>;

export type BookingsByVenueQuery = {
  __typename?: "Query";
  bookingsByVenue:
    | { __typename: "ErrorReturn"; message: string }
    | {
        __typename: "PaginatedBookingResponse";
        total: number;
        hasMore: boolean;
        items: Array<{
          __typename?: "Booking";
          type: BookingType;
          featureType: BookingFeatureType;
          status: string | null;
          id: string;
          startsAt: string;
          endsAt: string | null;
        }>;
      };
};

export type CreateQrCodeDynamicMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  receiver: Scalars["String"]["input"];
}>;

export type CreateQrCodeDynamicMutation = {
  __typename?: "Mutation";
  createQRCodeDynamic: { __typename: "QRCodeDynamic"; id: string };
};

export type QrCodeDynamicsByVenueIdQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type QrCodeDynamicsByVenueIdQuery = {
  __typename?: "Query";
  venue: {
    __typename: "Venue";
    id: string;
    active: {
      __typename?: "VenueActive";
      feedback: {
        __typename?: "VenueActiveFeedback";
        link: string;
        text: { __typename: "Translatable"; de: string; en: string };
        button: { __typename: "Translatable"; de: string; en: string };
      } | null;
    };
  };
  qrCodeDynamicsByVenueId:
    | { __typename: "ErrorReturn" }
    | {
        __typename: "PaginatedQRCodeDynamicResponse";
        items: Array<{
          __typename?: "QRCodeDynamic";
          id: string;
          rawJson: string;
          qrCode: {
            __typename?: "QRCode";
            id: string;
            notifications: Array<{
              __typename?: "Notification";
              id: string;
              enabled: boolean;
              notificationChannels: Array<{
                __typename?: "NotificationChannel";
                id: string;
                receiver: string;
                type: NotificationChannelType;
                errorAt: string | null;
                enabled: boolean;
              }>;
            }>;
          };
        }>;
      };
};

export type ToggleVenueActiveFeedbackMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type ToggleVenueActiveFeedbackMutation = {
  __typename?: "Mutation";
  toggleVenueActiveFeedback: {
    __typename: "Venue";
    id: string;
    active: {
      __typename?: "VenueActive";
      feedback: {
        __typename?: "VenueActiveFeedback";
        link: string;
        text: { __typename: "Translatable"; de: string; en: string };
        button: { __typename: "Translatable"; de: string; en: string };
      } | null;
    };
  };
};

export type UpdateNotificationChannelMutationVariables = Exact<{
  input: UpdateNotificationChannelInput;
}>;

export type UpdateNotificationChannelMutation = {
  __typename?: "Mutation";
  updateNotificationChannel: {
    __typename?: "NotificationChannel";
    id: string;
    type: NotificationChannelType;
    receiver: string;
    enabled: boolean;
  };
};

export type UpdateStepsMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  steps: Scalars["String"]["input"];
}>;

export type UpdateStepsMutation = {
  __typename?: "Mutation";
  updateSteps: { __typename?: "QRCodeDynamic"; id: string };
};

export type UpdateVenueActiveFeedbackMutationVariables = Exact<{
  input: UpdateVenueActiveFeedbackInput;
  venueId: Scalars["String"]["input"];
}>;

export type UpdateVenueActiveFeedbackMutation = {
  __typename?: "Mutation";
  updateVenueActiveFeedback: {
    __typename: "Venue";
    id: string;
    active: {
      __typename?: "VenueActive";
      feedback: {
        __typename?: "VenueActiveFeedback";
        link: string;
        text: { __typename?: "Translatable"; de: string; en: string };
        button: { __typename?: "Translatable"; de: string; en: string };
      } | null;
    };
  };
};

export type MinimalPositionFragment = { __typename?: "Position"; id: string; name: string | null };

export type PositionWithCoordinatesFragment = {
  __typename?: "Position";
  id: string;
  name: string | null;
  coordinates: { __typename?: "CoordinatesNonNull"; lat: number; lng: number } | null;
};

export type CreateMultipleLocationsMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  names: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type CreateMultipleLocationsMutation = {
  __typename?: "Mutation";
  createMultipleLocations:
    | { __typename?: "ErrorReturn"; message: string }
    | {
        __typename?: "LocationList";
        locations: Array<{ __typename: "Location"; id: string; name: string }>;
      };
};

export type DeletePositionMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeletePositionMutation = {
  __typename?: "Mutation";
  deletePosition:
    | { __typename: "ErrorReturn"; message: string }
    | { __typename: "SuccessReturn"; success: boolean };
};

export type EditPositionMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  name: InputMaybe<Scalars["String"]["input"]>;
  venueId: Scalars["String"]["input"];
}>;

export type EditPositionMutation = {
  __typename?: "Mutation";
  editPosition:
    | { __typename: "ErrorReturn"; message: string }
    | {
        __typename: "Position";
        id: string;
        name: string | null;
        coordinates: { __typename?: "CoordinatesNonNull"; lat: number; lng: number } | null;
      };
};

export type EditPositionLocationMutationVariables = Exact<{
  locationId: Scalars["String"]["input"];
  positionId: Scalars["String"]["input"];
}>;

export type EditPositionLocationMutation = {
  __typename?: "Mutation";
  editPositionLocation:
    | { __typename: "ErrorReturn" }
    | { __typename: "Position"; id: string; location: { __typename: "Location"; id: string } };
};

export type UpdateVenueCoordinatesMutationVariables = Exact<{
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  radius: InputMaybe<Scalars["Float"]["input"]>;
  venueId: Scalars["String"]["input"];
}>;

export type UpdateVenueCoordinatesMutation = {
  __typename?: "Mutation";
  updateVenueCoordinates: {
    __typename: "Venue";
    id: string;
    coordinates: {
      __typename?: "CoordinatesNonNull";
      lat: number;
      lng: number;
      radius: number;
    } | null;
  };
};

export type _GeofencingMapFragment = {
  __typename?: "Geofencing";
  requirePosition: boolean;
  requirePositionForVenue: boolean;
  requirePositionForQRCodes: boolean;
  polygons: Array<{
    __typename: "Polygon";
    id: string;
    coordinates: Array<{ __typename?: "RequiredLatLng"; lat: number; lng: number }>;
  }>;
  circles: Array<{
    __typename: "Circle";
    id: string;
    radius: number;
    center: { __typename?: "RequiredLatLng"; lat: number; lng: number };
  }>;
};

export type _GeofencingMapLocationFragment = {
  __typename?: "Location";
  id: string;
  name: string;
  coordinates: {
    __typename?: "CoordinatesNonNull";
    lat: number;
    lng: number;
    radius: number;
  } | null;
  positions: Array<{
    __typename: "Position";
    id: string;
    name: string | null;
    coordinates: { __typename?: "CoordinatesNonNull"; lat: number; lng: number } | null;
    qrCodes: Array<{
      __typename: "QRCode";
      id: string;
      qrCode:
        | { __typename: "QRCodeDynamic"; id: string }
        | { __typename: "QRCodeHelp"; id: string }
        | { __typename: "QRCodeInternalChat"; id: string };
    }> | null;
  }>;
};

export type _PositionListItemFragment = {
  __typename?: "Position";
  id: string;
  name: string | null;
  qrCodes: Array<{
    __typename?: "QRCode";
    id: string;
    scanCount: number;
    lastScan: string | null;
    nfcTags: Array<{ __typename?: "NFCTag"; id: string }> | null;
    qrCode:
      | { __typename: "QRCodeDynamic"; id: string }
      | { __typename: "QRCodeHelp"; id: string }
      | { __typename: "QRCodeInternalChat"; id: string };
  }> | null;
  coordinates: { __typename?: "CoordinatesNonNull"; lat: number; lng: number } | null;
};

export type _LocationListItemFragment = {
  __typename: "Location";
  id: string;
  name: string;
  coordinates: {
    __typename?: "CoordinatesNonNull";
    lat: number;
    lng: number;
    radius: number;
  } | null;
};

export type _GeofencingSettingsFragment = {
  __typename?: "Geofencing";
  requirePosition: boolean;
  requirePositionForVenue: boolean;
  requirePositionForQRCodes: boolean;
};

export type GetVenueLocationsQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type GetVenueLocationsQuery = {
  __typename?: "Query";
  venue: {
    __typename: "Venue";
    id: string;
    slug: string | null;
    geofencing: {
      __typename?: "Geofencing";
      requirePosition: boolean;
      requirePositionForVenue: boolean;
      requirePositionForQRCodes: boolean;
      polygons: Array<{
        __typename: "Polygon";
        id: string;
        coordinates: Array<{ __typename?: "RequiredLatLng"; lat: number; lng: number }>;
      }>;
      circles: Array<{
        __typename: "Circle";
        id: string;
        radius: number;
        center: { __typename?: "RequiredLatLng"; lat: number; lng: number };
      }>;
    };
    coordinates: {
      __typename?: "CoordinatesNonNull";
      lat: number;
      lng: number;
      radius: number;
    } | null;
    locations: Array<{
      __typename?: "Location";
      id: string;
      name: string;
      coordinates: {
        __typename?: "CoordinatesNonNull";
        lat: number;
        lng: number;
        radius: number;
      } | null;
      positions: Array<{
        __typename: "Position";
        id: string;
        name: string | null;
        coordinates: { __typename?: "CoordinatesNonNull"; lat: number; lng: number } | null;
        qrCodes: Array<{
          __typename: "QRCode";
          id: string;
          qrCode:
            | { __typename: "QRCodeDynamic"; id: string }
            | { __typename: "QRCodeHelp"; id: string }
            | { __typename: "QRCodeInternalChat"; id: string };
        }> | null;
      }>;
    }>;
  };
};

export type SetVenueGeofenceSettingsMutationVariables = Exact<{
  input: SetVenueGeofenceSettingsInput;
}>;

export type SetVenueGeofenceSettingsMutation = {
  __typename?: "Mutation";
  setVenueGeofenceSettings:
    | { __typename: "ErrorReturn" }
    | {
        __typename: "Geofencing";
        requirePosition: boolean;
        requirePositionForVenue: boolean;
        requirePositionForQRCodes: boolean;
      };
};

export type SetVenueGeofenceMutationVariables = Exact<{
  input: SetVenueGeofenceInput;
}>;

export type SetVenueGeofenceMutation = {
  __typename?: "Mutation";
  setVenueGeofence:
    | { __typename: "ErrorReturn" }
    | {
        __typename: "Geofencing";
        requirePosition: boolean;
        requirePositionForVenue: boolean;
        requirePositionForQRCodes: boolean;
        polygons: Array<{
          __typename?: "Polygon";
          coordinates: Array<{ __typename?: "RequiredLatLng"; lat: number; lng: number }>;
        }>;
        circles: Array<{
          __typename?: "Circle";
          radius: number;
          center: { __typename?: "RequiredLatLng"; lat: number; lng: number };
        }>;
      };
};

export type GetVenuePrintPageQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type GetVenuePrintPageQuery = {
  __typename?: "Query";
  venue: {
    __typename: "Venue";
    id: string;
    locations: Array<{
      __typename: "Location";
      id: string;
      name: string;
      positions: Array<{
        __typename: "Position";
        id: string;
        name: string | null;
        qrCodes: Array<{
          __typename: "QRCode";
          id: string;
          qrCode:
            | { __typename: "QRCodeDynamic"; id: string }
            | { __typename: "QRCodeHelp"; id: string }
            | { __typename: "QRCodeInternalChat"; id: string };
        }> | null;
      }>;
    }>;
  };
};

export type CreatePrintJobMutationVariables = Exact<{
  input: CreatePrintJobInput;
}>;

export type CreatePrintJobMutation = {
  __typename?: "Mutation";
  createPrintJob: {
    __typename?: "PrintJob";
    path: string | null;
    size: number | null;
    status: PrintJobStatus;
    format: Format;
    template: Template;
    id: string;
    numberOfQRCodes: number;
    timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
  };
};

export type RetryPrintJobMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type RetryPrintJobMutation = {
  __typename?: "Mutation";
  retryPrintJob: {
    __typename?: "PrintJob";
    path: string | null;
    size: number | null;
    status: PrintJobStatus;
    format: Format;
    template: Template;
    id: string;
    numberOfQRCodes: number;
    timestamp: { __typename?: "Timestamp"; createdAt: string; updatedAt: string };
  };
};

export type GetVenueSettingsQueryVariables = Exact<{
  venueId: Scalars["String"]["input"];
}>;

export type GetVenueSettingsQuery = {
  __typename?: "Query";
  venue: {
    __typename: "Venue";
    id: string;
    mayToggleActivate: boolean;
    settings: {
      __typename?: "VenueSettings";
      requirePosition: boolean;
      isWhiteLabel: boolean;
      showQuestions: boolean;
      hasChat: boolean;
      enablePositionSharing: boolean;
      allowDirectChat: boolean;
      inactivityTimeout: number;
      situations: Array<Situation>;
    };
    active: {
      __typename?: "VenueActive";
      isRequired: boolean;
      isActive: boolean;
      startsAt: string | null;
      endsAt: string | null;
    };
  };
  getVenueTheme: {
    __typename: "VenueTheme";
    id: string;
    localizations: {
      __typename?: "VenueThemeTexts";
      de: {
        __typename?: "Translations";
        automaticFirstMessage: string | null;
        automaticUnavailableMessage: string | null;
        waitingMessage: string | null;
        waitingMessageAccepted: string | null;
        waitingHeadline: string | null;
        waitingHeadlineAccepted: string | null;
      };
      en: {
        __typename?: "Translations";
        automaticFirstMessage: string | null;
        automaticUnavailableMessage: string | null;
        waitingMessage: string | null;
        waitingMessageAccepted: string | null;
        waitingHeadline: string | null;
        waitingHeadlineAccepted: string | null;
      };
    };
  } | null;
};

export type _VenueActiveSettingsFragment = {
  __typename?: "VenueActive";
  isRequired: boolean;
  isActive: boolean;
  startsAt: string | null;
  endsAt: string | null;
};

export type EditVenueSettingsInactivityTimeoutMutationVariables = Exact<{
  inactivityTimeout: Scalars["Int"]["input"];
  venueId: Scalars["String"]["input"];
}>;

export type EditVenueSettingsInactivityTimeoutMutation = {
  __typename?: "Mutation";
  editVenueSettingsInactivityTimeout: {
    __typename: "Venue";
    id: string;
    settings: {
      __typename?: "VenueSettings";
      showQuestions: boolean;
      hasChat: boolean;
      enablePositionSharing: boolean;
      requirePosition: boolean;
      requirePositionForVenue: boolean;
      requirePositionForQRCodes: boolean;
      isWhiteLabel: boolean;
      allowDirectChat: boolean;
      inactivityTimeout: number;
    };
  };
};

export type UpdateVenueThemeTextBulkMutationVariables = Exact<{
  venueId: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  input: Array<UpdateVenueThemeTextInput> | UpdateVenueThemeTextInput;
}>;

export type UpdateVenueThemeTextBulkMutation = {
  __typename?: "Mutation";
  updateVenueThemeTextBulk: {
    __typename: "VenueTheme";
    id: string;
    texts: {
      __typename?: "Translations";
      automaticFirstMessage: string | null;
      automaticUnavailableMessage: string | null;
      waitingMessage: string | null;
      waitingMessageAccepted: string | null;
      waitingHeadline: string | null;
      waitingHeadlineAccepted: string | null;
    };
  } | null;
};

export type SetVenueSituationsSettingsMutationVariables = Exact<{
  input: SetVenueSituationsSettingsInput;
}>;

export type SetVenueSituationsSettingsMutation = {
  __typename?: "Mutation";
  setVenueSituationsSettings:
    | { __typename: "ErrorReturn"; message: string }
    | { __typename: "VenueSettings"; situations: Array<Situation> };
};

export type CreatePermissionMutationVariables = Exact<{
  input: CreatePermissionInput;
}>;

export type CreatePermissionMutation = {
  __typename?: "Mutation";
  createPermission:
    | { __typename: "ErrorReturn"; message: string; i18nKey: string }
    | {
        __typename: "Permission";
        id: string;
        scopes: Array<Scopes>;
        roles: Array<Roles>;
        role: Roles;
        user: {
          __typename?: "User";
          id: string;
          email: string;
          name: string | null;
          phoneNumber: string | null;
          fcmToken: string | null;
          image: string | null;
        };
      };
};

export type EditPermissionMutationVariables = Exact<{
  permissionId: Scalars["String"]["input"];
  role: Roles;
  venueId: Scalars["String"]["input"];
}>;

export type EditPermissionMutation = {
  __typename?: "Mutation";
  editPermission:
    | { __typename?: "ErrorReturn"; message: string; statusCode: StatusCodes; i18nKey: string }
    | { __typename: "Permission"; id: string; role: Roles };
};

export type DeleteUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser: { __typename?: "AuthUser"; email: string; id: string };
};

export type DeleteUserCodeConfirmationMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
}>;

export type DeleteUserCodeConfirmationMutation = {
  __typename?: "Mutation";
  deleteUserCodeConfirmation: boolean;
};

export type DeleteUserTokenConfirmationMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type DeleteUserTokenConfirmationMutation = {
  __typename?: "Mutation";
  deleteUserTokenConfirmation: boolean;
};

export type SignInWithIdTokenMutationVariables = Exact<{
  idToken: Scalars["String"]["input"];
}>;

export type SignInWithIdTokenMutation = {
  __typename?: "Mutation";
  signInWithIdToken:
    | { __typename: "ErrorReturn"; message: string; statusCode: StatusCodes; i18nKey: string }
    | { __typename: "SignInWithIdTokenSuccessResult"; accessToken: string; sessionId: string }
    | {
        __typename: "UserNotFoundError";
        message: string;
        statusCode: StatusCodes;
        i18nKey: string;
      };
};

export type VerifyMagicLinkMutationVariables = Exact<{
  code: Scalars["String"]["input"];
  uuid: Scalars["String"]["input"];
}>;

export type VerifyMagicLinkMutation = {
  __typename?: "Mutation";
  verifyMagicLink:
    | { __typename: "ErrorReturn"; message: string }
    | { __typename: "VerifyMagicLinkReturn"; accessToken: string; sessionId: string };
};

export type SignUpWithIdTokenMutationVariables = Exact<{
  idToken: Scalars["String"]["input"];
}>;

export type SignUpWithIdTokenMutation = {
  __typename?: "Mutation";
  signupWithIdToken:
    | { __typename: "UnknownError" }
    | { __typename: "User"; id: string }
    | { __typename: "UserAlreadyExistsError" };
};

export type SendPasswordResetLinkMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type SendPasswordResetLinkMutation = {
  __typename?: "Mutation";
  sendPasswordResetLink:
    | { __typename: "ErrorReturn"; message: string; statusCode: StatusCodes; i18nKey: string }
    | { __typename: "SendPasswordResetLinkSuccessResult"; email: string };
};

export const _EditVenueDetailsModalFragmentDoc = gql`
  fragment _EditVenueDetailsModal on Venue {
    id
    name
    details {
      capacity
      weeklyOpen
      type
      startsAt
      endsAt
    }
  }
`;
export const BookingButtonComponentFragmentDoc = gql`
  fragment BookingButtonComponent on Venue {
    __typename
    id
    name
    details {
      capacity
      weeklyOpen
      type
      startsAt
      endsAt
    }
    contactUser {
      __typename
      id
      email
    }
  }
`;
export const PrintJobComponentFragmentDoc = gql`
  fragment PrintJobComponent on PrintJob {
    timestamp {
      createdAt
      updatedAt
    }
    path
    size
    status
    format
    template
    id
    numberOfQRCodes
  }
`;
export const BaseBookingFragmentDoc = gql`
  fragment BaseBooking on Booking {
    id
    timestamp {
      createdAt
      updatedAt
    }
    startsAt
    endsAt
    trialEndsAt
    cancelledAt
    type
    status
  }
`;
export const ExtendedBookingFragmentDoc = gql`
  fragment ExtendedBooking on Booking {
    ...BaseBooking
    subscriptionAmount
  }
  ${BaseBookingFragmentDoc}
`;
export const MinimalLocationFragmentDoc = gql`
  fragment MinimalLocation on Location {
    id
    name
  }
`;
export const PositionWithCoordinatesFragmentDoc = gql`
  fragment PositionWithCoordinates on Position {
    id
    name
    coordinates {
      lat
      lng
    }
  }
`;
export const LocationWithCoordsFragmentDoc = gql`
  fragment LocationWithCoords on Location {
    id
    name
    positions {
      ...PositionWithCoordinates
    }
  }
  ${PositionWithCoordinatesFragmentDoc}
`;
export const MinimalPermissionFragmentDoc = gql`
  fragment MinimalPermission on Permission {
    __typename
    id
    roles
    role
  }
`;
export const BasePermissionFragmentDoc = gql`
  fragment BasePermission on Permission {
    ...MinimalPermission
    scopes
    venueId
  }
  ${MinimalPermissionFragmentDoc}
`;
export const UserPermissionFragmentDoc = gql`
  fragment UserPermission on Permission {
    __typename
    id
    scopes
    roles
    role
    user {
      __typename
      id
      email
      name
      isVerified
    }
  }
`;
export const QrCodeHelpFragmentFragmentDoc = gql`
  fragment QRCodeHelpFragment on QRCodeHelp {
    __typename
    id
    showQuestions
  }
`;
export const QrCodeInternalChatFragmentFragmentDoc = gql`
  fragment QRCodeInternalChatFragment on QRCodeInternalChat {
    __typename
    id
    hasPin
  }
`;
export const QrCodeDynamicFragmentFragmentDoc = gql`
  fragment QRCodeDynamicFragment on QRCodeDynamic {
    __typename
    id
  }
`;
export const QrCodeBaseFragmentDoc = gql`
  fragment QRCodeBase on QRCode {
    __typename
    id
    shortCode
    nfcTags {
      id
    }
    qrCode {
      ... on QRCodeHelp {
        ...QRCodeHelpFragment
      }
      ... on QRCodeInternalChat {
        ...QRCodeInternalChatFragment
      }
      ... on QRCodeDynamic {
        ...QRCodeDynamicFragment
      }
    }
  }
  ${QrCodeHelpFragmentFragmentDoc}
  ${QrCodeInternalChatFragmentFragmentDoc}
  ${QrCodeDynamicFragmentFragmentDoc}
`;
export const BaseHelpStreamEntryFragmentDoc = gql`
  fragment BaseHelpStreamEntry on HelpStreamEntry {
    id
    date
    person
    situation
    location
    position
    title
    text
  }
`;
export const MinimalUserFragmentDoc = gql`
  fragment MinimalUser on User {
    __typename
    id
    email
    name
  }
`;
export const BaseUserFragmentDoc = gql`
  fragment BaseUser on User {
    ...MinimalUser
    phoneNumber
    fcmToken
    role
    isVerified
  }
  ${MinimalUserFragmentDoc}
`;
export const TranslationsFragmentDoc = gql`
  fragment Translations on Translations {
    automaticFirstMessage
    automaticUnavailableMessage
    waitingMessage
    waitingMessageAccepted
    waitingHeadline
    waitingHeadlineAccepted
  }
`;
export const ErrorReturnFragmentDoc = gql`
  fragment ErrorReturn on ErrorReturn {
    __typename
    message
  }
`;
export const TimestampFragmentDoc = gql`
  fragment Timestamp on Timestamp {
    createdAt
    updatedAt
  }
`;
export const MinimalVenueFragmentDoc = gql`
  fragment MinimalVenue on Venue {
    id
    name
    image
  }
`;
export const BaseVenueFragmentDoc = gql`
  fragment BaseVenue on Venue {
    ...MinimalVenue
    phoneNumber
    timestamp {
      createdAt
      updatedAt
    }
    contactUser {
      __typename
      id
      isVerified
    }
  }
  ${MinimalVenueFragmentDoc}
`;
export const VenueBookingFragmentDoc = gql`
  fragment VenueBooking on Venue {
    hasActivePaidSubscription
    bookingFeatureType
  }
`;
export const VenueSettingsFragmentDoc = gql`
  fragment VenueSettings on VenueSettings {
    requirePosition
    isWhiteLabel
    showQuestions
    hasChat
    enablePositionSharing
    allowDirectChat
    inactivityTimeout
    situations
  }
`;
export const VenueDetailsFragmentDoc = gql`
  fragment VenueDetails on Venue {
    ...BaseVenue
    ...VenueBooking
    details {
      weeklyOpen
      type
      capacity
      startsAt
      endsAt
    }
    settings {
      ...VenueSettings
    }
  }
  ${BaseVenueFragmentDoc}
  ${VenueBookingFragmentDoc}
  ${VenueSettingsFragmentDoc}
`;
export const VenueDetailsDetailsFragmentDoc = gql`
  fragment VenueDetailsDetails on VenueDetails {
    weeklyOpen
    type
    capacity
    startsAt
    endsAt
  }
`;
export const VenueThemeFragmentDoc = gql`
  fragment VenueTheme on VenueTheme {
    __typename
    colors {
      primaryColor
      secondaryColor
      background
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    __typename
    id
    isVerified
    email
  }
`;
export const LocationFragmentDoc = gql`
  fragment Location on Location {
    __typename
    id
    name
  }
`;
export const PositionFragmentDoc = gql`
  fragment Position on Position {
    __typename
    id
    name
    timestamp {
      createdAt
    }
    coordinates {
      lat
      lng
    }
  }
`;
export const NfcTagFragmentDoc = gql`
  fragment NFCTag on NFCTag {
    __typename
    id
  }
`;
export const QrCodeFragmentDoc = gql`
  fragment QRCode on QRCode {
    __typename
    id
    shortCode
  }
`;
export const QrCodeHelpFragmentDoc = gql`
  fragment QRCodeHelp on QRCodeHelp {
    __typename
    id
    showQuestions
  }
`;
export const QrCodeInternalChatFragmentDoc = gql`
  fragment QRCodeInternalChat on QRCodeInternalChat {
    __typename
    id
    hasPin
  }
`;
export const QrCodeDynamicFragmentDoc = gql`
  fragment QRCodeDynamic on QRCodeDynamic {
    __typename
    id
  }
`;
export const MinimalPositionFragmentDoc = gql`
  fragment MinimalPosition on Position {
    id
    name
  }
`;
export const _GeofencingMapFragmentDoc = gql`
  fragment _GeofencingMap on Geofencing {
    requirePosition
    requirePositionForVenue
    requirePositionForQRCodes
    polygons {
      __typename
      id
      coordinates {
        lat
        lng
      }
    }
    circles {
      __typename
      id
      center {
        lat
        lng
      }
      radius
    }
  }
`;
export const _GeofencingMapLocationFragmentDoc = gql`
  fragment _GeofencingMapLocation on Location {
    id
    name
    coordinates {
      lat
      lng
      radius
    }
    positions {
      __typename
      id
      name
      coordinates {
        lat
        lng
      }
      qrCodes {
        __typename
        id
        qrCode {
          __typename
          ... on QRCodeHelp {
            id
          }
          ... on QRCodeInternalChat {
            id
          }
          ... on QRCodeDynamic {
            id
          }
        }
      }
    }
  }
`;
export const _PositionListItemFragmentDoc = gql`
  fragment _PositionListItem on Position {
    id
    name
    qrCodes {
      id
      scanCount
      lastScan
      nfcTags {
        id
      }
      qrCode {
        __typename
        ... on QRCodeDynamic {
          id
        }
        ... on QRCodeHelp {
          id
        }
        ... on QRCodeInternalChat {
          id
        }
      }
    }
    coordinates {
      lat
      lng
    }
  }
`;
export const _LocationListItemFragmentDoc = gql`
  fragment _LocationListItem on Location {
    __typename
    id
    name
    coordinates {
      lat
      lng
      radius
    }
  }
`;
export const _GeofencingSettingsFragmentDoc = gql`
  fragment _GeofencingSettings on Geofencing {
    requirePosition
    requirePositionForVenue
    requirePositionForQRCodes
  }
`;
export const _VenueActiveSettingsFragmentDoc = gql`
  fragment _VenueActiveSettings on VenueActive {
    isRequired
    isActive
    startsAt
    endsAt
  }
`;
export const EditVenueBasicDetailsDocument = gql`
  mutation EditVenueBasicDetails($venueId: String!, $name: String!, $image: String!) {
    editVenueName(venueId: $venueId, name: $name) {
      __typename
      ... on Venue {
        id
        name
      }
    }
    editVenueImage(venueId: $venueId, image: $image) {
      __typename
      ... on Venue {
        id
        image
      }
    }
  }
`;
export type EditVenueBasicDetailsMutationFn = Apollo.MutationFunction<
  EditVenueBasicDetailsMutation,
  EditVenueBasicDetailsMutationVariables
>;

/**
 * __useEditVenueBasicDetailsMutation__
 *
 * To run a mutation, you first call `useEditVenueBasicDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVenueBasicDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVenueBasicDetailsMutation, { data, loading, error }] = useEditVenueBasicDetailsMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      name: // value for 'name'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useEditVenueBasicDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditVenueBasicDetailsMutation,
    EditVenueBasicDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditVenueBasicDetailsMutation, EditVenueBasicDetailsMutationVariables>(
    EditVenueBasicDetailsDocument,
    options,
  );
}
export type EditVenueBasicDetailsMutationHookResult = ReturnType<
  typeof useEditVenueBasicDetailsMutation
>;
export type EditVenueBasicDetailsMutationResult =
  Apollo.MutationResult<EditVenueBasicDetailsMutation>;
export type EditVenueBasicDetailsMutationOptions = Apollo.BaseMutationOptions<
  EditVenueBasicDetailsMutation,
  EditVenueBasicDetailsMutationVariables
>;
export const EditVenueDocument = gql`
  mutation EditVenue($venueId: String!, $name: String!, $details: VenueDetailsInput!) {
    editVenueName(venueId: $venueId, name: $name) {
      __typename
      id
      name
    }
    editVenueDetails(venueId: $venueId, details: $details) {
      ... on VenueDetails {
        ...VenueDetailsDetails
      }
      ... on ErrorReturn {
        message
        i18nKey
      }
    }
  }
  ${VenueDetailsDetailsFragmentDoc}
`;
export type EditVenueMutationFn = Apollo.MutationFunction<
  EditVenueMutation,
  EditVenueMutationVariables
>;

/**
 * __useEditVenueMutation__
 *
 * To run a mutation, you first call `useEditVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVenueMutation, { data, loading, error }] = useEditVenueMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      name: // value for 'name'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useEditVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<EditVenueMutation, EditVenueMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditVenueMutation, EditVenueMutationVariables>(
    EditVenueDocument,
    options,
  );
}
export type EditVenueMutationHookResult = ReturnType<typeof useEditVenueMutation>;
export type EditVenueMutationResult = Apollo.MutationResult<EditVenueMutation>;
export type EditVenueMutationOptions = Apollo.BaseMutationOptions<
  EditVenueMutation,
  EditVenueMutationVariables
>;
export const PrintJobDocument = gql`
  query PrintJob($id: String!) {
    printJob(id: $id) {
      ...PrintJobComponent
    }
  }
  ${PrintJobComponentFragmentDoc}
`;

/**
 * __usePrintJobQuery__
 *
 * To run a query within a React component, call `usePrintJobQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrintJobQuery(
  baseOptions: Apollo.QueryHookOptions<PrintJobQuery, PrintJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrintJobQuery, PrintJobQueryVariables>(PrintJobDocument, options);
}
export function usePrintJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrintJobQuery, PrintJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrintJobQuery, PrintJobQueryVariables>(PrintJobDocument, options);
}
export type PrintJobQueryHookResult = ReturnType<typeof usePrintJobQuery>;
export type PrintJobLazyQueryHookResult = ReturnType<typeof usePrintJobLazyQuery>;
export type PrintJobQueryResult = Apollo.QueryResult<PrintJobQuery, PrintJobQueryVariables>;
export const PrintJobsDocument = gql`
  query PrintJobs($input: PrintJobsInput!, $pagination: PaginationInput!) {
    printJobs(input: $input, pagination: $pagination) {
      items {
        ...PrintJobComponent
      }
    }
  }
  ${PrintJobComponentFragmentDoc}
`;

/**
 * __usePrintJobsQuery__
 *
 * To run a query within a React component, call `usePrintJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintJobsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePrintJobsQuery(
  baseOptions: Apollo.QueryHookOptions<PrintJobsQuery, PrintJobsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrintJobsQuery, PrintJobsQueryVariables>(PrintJobsDocument, options);
}
export function usePrintJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrintJobsQuery, PrintJobsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrintJobsQuery, PrintJobsQueryVariables>(PrintJobsDocument, options);
}
export type PrintJobsQueryHookResult = ReturnType<typeof usePrintJobsQuery>;
export type PrintJobsLazyQueryHookResult = ReturnType<typeof usePrintJobsLazyQuery>;
export type PrintJobsQueryResult = Apollo.QueryResult<PrintJobsQuery, PrintJobsQueryVariables>;
export const LogoutMobileDocument = gql`
  mutation LogoutMobile($sessionId: String!) {
    logoutMobile(sessionId: $sessionId) {
      ... on SuccessReturn {
        success
      }
    }
  }
`;
export type LogoutMobileMutationFn = Apollo.MutationFunction<
  LogoutMobileMutation,
  LogoutMobileMutationVariables
>;

/**
 * __useLogoutMobileMutation__
 *
 * To run a mutation, you first call `useLogoutMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMobileMutation, { data, loading, error }] = useLogoutMobileMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useLogoutMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMobileMutation, LogoutMobileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMobileMutation, LogoutMobileMutationVariables>(
    LogoutMobileDocument,
    options,
  );
}
export type LogoutMobileMutationHookResult = ReturnType<typeof useLogoutMobileMutation>;
export type LogoutMobileMutationResult = Apollo.MutationResult<LogoutMobileMutation>;
export type LogoutMobileMutationOptions = Apollo.BaseMutationOptions<
  LogoutMobileMutation,
  LogoutMobileMutationVariables
>;
export const AppRefreshDocument = gql`
  mutation AppRefresh($sessionId: String!) {
    appRefresh(sessionId: $sessionId) {
      ... on AppRefreshReturn {
        accessToken
        sessionId
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type AppRefreshMutationFn = Apollo.MutationFunction<
  AppRefreshMutation,
  AppRefreshMutationVariables
>;

/**
 * __useAppRefreshMutation__
 *
 * To run a mutation, you first call `useAppRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appRefreshMutation, { data, loading, error }] = useAppRefreshMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAppRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<AppRefreshMutation, AppRefreshMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppRefreshMutation, AppRefreshMutationVariables>(
    AppRefreshDocument,
    options,
  );
}
export type AppRefreshMutationHookResult = ReturnType<typeof useAppRefreshMutation>;
export type AppRefreshMutationResult = Apollo.MutationResult<AppRefreshMutation>;
export type AppRefreshMutationOptions = Apollo.BaseMutationOptions<
  AppRefreshMutation,
  AppRefreshMutationVariables
>;
export const CreateTrialDocument = gql`
  mutation createTrial($venueId: String!) {
    createTrial(venueId: $venueId) {
      __typename
      ... on CreateBookingSuccess {
        url
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type CreateTrialMutationFn = Apollo.MutationFunction<
  CreateTrialMutation,
  CreateTrialMutationVariables
>;

/**
 * __useCreateTrialMutation__
 *
 * To run a mutation, you first call `useCreateTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrialMutation, { data, loading, error }] = useCreateTrialMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useCreateTrialMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTrialMutation, CreateTrialMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTrialMutation, CreateTrialMutationVariables>(
    CreateTrialDocument,
    options,
  );
}
export type CreateTrialMutationHookResult = ReturnType<typeof useCreateTrialMutation>;
export type CreateTrialMutationResult = Apollo.MutationResult<CreateTrialMutation>;
export type CreateTrialMutationOptions = Apollo.BaseMutationOptions<
  CreateTrialMutation,
  CreateTrialMutationVariables
>;
export const CreateSubscriptionDocument = gql`
  mutation createSubscription($capacity: Int!, $venueId: String!) {
    createSubscription(capacity: $capacity, venueId: $venueId) {
      __typename
      ... on CreateBookingSuccess {
        url
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      capacity: // value for 'capacity'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(
    CreateSubscriptionDocument,
    options,
  );
}
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export const CreatePaymentDocument = gql`
  mutation createPayment(
    $capacity: Int!
    $venueId: String!
    $startsAt: DateTimeISO!
    $endsAt: DateTimeISO!
  ) {
    createPayment(capacity: $capacity, venueId: $venueId, startsAt: $startsAt, endsAt: $endsAt) {
      __typename
      ... on CreateBookingSuccess {
        url
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type CreatePaymentMutationFn = Apollo.MutationFunction<
  CreatePaymentMutation,
  CreatePaymentMutationVariables
>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      capacity: // value for 'capacity'
 *      venueId: // value for 'venueId'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useCreatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(
    CreatePaymentDocument,
    options,
  );
}
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMutation,
  CreatePaymentMutationVariables
>;
export const CancelBookingDocument = gql`
  mutation cancelBooking($bookingId: String!) {
    cancelBooking(bookingId: $bookingId) {
      __typename
      ... on Booking {
        ...ExtendedBooking
      }
      ... on ErrorReturn {
        message
      }
    }
  }
  ${ExtendedBookingFragmentDoc}
`;
export type CancelBookingMutationFn = Apollo.MutationFunction<
  CancelBookingMutation,
  CancelBookingMutationVariables
>;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useCancelBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(
    CancelBookingDocument,
    options,
  );
}
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = Apollo.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<
  CancelBookingMutation,
  CancelBookingMutationVariables
>;
export const DeleteLocationDocument = gql`
  mutation DeleteLocation($id: String!) {
    deleteLocation(id: $id) {
      ... on SuccessReturn {
        __typename
        success
      }
      ... on ErrorReturn {
        __typename
        message
      }
    }
  }
`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(
    DeleteLocationDocument,
    options,
  );
}
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;
export const EditLocationNameDocument = gql`
  mutation EditLocationName($id: String!, $name: String!) {
    editLocationName(id: $id, name: $name) {
      __typename
      ... on Location {
        id
        name
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type EditLocationNameMutationFn = Apollo.MutationFunction<
  EditLocationNameMutation,
  EditLocationNameMutationVariables
>;

/**
 * __useEditLocationNameMutation__
 *
 * To run a mutation, you first call `useEditLocationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLocationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLocationNameMutation, { data, loading, error }] = useEditLocationNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditLocationNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditLocationNameMutation,
    EditLocationNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditLocationNameMutation, EditLocationNameMutationVariables>(
    EditLocationNameDocument,
    options,
  );
}
export type EditLocationNameMutationHookResult = ReturnType<typeof useEditLocationNameMutation>;
export type EditLocationNameMutationResult = Apollo.MutationResult<EditLocationNameMutation>;
export type EditLocationNameMutationOptions = Apollo.BaseMutationOptions<
  EditLocationNameMutation,
  EditLocationNameMutationVariables
>;
export const GetNotificationsDocument = gql`
  query GetNotifications($venueId: String!) {
    getNotifications(venueId: $venueId) {
      __typename
      id
      enabled
      notificationChannels {
        __typename
        id
        type
        receiver
        errorAt
      }
    }
  }
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  );
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  );
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>;
export const ToggleNotificationsDocument = gql`
  mutation ToggleNotifications($venueId: String!, $enabled: Boolean!) {
    toggleNotifications(venueId: $venueId, enabled: $enabled) {
      __typename
      id
      enabled
    }
  }
`;
export type ToggleNotificationsMutationFn = Apollo.MutationFunction<
  ToggleNotificationsMutation,
  ToggleNotificationsMutationVariables
>;

/**
 * __useToggleNotificationsMutation__
 *
 * To run a mutation, you first call `useToggleNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleNotificationsMutation, { data, loading, error }] = useToggleNotificationsMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleNotificationsMutation,
    ToggleNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleNotificationsMutation, ToggleNotificationsMutationVariables>(
    ToggleNotificationsDocument,
    options,
  );
}
export type ToggleNotificationsMutationHookResult = ReturnType<
  typeof useToggleNotificationsMutation
>;
export type ToggleNotificationsMutationResult = Apollo.MutationResult<ToggleNotificationsMutation>;
export type ToggleNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ToggleNotificationsMutation,
  ToggleNotificationsMutationVariables
>;
export const UpsertNotificationChannelDocument = gql`
  mutation UpsertNotificationChannel(
    $id: String
    $venueId: String!
    $receiver: String!
    $notificationChannelType: NotificationChannelType!
  ) {
    upsertNotificationChannel(
      id: $id
      venueId: $venueId
      receiver: $receiver
      notificationChannelType: $notificationChannelType
    ) {
      __typename
      id
      type
      receiver
      errorAt
    }
  }
`;
export type UpsertNotificationChannelMutationFn = Apollo.MutationFunction<
  UpsertNotificationChannelMutation,
  UpsertNotificationChannelMutationVariables
>;

/**
 * __useUpsertNotificationChannelMutation__
 *
 * To run a mutation, you first call `useUpsertNotificationChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotificationChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotificationChannelMutation, { data, loading, error }] = useUpsertNotificationChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      venueId: // value for 'venueId'
 *      receiver: // value for 'receiver'
 *      notificationChannelType: // value for 'notificationChannelType'
 *   },
 * });
 */
export function useUpsertNotificationChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertNotificationChannelMutation,
    UpsertNotificationChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertNotificationChannelMutation,
    UpsertNotificationChannelMutationVariables
  >(UpsertNotificationChannelDocument, options);
}
export type UpsertNotificationChannelMutationHookResult = ReturnType<
  typeof useUpsertNotificationChannelMutation
>;
export type UpsertNotificationChannelMutationResult =
  Apollo.MutationResult<UpsertNotificationChannelMutation>;
export type UpsertNotificationChannelMutationOptions = Apollo.BaseMutationOptions<
  UpsertNotificationChannelMutation,
  UpsertNotificationChannelMutationVariables
>;
export const PermissionsForVenueDocument = gql`
  query PermissionsForVenue($venueId: String!) {
    permissionsForVenue(venueId: $venueId) {
      __typename
      ... on PermissionForVenueSuccess {
        items {
          ...UserPermission
        }
      }
      ... on ErrorReturn {
        message
      }
    }
  }
  ${UserPermissionFragmentDoc}
`;

/**
 * __usePermissionsForVenueQuery__
 *
 * To run a query within a React component, call `usePermissionsForVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsForVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsForVenueQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function usePermissionsForVenueQuery(
  baseOptions: Apollo.QueryHookOptions<PermissionsForVenueQuery, PermissionsForVenueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PermissionsForVenueQuery, PermissionsForVenueQueryVariables>(
    PermissionsForVenueDocument,
    options,
  );
}
export function usePermissionsForVenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PermissionsForVenueQuery,
    PermissionsForVenueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PermissionsForVenueQuery, PermissionsForVenueQueryVariables>(
    PermissionsForVenueDocument,
    options,
  );
}
export type PermissionsForVenueQueryHookResult = ReturnType<typeof usePermissionsForVenueQuery>;
export type PermissionsForVenueLazyQueryHookResult = ReturnType<
  typeof usePermissionsForVenueLazyQuery
>;
export type PermissionsForVenueQueryResult = Apollo.QueryResult<
  PermissionsForVenueQuery,
  PermissionsForVenueQueryVariables
>;
export const GetMyPermissionsPaginatedDocument = gql`
  query GetMyPermissionsPaginated($skip: Int!, $take: Int!, $tags: [String!], $name: String) {
    getMyPermissionsPaginated(skip: $skip, take: $take, tags: $tags, name: $name) {
      hasMore
      total
      items {
        ...BasePermission
        venue {
          ...VenueDetails
          activeSubscription {
            ...BaseBooking
          }
        }
      }
    }
  }
  ${BasePermissionFragmentDoc}
  ${VenueDetailsFragmentDoc}
  ${BaseBookingFragmentDoc}
`;

/**
 * __useGetMyPermissionsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetMyPermissionsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPermissionsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPermissionsPaginatedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      tags: // value for 'tags'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetMyPermissionsPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyPermissionsPaginatedQuery,
    GetMyPermissionsPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyPermissionsPaginatedQuery, GetMyPermissionsPaginatedQueryVariables>(
    GetMyPermissionsPaginatedDocument,
    options,
  );
}
export function useGetMyPermissionsPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPermissionsPaginatedQuery,
    GetMyPermissionsPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPermissionsPaginatedQuery,
    GetMyPermissionsPaginatedQueryVariables
  >(GetMyPermissionsPaginatedDocument, options);
}
export type GetMyPermissionsPaginatedQueryHookResult = ReturnType<
  typeof useGetMyPermissionsPaginatedQuery
>;
export type GetMyPermissionsPaginatedLazyQueryHookResult = ReturnType<
  typeof useGetMyPermissionsPaginatedLazyQuery
>;
export type GetMyPermissionsPaginatedQueryResult = Apollo.QueryResult<
  GetMyPermissionsPaginatedQuery,
  GetMyPermissionsPaginatedQueryVariables
>;
export const DeletePermissionDocument = gql`
  mutation DeletePermission($venueId: String!, $permissionId: String!) {
    deletePermission(venueId: $venueId, permissionId: $permissionId)
  }
`;
export type DeletePermissionMutationFn = Apollo.MutationFunction<
  DeletePermissionMutation,
  DeletePermissionMutationVariables
>;

/**
 * __useDeletePermissionMutation__
 *
 * To run a mutation, you first call `useDeletePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionMutation, { data, loading, error }] = useDeletePermissionMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      permissionId: // value for 'permissionId'
 *   },
 * });
 */
export function useDeletePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePermissionMutation,
    DeletePermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePermissionMutation, DeletePermissionMutationVariables>(
    DeletePermissionDocument,
    options,
  );
}
export type DeletePermissionMutationHookResult = ReturnType<typeof useDeletePermissionMutation>;
export type DeletePermissionMutationResult = Apollo.MutationResult<DeletePermissionMutation>;
export type DeletePermissionMutationOptions = Apollo.BaseMutationOptions<
  DeletePermissionMutation,
  DeletePermissionMutationVariables
>;
export const CreateQrCodeHelpDocument = gql`
  mutation CreateQRCodeHelp(
    $name: String
    $locationId: String!
    $hasChat: Boolean!
    $showQuestions: Boolean!
  ) {
    createQRCodeHelp(
      name: $name
      locationId: $locationId
      hasChat: $hasChat
      showQuestions: $showQuestions
    ) {
      id
      showQuestions
    }
  }
`;
export type CreateQrCodeHelpMutationFn = Apollo.MutationFunction<
  CreateQrCodeHelpMutation,
  CreateQrCodeHelpMutationVariables
>;

/**
 * __useCreateQrCodeHelpMutation__
 *
 * To run a mutation, you first call `useCreateQrCodeHelpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQrCodeHelpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQrCodeHelpMutation, { data, loading, error }] = useCreateQrCodeHelpMutation({
 *   variables: {
 *      name: // value for 'name'
 *      locationId: // value for 'locationId'
 *      hasChat: // value for 'hasChat'
 *      showQuestions: // value for 'showQuestions'
 *   },
 * });
 */
export function useCreateQrCodeHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQrCodeHelpMutation,
    CreateQrCodeHelpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateQrCodeHelpMutation, CreateQrCodeHelpMutationVariables>(
    CreateQrCodeHelpDocument,
    options,
  );
}
export type CreateQrCodeHelpMutationHookResult = ReturnType<typeof useCreateQrCodeHelpMutation>;
export type CreateQrCodeHelpMutationResult = Apollo.MutationResult<CreateQrCodeHelpMutation>;
export type CreateQrCodeHelpMutationOptions = Apollo.BaseMutationOptions<
  CreateQrCodeHelpMutation,
  CreateQrCodeHelpMutationVariables
>;
export const CreateQrCodeInternalChatDocument = gql`
  mutation CreateQRCodeInternalChat($locationId: String!, $hasPin: Boolean!, $name: String) {
    createQRCodeInternalChat(locationId: $locationId, hasPin: $hasPin, name: $name) {
      id
      hasPin
    }
  }
`;
export type CreateQrCodeInternalChatMutationFn = Apollo.MutationFunction<
  CreateQrCodeInternalChatMutation,
  CreateQrCodeInternalChatMutationVariables
>;

/**
 * __useCreateQrCodeInternalChatMutation__
 *
 * To run a mutation, you first call `useCreateQrCodeInternalChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQrCodeInternalChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQrCodeInternalChatMutation, { data, loading, error }] = useCreateQrCodeInternalChatMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      hasPin: // value for 'hasPin'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateQrCodeInternalChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQrCodeInternalChatMutation,
    CreateQrCodeInternalChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQrCodeInternalChatMutation,
    CreateQrCodeInternalChatMutationVariables
  >(CreateQrCodeInternalChatDocument, options);
}
export type CreateQrCodeInternalChatMutationHookResult = ReturnType<
  typeof useCreateQrCodeInternalChatMutation
>;
export type CreateQrCodeInternalChatMutationResult =
  Apollo.MutationResult<CreateQrCodeInternalChatMutation>;
export type CreateQrCodeInternalChatMutationOptions = Apollo.BaseMutationOptions<
  CreateQrCodeInternalChatMutation,
  CreateQrCodeInternalChatMutationVariables
>;
export const GetStatisticsDocument = gql`
  query GetStatistics {
    getStatistics {
      venues {
        venue {
          id
          name
        }
        helpStream {
          entries {
            ...BaseHelpStreamEntry
          }
        }
        last24Hours {
          internal
          external
        }
        last28Days {
          internal
          external
        }
        lastWeek {
          internal
          external
        }
        lastYear {
          internal
          external
        }
      }
    }
  }
  ${BaseHelpStreamEntryFragmentDoc}
`;

/**
 * __useGetStatisticsQuery__
 *
 * To run a query within a React component, call `useGetStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStatisticsQuery, GetStatisticsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(
    GetStatisticsDocument,
    options,
  );
}
export function useGetStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticsQuery, GetStatisticsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(
    GetStatisticsDocument,
    options,
  );
}
export type GetStatisticsQueryHookResult = ReturnType<typeof useGetStatisticsQuery>;
export type GetStatisticsLazyQueryHookResult = ReturnType<typeof useGetStatisticsLazyQuery>;
export type GetStatisticsQueryResult = Apollo.QueryResult<
  GetStatisticsQuery,
  GetStatisticsQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ... on Anonymous {
        uuid
      }
      ... on User {
        ...BaseUser
        permissions {
          ...BasePermission
        }
      }
      ... on ErrorReturn {
        message
      }
    }
  }
  ${BaseUserFragmentDoc}
  ${BasePermissionFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateFcmTokenDocument = gql`
  mutation UpdateFCMToken($token: String!) {
    updateFCMToken(token: $token) {
      ... on User {
        __typename
        id
        email
        phoneNumber
        fcmToken
        name
        permissions {
          __typename
          id
          roles
          scopes
          venue {
            __typename
            id
            name
            image
            timestamp {
              createdAt
              updatedAt
            }
          }
        }
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type UpdateFcmTokenMutationFn = Apollo.MutationFunction<
  UpdateFcmTokenMutation,
  UpdateFcmTokenMutationVariables
>;

/**
 * __useUpdateFcmTokenMutation__
 *
 * To run a mutation, you first call `useUpdateFcmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFcmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFcmTokenMutation, { data, loading, error }] = useUpdateFcmTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdateFcmTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFcmTokenMutation, UpdateFcmTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFcmTokenMutation, UpdateFcmTokenMutationVariables>(
    UpdateFcmTokenDocument,
    options,
  );
}
export type UpdateFcmTokenMutationHookResult = ReturnType<typeof useUpdateFcmTokenMutation>;
export type UpdateFcmTokenMutationResult = Apollo.MutationResult<UpdateFcmTokenMutation>;
export type UpdateFcmTokenMutationOptions = Apollo.BaseMutationOptions<
  UpdateFcmTokenMutation,
  UpdateFcmTokenMutationVariables
>;
export const CreateVenueDocument = gql`
  mutation CreateVenue($name: String!, $details: VenueDetailsInput!) {
    createVenue(name: $name, details: $details) {
      ... on CreateVenueSuccessResult {
        accessToken
        venue {
          __typename
          id
          name
          image
          settings {
            ...VenueSettings
          }
          activeSubscription {
            id
          }
        }
      }
      ... on ErrorReturn {
        message
      }
    }
  }
  ${VenueSettingsFragmentDoc}
`;
export type CreateVenueMutationFn = Apollo.MutationFunction<
  CreateVenueMutation,
  CreateVenueMutationVariables
>;

/**
 * __useCreateVenueMutation__
 *
 * To run a mutation, you first call `useCreateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVenueMutation, { data, loading, error }] = useCreateVenueMutation({
 *   variables: {
 *      name: // value for 'name'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useCreateVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVenueMutation, CreateVenueMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVenueMutation, CreateVenueMutationVariables>(
    CreateVenueDocument,
    options,
  );
}
export type CreateVenueMutationHookResult = ReturnType<typeof useCreateVenueMutation>;
export type CreateVenueMutationResult = Apollo.MutationResult<CreateVenueMutation>;
export type CreateVenueMutationOptions = Apollo.BaseMutationOptions<
  CreateVenueMutation,
  CreateVenueMutationVariables
>;
export const EditVenueImageDocument = gql`
  mutation EditVenueImage($venueId: String!, $image: String!) {
    editVenueImage(venueId: $venueId, image: $image) {
      __typename
      id
      image
    }
  }
`;
export type EditVenueImageMutationFn = Apollo.MutationFunction<
  EditVenueImageMutation,
  EditVenueImageMutationVariables
>;

/**
 * __useEditVenueImageMutation__
 *
 * To run a mutation, you first call `useEditVenueImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVenueImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVenueImageMutation, { data, loading, error }] = useEditVenueImageMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useEditVenueImageMutation(
  baseOptions?: Apollo.MutationHookOptions<EditVenueImageMutation, EditVenueImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditVenueImageMutation, EditVenueImageMutationVariables>(
    EditVenueImageDocument,
    options,
  );
}
export type EditVenueImageMutationHookResult = ReturnType<typeof useEditVenueImageMutation>;
export type EditVenueImageMutationResult = Apollo.MutationResult<EditVenueImageMutation>;
export type EditVenueImageMutationOptions = Apollo.BaseMutationOptions<
  EditVenueImageMutation,
  EditVenueImageMutationVariables
>;
export const EditVenuePhoneNumberDocument = gql`
  mutation EditVenuePhoneNumber($venueId: String!, $phoneNumber: String!) {
    editVenuePhoneNumber(venueId: $venueId, phoneNumber: $phoneNumber) {
      __typename
      id
      phoneNumber
    }
  }
`;
export type EditVenuePhoneNumberMutationFn = Apollo.MutationFunction<
  EditVenuePhoneNumberMutation,
  EditVenuePhoneNumberMutationVariables
>;

/**
 * __useEditVenuePhoneNumberMutation__
 *
 * To run a mutation, you first call `useEditVenuePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVenuePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVenuePhoneNumberMutation, { data, loading, error }] = useEditVenuePhoneNumberMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useEditVenuePhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditVenuePhoneNumberMutation,
    EditVenuePhoneNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditVenuePhoneNumberMutation, EditVenuePhoneNumberMutationVariables>(
    EditVenuePhoneNumberDocument,
    options,
  );
}
export type EditVenuePhoneNumberMutationHookResult = ReturnType<
  typeof useEditVenuePhoneNumberMutation
>;
export type EditVenuePhoneNumberMutationResult =
  Apollo.MutationResult<EditVenuePhoneNumberMutation>;
export type EditVenuePhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  EditVenuePhoneNumberMutation,
  EditVenuePhoneNumberMutationVariables
>;
export const GetVenueFeedbackDocument = gql`
  query GetVenueFeedback($venueId: String!) {
    getVenueFeedback(venueId: $venueId) {
      id
      timestamp {
        createdAt
      }
      feedback {
        key
        label
        value
      }
    }
  }
`;

/**
 * __useGetVenueFeedbackQuery__
 *
 * To run a query within a React component, call `useGetVenueFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueFeedbackQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useGetVenueFeedbackQuery(
  baseOptions: Apollo.QueryHookOptions<GetVenueFeedbackQuery, GetVenueFeedbackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVenueFeedbackQuery, GetVenueFeedbackQueryVariables>(
    GetVenueFeedbackDocument,
    options,
  );
}
export function useGetVenueFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVenueFeedbackQuery, GetVenueFeedbackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVenueFeedbackQuery, GetVenueFeedbackQueryVariables>(
    GetVenueFeedbackDocument,
    options,
  );
}
export type GetVenueFeedbackQueryHookResult = ReturnType<typeof useGetVenueFeedbackQuery>;
export type GetVenueFeedbackLazyQueryHookResult = ReturnType<typeof useGetVenueFeedbackLazyQuery>;
export type GetVenueFeedbackQueryResult = Apollo.QueryResult<
  GetVenueFeedbackQuery,
  GetVenueFeedbackQueryVariables
>;
export const GetVenueThemeDocument = gql`
  query GetVenueTheme($venueId: String!, $language: String!) {
    getVenueTheme(venueId: $venueId) {
      __typename
      id
      images {
        background
        logo
      }
      colors {
        primaryColor
        secondaryColor
        background
      }
      texts(language: $language) {
        ...Translations
      }
      venue {
        id
        name
        phoneNumber
        settings {
          ...VenueSettings
        }
      }
    }
  }
  ${TranslationsFragmentDoc}
  ${VenueSettingsFragmentDoc}
`;

/**
 * __useGetVenueThemeQuery__
 *
 * To run a query within a React component, call `useGetVenueThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueThemeQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetVenueThemeQuery(
  baseOptions: Apollo.QueryHookOptions<GetVenueThemeQuery, GetVenueThemeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVenueThemeQuery, GetVenueThemeQueryVariables>(
    GetVenueThemeDocument,
    options,
  );
}
export function useGetVenueThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVenueThemeQuery, GetVenueThemeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVenueThemeQuery, GetVenueThemeQueryVariables>(
    GetVenueThemeDocument,
    options,
  );
}
export type GetVenueThemeQueryHookResult = ReturnType<typeof useGetVenueThemeQuery>;
export type GetVenueThemeLazyQueryHookResult = ReturnType<typeof useGetVenueThemeLazyQuery>;
export type GetVenueThemeQueryResult = Apollo.QueryResult<
  GetVenueThemeQuery,
  GetVenueThemeQueryVariables
>;
export const GetVenueThemeTextsDocument = gql`
  query GetVenueThemeTexts($venueId: String!, $language: String!) {
    getVenueTheme(venueId: $venueId) {
      __typename
      id
      texts(language: $language) {
        ...Translations
      }
    }
  }
  ${TranslationsFragmentDoc}
`;

/**
 * __useGetVenueThemeTextsQuery__
 *
 * To run a query within a React component, call `useGetVenueThemeTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueThemeTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueThemeTextsQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetVenueThemeTextsQuery(
  baseOptions: Apollo.QueryHookOptions<GetVenueThemeTextsQuery, GetVenueThemeTextsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVenueThemeTextsQuery, GetVenueThemeTextsQueryVariables>(
    GetVenueThemeTextsDocument,
    options,
  );
}
export function useGetVenueThemeTextsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVenueThemeTextsQuery,
    GetVenueThemeTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVenueThemeTextsQuery, GetVenueThemeTextsQueryVariables>(
    GetVenueThemeTextsDocument,
    options,
  );
}
export type GetVenueThemeTextsQueryHookResult = ReturnType<typeof useGetVenueThemeTextsQuery>;
export type GetVenueThemeTextsLazyQueryHookResult = ReturnType<
  typeof useGetVenueThemeTextsLazyQuery
>;
export type GetVenueThemeTextsQueryResult = Apollo.QueryResult<
  GetVenueThemeTextsQuery,
  GetVenueThemeTextsQueryVariables
>;
export const UpdateVenueThemeDocument = gql`
  mutation UpdateVenueTheme(
    $venueId: String!
    $colors: VenueThemeColorsInput!
    $language: String!
  ) {
    updateVenueTheme(venueId: $venueId, colors: $colors) {
      __typename
      id
      images {
        background
        logo
      }
      colors {
        primaryColor
        secondaryColor
        background
      }
      texts(language: $language) {
        ...Translations
      }
      venue {
        id
        name
        settings {
          requirePosition
          isWhiteLabel
        }
      }
    }
  }
  ${TranslationsFragmentDoc}
`;
export type UpdateVenueThemeMutationFn = Apollo.MutationFunction<
  UpdateVenueThemeMutation,
  UpdateVenueThemeMutationVariables
>;

/**
 * __useUpdateVenueThemeMutation__
 *
 * To run a mutation, you first call `useUpdateVenueThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueThemeMutation, { data, loading, error }] = useUpdateVenueThemeMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      colors: // value for 'colors'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateVenueThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVenueThemeMutation,
    UpdateVenueThemeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVenueThemeMutation, UpdateVenueThemeMutationVariables>(
    UpdateVenueThemeDocument,
    options,
  );
}
export type UpdateVenueThemeMutationHookResult = ReturnType<typeof useUpdateVenueThemeMutation>;
export type UpdateVenueThemeMutationResult = Apollo.MutationResult<UpdateVenueThemeMutation>;
export type UpdateVenueThemeMutationOptions = Apollo.BaseMutationOptions<
  UpdateVenueThemeMutation,
  UpdateVenueThemeMutationVariables
>;
export const BookingsDocument = gql`
  query Bookings($filter: BookingFilterInput, $pagination: PaginationInput!) {
    bookings(filter: $filter, pagination: $pagination) {
      __typename
      ... on PaginatedBookingResponse {
        total
        hasMore
        items {
          id
          timestamp {
            createdAt
            updatedAt
          }
          startsAt
          endsAt
          trialEndsAt
          type
          status
          subscriptionAmount
          provider
          featureType
          user {
            ...MinimalUser
          }
          venue {
            ...MinimalVenue
          }
        }
      }
    }
  }
  ${MinimalUserFragmentDoc}
  ${MinimalVenueFragmentDoc}
`;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
}
export function useBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingsQuery, BookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
}
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsQueryResult = Apollo.QueryResult<BookingsQuery, BookingsQueryVariables>;
export const ResetVenueArchivedDocument = gql`
  mutation ResetVenueArchived($venueId: String!) {
    resetVenueArchived(venueId: $venueId) {
      id
      archivedAt
    }
  }
`;
export type ResetVenueArchivedMutationFn = Apollo.MutationFunction<
  ResetVenueArchivedMutation,
  ResetVenueArchivedMutationVariables
>;

/**
 * __useResetVenueArchivedMutation__
 *
 * To run a mutation, you first call `useResetVenueArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetVenueArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetVenueArchivedMutation, { data, loading, error }] = useResetVenueArchivedMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useResetVenueArchivedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetVenueArchivedMutation,
    ResetVenueArchivedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetVenueArchivedMutation, ResetVenueArchivedMutationVariables>(
    ResetVenueArchivedDocument,
    options,
  );
}
export type ResetVenueArchivedMutationHookResult = ReturnType<typeof useResetVenueArchivedMutation>;
export type ResetVenueArchivedMutationResult = Apollo.MutationResult<ResetVenueArchivedMutation>;
export type ResetVenueArchivedMutationOptions = Apollo.BaseMutationOptions<
  ResetVenueArchivedMutation,
  ResetVenueArchivedMutationVariables
>;
export const VenuesArchiveDocument = gql`
  query VenuesArchive($take: Int!, $skip: Int!) {
    venuesArchive(take: $take, skip: $skip) {
      items {
        id
        name
        archivedAt
        timestamp {
          deletedAt
        }
      }
      total
      hasMore
    }
  }
`;

/**
 * __useVenuesArchiveQuery__
 *
 * To run a query within a React component, call `useVenuesArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuesArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuesArchiveQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useVenuesArchiveQuery(
  baseOptions: Apollo.QueryHookOptions<VenuesArchiveQuery, VenuesArchiveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VenuesArchiveQuery, VenuesArchiveQueryVariables>(
    VenuesArchiveDocument,
    options,
  );
}
export function useVenuesArchiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VenuesArchiveQuery, VenuesArchiveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VenuesArchiveQuery, VenuesArchiveQueryVariables>(
    VenuesArchiveDocument,
    options,
  );
}
export type VenuesArchiveQueryHookResult = ReturnType<typeof useVenuesArchiveQuery>;
export type VenuesArchiveLazyQueryHookResult = ReturnType<typeof useVenuesArchiveLazyQuery>;
export type VenuesArchiveQueryResult = Apollo.QueryResult<
  VenuesArchiveQuery,
  VenuesArchiveQueryVariables
>;
export const CreateManuaPaymentDocument = gql`
  mutation CreateManuaPayment(
    $capacity: Int!
    $endsAt: DateTimeISO!
    $startsAt: DateTimeISO!
    $email: String!
    $venueId: String!
    $bookingFeatureType: BookingFeatureType!
  ) {
    createManualPayment(
      capacity: $capacity
      endsAt: $endsAt
      startsAt: $startsAt
      email: $email
      venueId: $venueId
      bookingFeatureType: $bookingFeatureType
    ) {
      __typename
      ... on GraphQLBooking {
        id
        endsAt
        startsAt
        trialEndsAt
        type
        user {
          id
          email
        }
        venue {
          id
          name
        }
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type CreateManuaPaymentMutationFn = Apollo.MutationFunction<
  CreateManuaPaymentMutation,
  CreateManuaPaymentMutationVariables
>;

/**
 * __useCreateManuaPaymentMutation__
 *
 * To run a mutation, you first call `useCreateManuaPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManuaPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManuaPaymentMutation, { data, loading, error }] = useCreateManuaPaymentMutation({
 *   variables: {
 *      capacity: // value for 'capacity'
 *      endsAt: // value for 'endsAt'
 *      startsAt: // value for 'startsAt'
 *      email: // value for 'email'
 *      venueId: // value for 'venueId'
 *      bookingFeatureType: // value for 'bookingFeatureType'
 *   },
 * });
 */
export function useCreateManuaPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManuaPaymentMutation,
    CreateManuaPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateManuaPaymentMutation, CreateManuaPaymentMutationVariables>(
    CreateManuaPaymentDocument,
    options,
  );
}
export type CreateManuaPaymentMutationHookResult = ReturnType<typeof useCreateManuaPaymentMutation>;
export type CreateManuaPaymentMutationResult = Apollo.MutationResult<CreateManuaPaymentMutation>;
export type CreateManuaPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateManuaPaymentMutation,
  CreateManuaPaymentMutationVariables
>;
export const CreateManualSubscriptionDocument = gql`
  mutation CreateManualSubscription(
    $capacity: Int!
    $startsAt: DateTimeISO!
    $email: String!
    $venueId: String!
    $bookingFeatureType: BookingFeatureType!
  ) {
    createManualSubscription(
      capacity: $capacity
      startsAt: $startsAt
      email: $email
      venueId: $venueId
      bookingFeatureType: $bookingFeatureType
    ) {
      __typename
      ... on GraphQLBooking {
        id
        endsAt
        startsAt
        trialEndsAt
        type
        user {
          id
          email
        }
        venue {
          id
          name
        }
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type CreateManualSubscriptionMutationFn = Apollo.MutationFunction<
  CreateManualSubscriptionMutation,
  CreateManualSubscriptionMutationVariables
>;

/**
 * __useCreateManualSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateManualSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualSubscriptionMutation, { data, loading, error }] = useCreateManualSubscriptionMutation({
 *   variables: {
 *      capacity: // value for 'capacity'
 *      startsAt: // value for 'startsAt'
 *      email: // value for 'email'
 *      venueId: // value for 'venueId'
 *      bookingFeatureType: // value for 'bookingFeatureType'
 *   },
 * });
 */
export function useCreateManualSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManualSubscriptionMutation,
    CreateManualSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManualSubscriptionMutation,
    CreateManualSubscriptionMutationVariables
  >(CreateManualSubscriptionDocument, options);
}
export type CreateManualSubscriptionMutationHookResult = ReturnType<
  typeof useCreateManualSubscriptionMutation
>;
export type CreateManualSubscriptionMutationResult =
  Apollo.MutationResult<CreateManualSubscriptionMutation>;
export type CreateManualSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateManualSubscriptionMutation,
  CreateManualSubscriptionMutationVariables
>;
export const SetVenueArchivedDocument = gql`
  mutation SetVenueArchived($venueId: String!) {
    setVenueArchived(venueId: $venueId) {
      id
    }
  }
`;
export type SetVenueArchivedMutationFn = Apollo.MutationFunction<
  SetVenueArchivedMutation,
  SetVenueArchivedMutationVariables
>;

/**
 * __useSetVenueArchivedMutation__
 *
 * To run a mutation, you first call `useSetVenueArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVenueArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVenueArchivedMutation, { data, loading, error }] = useSetVenueArchivedMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useSetVenueArchivedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVenueArchivedMutation,
    SetVenueArchivedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetVenueArchivedMutation, SetVenueArchivedMutationVariables>(
    SetVenueArchivedDocument,
    options,
  );
}
export type SetVenueArchivedMutationHookResult = ReturnType<typeof useSetVenueArchivedMutation>;
export type SetVenueArchivedMutationResult = Apollo.MutationResult<SetVenueArchivedMutation>;
export type SetVenueArchivedMutationOptions = Apollo.BaseMutationOptions<
  SetVenueArchivedMutation,
  SetVenueArchivedMutationVariables
>;
export const GetAllVenuesDocument = gql`
  query GetAllVenues($take: Int!, $skip: Int!, $tags: [String!], $name: String) {
    venues(take: $take, skip: $skip, tags: $tags, name: $name) {
      items {
        ...MinimalVenue
        details {
          weeklyOpen
          type
          capacity
          startsAt
          endsAt
        }
        contactUser {
          ...MinimalUser
        }
        timestamp {
          ...Timestamp
        }
        activeSubscription {
          ...BaseBooking
        }
        hasActivePaidSubscription
      }
      total
      hasMore
    }
  }
  ${MinimalVenueFragmentDoc}
  ${MinimalUserFragmentDoc}
  ${TimestampFragmentDoc}
  ${BaseBookingFragmentDoc}
`;

/**
 * __useGetAllVenuesQuery__
 *
 * To run a query within a React component, call `useGetAllVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVenuesQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      tags: // value for 'tags'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetAllVenuesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllVenuesQuery, GetAllVenuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllVenuesQuery, GetAllVenuesQueryVariables>(
    GetAllVenuesDocument,
    options,
  );
}
export function useGetAllVenuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllVenuesQuery, GetAllVenuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllVenuesQuery, GetAllVenuesQueryVariables>(
    GetAllVenuesDocument,
    options,
  );
}
export type GetAllVenuesQueryHookResult = ReturnType<typeof useGetAllVenuesQuery>;
export type GetAllVenuesLazyQueryHookResult = ReturnType<typeof useGetAllVenuesLazyQuery>;
export type GetAllVenuesQueryResult = Apollo.QueryResult<
  GetAllVenuesQuery,
  GetAllVenuesQueryVariables
>;
export const GetAllUsersDocument = gql`
  query GetAllUsers($skip: Int!, $take: Int!) {
    users(skip: $skip, take: $take) {
      total
      hasMore
      items {
        ...MinimalUser
        timestamp {
          createdAt
          updatedAt
        }
        lastRefresh
        isVerified
        permissions {
          ...MinimalPermission
          venue {
            ...MinimalVenue
          }
        }
      }
    }
  }
  ${MinimalUserFragmentDoc}
  ${MinimalPermissionFragmentDoc}
  ${MinimalVenueFragmentDoc}
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options,
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const BookingsByUserDocument = gql`
  query BookingsByUser($filter: BookingFilterInput, $pagination: PaginationInput!) {
    bookingsByUser(filter: $filter, pagination: $pagination) {
      __typename
      ... on ErrorReturn {
        message
      }
      ... on PaginatedBookingResponse {
        total
        hasMore
        items {
          ...ExtendedBooking
          venue {
            ...MinimalVenue
          }
        }
      }
    }
  }
  ${ExtendedBookingFragmentDoc}
  ${MinimalVenueFragmentDoc}
`;

/**
 * __useBookingsByUserQuery__
 *
 * To run a query within a React component, call `useBookingsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsByUserQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBookingsByUserQuery(
  baseOptions: Apollo.QueryHookOptions<BookingsByUserQuery, BookingsByUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingsByUserQuery, BookingsByUserQueryVariables>(
    BookingsByUserDocument,
    options,
  );
}
export function useBookingsByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingsByUserQuery, BookingsByUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingsByUserQuery, BookingsByUserQueryVariables>(
    BookingsByUserDocument,
    options,
  );
}
export type BookingsByUserQueryHookResult = ReturnType<typeof useBookingsByUserQuery>;
export type BookingsByUserLazyQueryHookResult = ReturnType<typeof useBookingsByUserLazyQuery>;
export type BookingsByUserQueryResult = Apollo.QueryResult<
  BookingsByUserQuery,
  BookingsByUserQueryVariables
>;
export const GetPricingDocument = gql`
  query GetPricing($capacity: Int!, $item: ITEMS!) {
    pricing(capacity: $capacity, item: $item) {
      price
    }
  }
`;

/**
 * __useGetPricingQuery__
 *
 * To run a query within a React component, call `useGetPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricingQuery({
 *   variables: {
 *      capacity: // value for 'capacity'
 *      item: // value for 'item'
 *   },
 * });
 */
export function useGetPricingQuery(
  baseOptions: Apollo.QueryHookOptions<GetPricingQuery, GetPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPricingQuery, GetPricingQueryVariables>(GetPricingDocument, options);
}
export function useGetPricingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPricingQuery, GetPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPricingQuery, GetPricingQueryVariables>(
    GetPricingDocument,
    options,
  );
}
export type GetPricingQueryHookResult = ReturnType<typeof useGetPricingQuery>;
export type GetPricingLazyQueryHookResult = ReturnType<typeof useGetPricingLazyQuery>;
export type GetPricingQueryResult = Apollo.QueryResult<GetPricingQuery, GetPricingQueryVariables>;
export const UpdateNameDocument = gql`
  mutation UpdateName($name: String!) {
    updateName(name: $name) {
      ... on User {
        __typename
        id
        name
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type UpdateNameMutationFn = Apollo.MutationFunction<
  UpdateNameMutation,
  UpdateNameMutationVariables
>;

/**
 * __useUpdateNameMutation__
 *
 * To run a mutation, you first call `useUpdateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNameMutation, { data, loading, error }] = useUpdateNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateNameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNameMutation, UpdateNameMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNameMutation, UpdateNameMutationVariables>(
    UpdateNameDocument,
    options,
  );
}
export type UpdateNameMutationHookResult = ReturnType<typeof useUpdateNameMutation>;
export type UpdateNameMutationResult = Apollo.MutationResult<UpdateNameMutation>;
export type UpdateNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateNameMutation,
  UpdateNameMutationVariables
>;
export const UpdatePhoneNumberDocument = gql`
  mutation UpdatePhoneNumber($phoneNumber: String!) {
    updatePhoneNumber(phoneNumber: $phoneNumber) {
      ... on User {
        __typename
        id
        email
        phoneNumber
        fcmToken
        name
        permissions {
          __typename
          id
          roles
          scopes
          venue {
            __typename
            id
            name
            image
            timestamp {
              createdAt
              updatedAt
            }
          }
        }
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>;

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>(
    UpdatePhoneNumberDocument,
    options,
  );
}
export type UpdatePhoneNumberMutationHookResult = ReturnType<typeof useUpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationResult = Apollo.MutationResult<UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>;
export const GetVenueContainerDocument = gql`
  query GetVenueContainer($venueId: String!) {
    venue(venueId: $venueId) {
      id
      name
      image
      phoneNumber
      hasActivePaidSubscription
      bookingFeatureType
      hasActivePaidSubscription
      hasActiveLead
      isActive
      coordinates {
        lat
        lng
        radius
      }
      timestamp {
        createdAt
        updatedAt
      }
      details {
        weeklyOpen
        type
        capacity
        startsAt
        endsAt
      }
      contactUser {
        __typename
        id
        isVerified
        email
      }
    }
  }
`;

/**
 * __useGetVenueContainerQuery__
 *
 * To run a query within a React component, call `useGetVenueContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueContainerQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useGetVenueContainerQuery(
  baseOptions: Apollo.QueryHookOptions<GetVenueContainerQuery, GetVenueContainerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVenueContainerQuery, GetVenueContainerQueryVariables>(
    GetVenueContainerDocument,
    options,
  );
}
export function useGetVenueContainerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVenueContainerQuery,
    GetVenueContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVenueContainerQuery, GetVenueContainerQueryVariables>(
    GetVenueContainerDocument,
    options,
  );
}
export type GetVenueContainerQueryHookResult = ReturnType<typeof useGetVenueContainerQuery>;
export type GetVenueContainerLazyQueryHookResult = ReturnType<typeof useGetVenueContainerLazyQuery>;
export type GetVenueContainerQueryResult = Apollo.QueryResult<
  GetVenueContainerQuery,
  GetVenueContainerQueryVariables
>;
export const HelpStreamDocument = gql`
  query HelpStream($venueId: String!, $cursor: String) {
    helpStream(venueId: $venueId, cursor: $cursor) {
      __typename
      ... on PaginatedHelpStreamResponse {
        total
        nextCursor
        items {
          id
          location
          position
          person
          situation
          date
        }
      }
    }
  }
`;

/**
 * __useHelpStreamQuery__
 *
 * To run a query within a React component, call `useHelpStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpStreamQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useHelpStreamQuery(
  baseOptions: Apollo.QueryHookOptions<HelpStreamQuery, HelpStreamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HelpStreamQuery, HelpStreamQueryVariables>(HelpStreamDocument, options);
}
export function useHelpStreamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HelpStreamQuery, HelpStreamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HelpStreamQuery, HelpStreamQueryVariables>(
    HelpStreamDocument,
    options,
  );
}
export type HelpStreamQueryHookResult = ReturnType<typeof useHelpStreamQuery>;
export type HelpStreamLazyQueryHookResult = ReturnType<typeof useHelpStreamLazyQuery>;
export type HelpStreamQueryResult = Apollo.QueryResult<HelpStreamQuery, HelpStreamQueryVariables>;
export const BookingsByVenueDocument = gql`
  query BookingsByVenue($venueId: String!, $pagination: PaginationInput!) {
    bookingsByVenue(venueId: $venueId, pagination: $pagination) {
      __typename
      ... on ErrorReturn {
        message
      }
      ... on PaginatedBookingResponse {
        items {
          type
          featureType
          status
          id
          startsAt
          endsAt
        }
        total
        hasMore
      }
    }
  }
`;

/**
 * __useBookingsByVenueQuery__
 *
 * To run a query within a React component, call `useBookingsByVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsByVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsByVenueQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBookingsByVenueQuery(
  baseOptions: Apollo.QueryHookOptions<BookingsByVenueQuery, BookingsByVenueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingsByVenueQuery, BookingsByVenueQueryVariables>(
    BookingsByVenueDocument,
    options,
  );
}
export function useBookingsByVenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingsByVenueQuery, BookingsByVenueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingsByVenueQuery, BookingsByVenueQueryVariables>(
    BookingsByVenueDocument,
    options,
  );
}
export type BookingsByVenueQueryHookResult = ReturnType<typeof useBookingsByVenueQuery>;
export type BookingsByVenueLazyQueryHookResult = ReturnType<typeof useBookingsByVenueLazyQuery>;
export type BookingsByVenueQueryResult = Apollo.QueryResult<
  BookingsByVenueQuery,
  BookingsByVenueQueryVariables
>;
export const CreateQrCodeDynamicDocument = gql`
  mutation createQRCodeDynamic($venueId: String!, $receiver: String!) {
    createQRCodeDynamic(venueId: $venueId, receiver: $receiver) {
      __typename
      id
    }
  }
`;
export type CreateQrCodeDynamicMutationFn = Apollo.MutationFunction<
  CreateQrCodeDynamicMutation,
  CreateQrCodeDynamicMutationVariables
>;

/**
 * __useCreateQrCodeDynamicMutation__
 *
 * To run a mutation, you first call `useCreateQrCodeDynamicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQrCodeDynamicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQrCodeDynamicMutation, { data, loading, error }] = useCreateQrCodeDynamicMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      receiver: // value for 'receiver'
 *   },
 * });
 */
export function useCreateQrCodeDynamicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQrCodeDynamicMutation,
    CreateQrCodeDynamicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateQrCodeDynamicMutation, CreateQrCodeDynamicMutationVariables>(
    CreateQrCodeDynamicDocument,
    options,
  );
}
export type CreateQrCodeDynamicMutationHookResult = ReturnType<
  typeof useCreateQrCodeDynamicMutation
>;
export type CreateQrCodeDynamicMutationResult = Apollo.MutationResult<CreateQrCodeDynamicMutation>;
export type CreateQrCodeDynamicMutationOptions = Apollo.BaseMutationOptions<
  CreateQrCodeDynamicMutation,
  CreateQrCodeDynamicMutationVariables
>;
export const QrCodeDynamicsByVenueIdDocument = gql`
  query QrCodeDynamicsByVenueId($venueId: String!) {
    venue(venueId: $venueId) {
      id
      __typename
      active {
        feedback {
          text {
            __typename
            de
            en
          }
          button {
            __typename
            de
            en
          }
          link
        }
      }
    }
    qrCodeDynamicsByVenueId(venueId: $venueId) {
      __typename
      ... on PaginatedQRCodeDynamicResponse {
        items {
          id
          rawJson
          qrCode {
            id
            notifications {
              id
              enabled
              notificationChannels {
                id
                receiver
                type
                errorAt
                enabled
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useQrCodeDynamicsByVenueIdQuery__
 *
 * To run a query within a React component, call `useQrCodeDynamicsByVenueIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodeDynamicsByVenueIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodeDynamicsByVenueIdQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useQrCodeDynamicsByVenueIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QrCodeDynamicsByVenueIdQuery,
    QrCodeDynamicsByVenueIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QrCodeDynamicsByVenueIdQuery, QrCodeDynamicsByVenueIdQueryVariables>(
    QrCodeDynamicsByVenueIdDocument,
    options,
  );
}
export function useQrCodeDynamicsByVenueIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QrCodeDynamicsByVenueIdQuery,
    QrCodeDynamicsByVenueIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QrCodeDynamicsByVenueIdQuery, QrCodeDynamicsByVenueIdQueryVariables>(
    QrCodeDynamicsByVenueIdDocument,
    options,
  );
}
export type QrCodeDynamicsByVenueIdQueryHookResult = ReturnType<
  typeof useQrCodeDynamicsByVenueIdQuery
>;
export type QrCodeDynamicsByVenueIdLazyQueryHookResult = ReturnType<
  typeof useQrCodeDynamicsByVenueIdLazyQuery
>;
export type QrCodeDynamicsByVenueIdQueryResult = Apollo.QueryResult<
  QrCodeDynamicsByVenueIdQuery,
  QrCodeDynamicsByVenueIdQueryVariables
>;
export const ToggleVenueActiveFeedbackDocument = gql`
  mutation ToggleVenueActiveFeedback($venueId: String!) {
    toggleVenueActiveFeedback(venueId: $venueId) {
      id
      __typename
      active {
        feedback {
          text {
            de
            en
            __typename
          }
          button {
            de
            en
            __typename
          }
          link
        }
      }
    }
  }
`;
export type ToggleVenueActiveFeedbackMutationFn = Apollo.MutationFunction<
  ToggleVenueActiveFeedbackMutation,
  ToggleVenueActiveFeedbackMutationVariables
>;

/**
 * __useToggleVenueActiveFeedbackMutation__
 *
 * To run a mutation, you first call `useToggleVenueActiveFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleVenueActiveFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleVenueActiveFeedbackMutation, { data, loading, error }] = useToggleVenueActiveFeedbackMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useToggleVenueActiveFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleVenueActiveFeedbackMutation,
    ToggleVenueActiveFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleVenueActiveFeedbackMutation,
    ToggleVenueActiveFeedbackMutationVariables
  >(ToggleVenueActiveFeedbackDocument, options);
}
export type ToggleVenueActiveFeedbackMutationHookResult = ReturnType<
  typeof useToggleVenueActiveFeedbackMutation
>;
export type ToggleVenueActiveFeedbackMutationResult =
  Apollo.MutationResult<ToggleVenueActiveFeedbackMutation>;
export type ToggleVenueActiveFeedbackMutationOptions = Apollo.BaseMutationOptions<
  ToggleVenueActiveFeedbackMutation,
  ToggleVenueActiveFeedbackMutationVariables
>;
export const UpdateNotificationChannelDocument = gql`
  mutation UpdateNotificationChannel($input: UpdateNotificationChannelInput!) {
    updateNotificationChannel(input: $input) {
      id
      type
      receiver
      enabled
    }
  }
`;
export type UpdateNotificationChannelMutationFn = Apollo.MutationFunction<
  UpdateNotificationChannelMutation,
  UpdateNotificationChannelMutationVariables
>;

/**
 * __useUpdateNotificationChannelMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationChannelMutation, { data, loading, error }] = useUpdateNotificationChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationChannelMutation,
    UpdateNotificationChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationChannelMutation,
    UpdateNotificationChannelMutationVariables
  >(UpdateNotificationChannelDocument, options);
}
export type UpdateNotificationChannelMutationHookResult = ReturnType<
  typeof useUpdateNotificationChannelMutation
>;
export type UpdateNotificationChannelMutationResult =
  Apollo.MutationResult<UpdateNotificationChannelMutation>;
export type UpdateNotificationChannelMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationChannelMutation,
  UpdateNotificationChannelMutationVariables
>;
export const UpdateStepsDocument = gql`
  mutation UpdateSteps($id: String!, $steps: String!) {
    updateSteps(id: $id, steps: $steps) {
      id
    }
  }
`;
export type UpdateStepsMutationFn = Apollo.MutationFunction<
  UpdateStepsMutation,
  UpdateStepsMutationVariables
>;

/**
 * __useUpdateStepsMutation__
 *
 * To run a mutation, you first call `useUpdateStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStepsMutation, { data, loading, error }] = useUpdateStepsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useUpdateStepsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateStepsMutation, UpdateStepsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStepsMutation, UpdateStepsMutationVariables>(
    UpdateStepsDocument,
    options,
  );
}
export type UpdateStepsMutationHookResult = ReturnType<typeof useUpdateStepsMutation>;
export type UpdateStepsMutationResult = Apollo.MutationResult<UpdateStepsMutation>;
export type UpdateStepsMutationOptions = Apollo.BaseMutationOptions<
  UpdateStepsMutation,
  UpdateStepsMutationVariables
>;
export const UpdateVenueActiveFeedbackDocument = gql`
  mutation UpdateVenueActiveFeedback($input: UpdateVenueActiveFeedbackInput!, $venueId: String!) {
    updateVenueActiveFeedback(input: $input, venueId: $venueId) {
      id
      __typename
      active {
        feedback {
          text {
            de
            en
          }
          button {
            de
            en
          }
          link
        }
      }
    }
  }
`;
export type UpdateVenueActiveFeedbackMutationFn = Apollo.MutationFunction<
  UpdateVenueActiveFeedbackMutation,
  UpdateVenueActiveFeedbackMutationVariables
>;

/**
 * __useUpdateVenueActiveFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateVenueActiveFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueActiveFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueActiveFeedbackMutation, { data, loading, error }] = useUpdateVenueActiveFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useUpdateVenueActiveFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVenueActiveFeedbackMutation,
    UpdateVenueActiveFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVenueActiveFeedbackMutation,
    UpdateVenueActiveFeedbackMutationVariables
  >(UpdateVenueActiveFeedbackDocument, options);
}
export type UpdateVenueActiveFeedbackMutationHookResult = ReturnType<
  typeof useUpdateVenueActiveFeedbackMutation
>;
export type UpdateVenueActiveFeedbackMutationResult =
  Apollo.MutationResult<UpdateVenueActiveFeedbackMutation>;
export type UpdateVenueActiveFeedbackMutationOptions = Apollo.BaseMutationOptions<
  UpdateVenueActiveFeedbackMutation,
  UpdateVenueActiveFeedbackMutationVariables
>;
export const CreateMultipleLocationsDocument = gql`
  mutation CreateMultipleLocations($venueId: String!, $names: [String!]!) {
    createMultipleLocations(venueId: $venueId, names: $names) {
      ... on LocationList {
        locations {
          __typename
          id
          name
        }
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type CreateMultipleLocationsMutationFn = Apollo.MutationFunction<
  CreateMultipleLocationsMutation,
  CreateMultipleLocationsMutationVariables
>;

/**
 * __useCreateMultipleLocationsMutation__
 *
 * To run a mutation, you first call `useCreateMultipleLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipleLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipleLocationsMutation, { data, loading, error }] = useCreateMultipleLocationsMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      names: // value for 'names'
 *   },
 * });
 */
export function useCreateMultipleLocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMultipleLocationsMutation,
    CreateMultipleLocationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMultipleLocationsMutation,
    CreateMultipleLocationsMutationVariables
  >(CreateMultipleLocationsDocument, options);
}
export type CreateMultipleLocationsMutationHookResult = ReturnType<
  typeof useCreateMultipleLocationsMutation
>;
export type CreateMultipleLocationsMutationResult =
  Apollo.MutationResult<CreateMultipleLocationsMutation>;
export type CreateMultipleLocationsMutationOptions = Apollo.BaseMutationOptions<
  CreateMultipleLocationsMutation,
  CreateMultipleLocationsMutationVariables
>;
export const DeletePositionDocument = gql`
  mutation DeletePosition($id: String!) {
    deletePosition(id: $id) {
      ... on SuccessReturn {
        __typename
        success
      }
      ... on ErrorReturn {
        __typename
        message
      }
    }
  }
`;
export type DeletePositionMutationFn = Apollo.MutationFunction<
  DeletePositionMutation,
  DeletePositionMutationVariables
>;

/**
 * __useDeletePositionMutation__
 *
 * To run a mutation, you first call `useDeletePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePositionMutation, { data, loading, error }] = useDeletePositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePositionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePositionMutation, DeletePositionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePositionMutation, DeletePositionMutationVariables>(
    DeletePositionDocument,
    options,
  );
}
export type DeletePositionMutationHookResult = ReturnType<typeof useDeletePositionMutation>;
export type DeletePositionMutationResult = Apollo.MutationResult<DeletePositionMutation>;
export type DeletePositionMutationOptions = Apollo.BaseMutationOptions<
  DeletePositionMutation,
  DeletePositionMutationVariables
>;
export const EditPositionDocument = gql`
  mutation EditPosition($id: String!, $name: String, $venueId: String!) {
    editPosition(id: $id, name: $name, venueId: $venueId) {
      __typename
      ... on ErrorReturn {
        message
      }
      ... on Position {
        id
        name
        coordinates {
          lat
          lng
        }
      }
    }
  }
`;
export type EditPositionMutationFn = Apollo.MutationFunction<
  EditPositionMutation,
  EditPositionMutationVariables
>;

/**
 * __useEditPositionMutation__
 *
 * To run a mutation, you first call `useEditPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPositionMutation, { data, loading, error }] = useEditPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useEditPositionMutation(
  baseOptions?: Apollo.MutationHookOptions<EditPositionMutation, EditPositionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPositionMutation, EditPositionMutationVariables>(
    EditPositionDocument,
    options,
  );
}
export type EditPositionMutationHookResult = ReturnType<typeof useEditPositionMutation>;
export type EditPositionMutationResult = Apollo.MutationResult<EditPositionMutation>;
export type EditPositionMutationOptions = Apollo.BaseMutationOptions<
  EditPositionMutation,
  EditPositionMutationVariables
>;
export const EditPositionLocationDocument = gql`
  mutation EditPositionLocation($locationId: String!, $positionId: String!) {
    editPositionLocation(locationId: $locationId, positionId: $positionId) {
      __typename
      ... on Position {
        __typename
        id
        location {
          __typename
          id
        }
      }
    }
  }
`;
export type EditPositionLocationMutationFn = Apollo.MutationFunction<
  EditPositionLocationMutation,
  EditPositionLocationMutationVariables
>;

/**
 * __useEditPositionLocationMutation__
 *
 * To run a mutation, you first call `useEditPositionLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPositionLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPositionLocationMutation, { data, loading, error }] = useEditPositionLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useEditPositionLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditPositionLocationMutation,
    EditPositionLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPositionLocationMutation, EditPositionLocationMutationVariables>(
    EditPositionLocationDocument,
    options,
  );
}
export type EditPositionLocationMutationHookResult = ReturnType<
  typeof useEditPositionLocationMutation
>;
export type EditPositionLocationMutationResult =
  Apollo.MutationResult<EditPositionLocationMutation>;
export type EditPositionLocationMutationOptions = Apollo.BaseMutationOptions<
  EditPositionLocationMutation,
  EditPositionLocationMutationVariables
>;
export const UpdateVenueCoordinatesDocument = gql`
  mutation UpdateVenueCoordinates($lat: Float!, $lng: Float!, $radius: Float, $venueId: String!) {
    updateVenueCoordinates(lat: $lat, lng: $lng, radius: $radius, venueId: $venueId) {
      __typename
      id
      coordinates {
        lat
        lng
        radius
      }
    }
  }
`;
export type UpdateVenueCoordinatesMutationFn = Apollo.MutationFunction<
  UpdateVenueCoordinatesMutation,
  UpdateVenueCoordinatesMutationVariables
>;

/**
 * __useUpdateVenueCoordinatesMutation__
 *
 * To run a mutation, you first call `useUpdateVenueCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueCoordinatesMutation, { data, loading, error }] = useUpdateVenueCoordinatesMutation({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      radius: // value for 'radius'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useUpdateVenueCoordinatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVenueCoordinatesMutation,
    UpdateVenueCoordinatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVenueCoordinatesMutation,
    UpdateVenueCoordinatesMutationVariables
  >(UpdateVenueCoordinatesDocument, options);
}
export type UpdateVenueCoordinatesMutationHookResult = ReturnType<
  typeof useUpdateVenueCoordinatesMutation
>;
export type UpdateVenueCoordinatesMutationResult =
  Apollo.MutationResult<UpdateVenueCoordinatesMutation>;
export type UpdateVenueCoordinatesMutationOptions = Apollo.BaseMutationOptions<
  UpdateVenueCoordinatesMutation,
  UpdateVenueCoordinatesMutationVariables
>;
export const GetVenueLocationsDocument = gql`
  query GetVenueLocations($venueId: String!) {
    venue(venueId: $venueId) {
      __typename
      id
      slug
      geofencing {
        ..._GeofencingMap
      }
      coordinates {
        lat
        lng
        radius
      }
      locations {
        ..._GeofencingMapLocation
      }
    }
  }
  ${_GeofencingMapFragmentDoc}
  ${_GeofencingMapLocationFragmentDoc}
`;

/**
 * __useGetVenueLocationsQuery__
 *
 * To run a query within a React component, call `useGetVenueLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueLocationsQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useGetVenueLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetVenueLocationsQuery, GetVenueLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVenueLocationsQuery, GetVenueLocationsQueryVariables>(
    GetVenueLocationsDocument,
    options,
  );
}
export function useGetVenueLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVenueLocationsQuery,
    GetVenueLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVenueLocationsQuery, GetVenueLocationsQueryVariables>(
    GetVenueLocationsDocument,
    options,
  );
}
export type GetVenueLocationsQueryHookResult = ReturnType<typeof useGetVenueLocationsQuery>;
export type GetVenueLocationsLazyQueryHookResult = ReturnType<typeof useGetVenueLocationsLazyQuery>;
export type GetVenueLocationsQueryResult = Apollo.QueryResult<
  GetVenueLocationsQuery,
  GetVenueLocationsQueryVariables
>;
export const SetVenueGeofenceSettingsDocument = gql`
  mutation SetVenueGeofenceSettings($input: SetVenueGeofenceSettingsInput!) {
    setVenueGeofenceSettings(input: $input) {
      __typename
      ... on Geofencing {
        requirePosition
        requirePositionForVenue
        requirePositionForQRCodes
      }
    }
  }
`;
export type SetVenueGeofenceSettingsMutationFn = Apollo.MutationFunction<
  SetVenueGeofenceSettingsMutation,
  SetVenueGeofenceSettingsMutationVariables
>;

/**
 * __useSetVenueGeofenceSettingsMutation__
 *
 * To run a mutation, you first call `useSetVenueGeofenceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVenueGeofenceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVenueGeofenceSettingsMutation, { data, loading, error }] = useSetVenueGeofenceSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetVenueGeofenceSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVenueGeofenceSettingsMutation,
    SetVenueGeofenceSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetVenueGeofenceSettingsMutation,
    SetVenueGeofenceSettingsMutationVariables
  >(SetVenueGeofenceSettingsDocument, options);
}
export type SetVenueGeofenceSettingsMutationHookResult = ReturnType<
  typeof useSetVenueGeofenceSettingsMutation
>;
export type SetVenueGeofenceSettingsMutationResult =
  Apollo.MutationResult<SetVenueGeofenceSettingsMutation>;
export type SetVenueGeofenceSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetVenueGeofenceSettingsMutation,
  SetVenueGeofenceSettingsMutationVariables
>;
export const SetVenueGeofenceDocument = gql`
  mutation SetVenueGeofence($input: SetVenueGeofenceInput!) {
    setVenueGeofence(input: $input) {
      __typename
      ... on Geofencing {
        requirePosition
        requirePositionForVenue
        requirePositionForQRCodes
        polygons {
          coordinates {
            lat
            lng
          }
        }
        circles {
          center {
            lat
            lng
          }
          radius
        }
      }
    }
  }
`;
export type SetVenueGeofenceMutationFn = Apollo.MutationFunction<
  SetVenueGeofenceMutation,
  SetVenueGeofenceMutationVariables
>;

/**
 * __useSetVenueGeofenceMutation__
 *
 * To run a mutation, you first call `useSetVenueGeofenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVenueGeofenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVenueGeofenceMutation, { data, loading, error }] = useSetVenueGeofenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetVenueGeofenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVenueGeofenceMutation,
    SetVenueGeofenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetVenueGeofenceMutation, SetVenueGeofenceMutationVariables>(
    SetVenueGeofenceDocument,
    options,
  );
}
export type SetVenueGeofenceMutationHookResult = ReturnType<typeof useSetVenueGeofenceMutation>;
export type SetVenueGeofenceMutationResult = Apollo.MutationResult<SetVenueGeofenceMutation>;
export type SetVenueGeofenceMutationOptions = Apollo.BaseMutationOptions<
  SetVenueGeofenceMutation,
  SetVenueGeofenceMutationVariables
>;
export const GetVenuePrintPageDocument = gql`
  query GetVenuePrintPage($venueId: String!) {
    venue(venueId: $venueId) {
      __typename
      id
      locations {
        __typename
        id
        name
        positions {
          __typename
          id
          name
          qrCodes {
            __typename
            id
            qrCode {
              ... on QRCodeHelp {
                __typename
                id
              }
              ... on QRCodeInternalChat {
                __typename
                id
              }
              ... on QRCodeDynamic {
                __typename
                id
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetVenuePrintPageQuery__
 *
 * To run a query within a React component, call `useGetVenuePrintPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenuePrintPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenuePrintPageQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useGetVenuePrintPageQuery(
  baseOptions: Apollo.QueryHookOptions<GetVenuePrintPageQuery, GetVenuePrintPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVenuePrintPageQuery, GetVenuePrintPageQueryVariables>(
    GetVenuePrintPageDocument,
    options,
  );
}
export function useGetVenuePrintPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVenuePrintPageQuery,
    GetVenuePrintPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVenuePrintPageQuery, GetVenuePrintPageQueryVariables>(
    GetVenuePrintPageDocument,
    options,
  );
}
export type GetVenuePrintPageQueryHookResult = ReturnType<typeof useGetVenuePrintPageQuery>;
export type GetVenuePrintPageLazyQueryHookResult = ReturnType<typeof useGetVenuePrintPageLazyQuery>;
export type GetVenuePrintPageQueryResult = Apollo.QueryResult<
  GetVenuePrintPageQuery,
  GetVenuePrintPageQueryVariables
>;
export const CreatePrintJobDocument = gql`
  mutation CreatePrintJob($input: CreatePrintJobInput!) {
    createPrintJob(input: $input) {
      ...PrintJobComponent
    }
  }
  ${PrintJobComponentFragmentDoc}
`;
export type CreatePrintJobMutationFn = Apollo.MutationFunction<
  CreatePrintJobMutation,
  CreatePrintJobMutationVariables
>;

/**
 * __useCreatePrintJobMutation__
 *
 * To run a mutation, you first call `useCreatePrintJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrintJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrintJobMutation, { data, loading, error }] = useCreatePrintJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrintJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePrintJobMutation, CreatePrintJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePrintJobMutation, CreatePrintJobMutationVariables>(
    CreatePrintJobDocument,
    options,
  );
}
export type CreatePrintJobMutationHookResult = ReturnType<typeof useCreatePrintJobMutation>;
export type CreatePrintJobMutationResult = Apollo.MutationResult<CreatePrintJobMutation>;
export type CreatePrintJobMutationOptions = Apollo.BaseMutationOptions<
  CreatePrintJobMutation,
  CreatePrintJobMutationVariables
>;
export const RetryPrintJobDocument = gql`
  mutation RetryPrintJob($id: String!) {
    retryPrintJob(id: $id) {
      ...PrintJobComponent
    }
  }
  ${PrintJobComponentFragmentDoc}
`;
export type RetryPrintJobMutationFn = Apollo.MutationFunction<
  RetryPrintJobMutation,
  RetryPrintJobMutationVariables
>;

/**
 * __useRetryPrintJobMutation__
 *
 * To run a mutation, you first call `useRetryPrintJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryPrintJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryPrintJobMutation, { data, loading, error }] = useRetryPrintJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetryPrintJobMutation(
  baseOptions?: Apollo.MutationHookOptions<RetryPrintJobMutation, RetryPrintJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RetryPrintJobMutation, RetryPrintJobMutationVariables>(
    RetryPrintJobDocument,
    options,
  );
}
export type RetryPrintJobMutationHookResult = ReturnType<typeof useRetryPrintJobMutation>;
export type RetryPrintJobMutationResult = Apollo.MutationResult<RetryPrintJobMutation>;
export type RetryPrintJobMutationOptions = Apollo.BaseMutationOptions<
  RetryPrintJobMutation,
  RetryPrintJobMutationVariables
>;
export const GetVenueSettingsDocument = gql`
  query GetVenueSettings($venueId: String!) {
    venue(venueId: $venueId) {
      __typename
      id
      settings {
        ...VenueSettings
      }
      active {
        ..._VenueActiveSettings
      }
      mayToggleActivate
    }
    getVenueTheme(venueId: $venueId) {
      __typename
      id
      localizations {
        de {
          ...Translations
        }
        en {
          ...Translations
        }
      }
    }
  }
  ${VenueSettingsFragmentDoc}
  ${_VenueActiveSettingsFragmentDoc}
  ${TranslationsFragmentDoc}
`;

/**
 * __useGetVenueSettingsQuery__
 *
 * To run a query within a React component, call `useGetVenueSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueSettingsQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useGetVenueSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetVenueSettingsQuery, GetVenueSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVenueSettingsQuery, GetVenueSettingsQueryVariables>(
    GetVenueSettingsDocument,
    options,
  );
}
export function useGetVenueSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVenueSettingsQuery, GetVenueSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVenueSettingsQuery, GetVenueSettingsQueryVariables>(
    GetVenueSettingsDocument,
    options,
  );
}
export type GetVenueSettingsQueryHookResult = ReturnType<typeof useGetVenueSettingsQuery>;
export type GetVenueSettingsLazyQueryHookResult = ReturnType<typeof useGetVenueSettingsLazyQuery>;
export type GetVenueSettingsQueryResult = Apollo.QueryResult<
  GetVenueSettingsQuery,
  GetVenueSettingsQueryVariables
>;
export const EditVenueSettingsInactivityTimeoutDocument = gql`
  mutation EditVenueSettingsInactivityTimeout($inactivityTimeout: Int!, $venueId: String!) {
    editVenueSettingsInactivityTimeout(inactivityTimeout: $inactivityTimeout, venueId: $venueId) {
      id
      __typename
      settings {
        showQuestions
        hasChat
        enablePositionSharing
        requirePosition
        requirePositionForVenue
        requirePositionForQRCodes
        isWhiteLabel
        allowDirectChat
        inactivityTimeout
      }
    }
  }
`;
export type EditVenueSettingsInactivityTimeoutMutationFn = Apollo.MutationFunction<
  EditVenueSettingsInactivityTimeoutMutation,
  EditVenueSettingsInactivityTimeoutMutationVariables
>;

/**
 * __useEditVenueSettingsInactivityTimeoutMutation__
 *
 * To run a mutation, you first call `useEditVenueSettingsInactivityTimeoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVenueSettingsInactivityTimeoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVenueSettingsInactivityTimeoutMutation, { data, loading, error }] = useEditVenueSettingsInactivityTimeoutMutation({
 *   variables: {
 *      inactivityTimeout: // value for 'inactivityTimeout'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useEditVenueSettingsInactivityTimeoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditVenueSettingsInactivityTimeoutMutation,
    EditVenueSettingsInactivityTimeoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditVenueSettingsInactivityTimeoutMutation,
    EditVenueSettingsInactivityTimeoutMutationVariables
  >(EditVenueSettingsInactivityTimeoutDocument, options);
}
export type EditVenueSettingsInactivityTimeoutMutationHookResult = ReturnType<
  typeof useEditVenueSettingsInactivityTimeoutMutation
>;
export type EditVenueSettingsInactivityTimeoutMutationResult =
  Apollo.MutationResult<EditVenueSettingsInactivityTimeoutMutation>;
export type EditVenueSettingsInactivityTimeoutMutationOptions = Apollo.BaseMutationOptions<
  EditVenueSettingsInactivityTimeoutMutation,
  EditVenueSettingsInactivityTimeoutMutationVariables
>;
export const UpdateVenueThemeTextBulkDocument = gql`
  mutation UpdateVenueThemeTextBulk(
    $venueId: String!
    $language: String!
    $input: [UpdateVenueThemeTextInput!]!
  ) {
    updateVenueThemeTextBulk(venueId: $venueId, input: $input) {
      id
      __typename
      texts(language: $language) {
        ...Translations
      }
    }
  }
  ${TranslationsFragmentDoc}
`;
export type UpdateVenueThemeTextBulkMutationFn = Apollo.MutationFunction<
  UpdateVenueThemeTextBulkMutation,
  UpdateVenueThemeTextBulkMutationVariables
>;

/**
 * __useUpdateVenueThemeTextBulkMutation__
 *
 * To run a mutation, you first call `useUpdateVenueThemeTextBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueThemeTextBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueThemeTextBulkMutation, { data, loading, error }] = useUpdateVenueThemeTextBulkMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      language: // value for 'language'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVenueThemeTextBulkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVenueThemeTextBulkMutation,
    UpdateVenueThemeTextBulkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVenueThemeTextBulkMutation,
    UpdateVenueThemeTextBulkMutationVariables
  >(UpdateVenueThemeTextBulkDocument, options);
}
export type UpdateVenueThemeTextBulkMutationHookResult = ReturnType<
  typeof useUpdateVenueThemeTextBulkMutation
>;
export type UpdateVenueThemeTextBulkMutationResult =
  Apollo.MutationResult<UpdateVenueThemeTextBulkMutation>;
export type UpdateVenueThemeTextBulkMutationOptions = Apollo.BaseMutationOptions<
  UpdateVenueThemeTextBulkMutation,
  UpdateVenueThemeTextBulkMutationVariables
>;
export const SetVenueSituationsSettingsDocument = gql`
  mutation SetVenueSituationsSettings($input: SetVenueSituationsSettingsInput!) {
    setVenueSituationsSettings(input: $input) {
      __typename
      ... on VenueSettings {
        situations
      }
      ... on ErrorReturn {
        message
      }
    }
  }
`;
export type SetVenueSituationsSettingsMutationFn = Apollo.MutationFunction<
  SetVenueSituationsSettingsMutation,
  SetVenueSituationsSettingsMutationVariables
>;

/**
 * __useSetVenueSituationsSettingsMutation__
 *
 * To run a mutation, you first call `useSetVenueSituationsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVenueSituationsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVenueSituationsSettingsMutation, { data, loading, error }] = useSetVenueSituationsSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetVenueSituationsSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVenueSituationsSettingsMutation,
    SetVenueSituationsSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetVenueSituationsSettingsMutation,
    SetVenueSituationsSettingsMutationVariables
  >(SetVenueSituationsSettingsDocument, options);
}
export type SetVenueSituationsSettingsMutationHookResult = ReturnType<
  typeof useSetVenueSituationsSettingsMutation
>;
export type SetVenueSituationsSettingsMutationResult =
  Apollo.MutationResult<SetVenueSituationsSettingsMutation>;
export type SetVenueSituationsSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetVenueSituationsSettingsMutation,
  SetVenueSituationsSettingsMutationVariables
>;
export const CreatePermissionDocument = gql`
  mutation CreatePermission($input: CreatePermissionInput!) {
    createPermission(input: $input) {
      ... on ErrorReturn {
        __typename
        message
        i18nKey
      }
      ... on Permission {
        __typename
        id
        scopes
        roles
        role
        user {
          id
          email
          name
          phoneNumber
          fcmToken
          image
        }
      }
    }
  }
`;
export type CreatePermissionMutationFn = Apollo.MutationFunction<
  CreatePermissionMutation,
  CreatePermissionMutationVariables
>;

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePermissionMutation,
    CreatePermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePermissionMutation, CreatePermissionMutationVariables>(
    CreatePermissionDocument,
    options,
  );
}
export type CreatePermissionMutationHookResult = ReturnType<typeof useCreatePermissionMutation>;
export type CreatePermissionMutationResult = Apollo.MutationResult<CreatePermissionMutation>;
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<
  CreatePermissionMutation,
  CreatePermissionMutationVariables
>;
export const EditPermissionDocument = gql`
  mutation EditPermission($permissionId: String!, $role: Roles!, $venueId: String!) {
    editPermission(permissionId: $permissionId, role: $role, venueId: $venueId) {
      ... on Permission {
        __typename
        id
        role
      }
      ... on ErrorReturn {
        message
        statusCode
        i18nKey
      }
    }
  }
`;
export type EditPermissionMutationFn = Apollo.MutationFunction<
  EditPermissionMutation,
  EditPermissionMutationVariables
>;

/**
 * __useEditPermissionMutation__
 *
 * To run a mutation, you first call `useEditPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPermissionMutation, { data, loading, error }] = useEditPermissionMutation({
 *   variables: {
 *      permissionId: // value for 'permissionId'
 *      role: // value for 'role'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useEditPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<EditPermissionMutation, EditPermissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPermissionMutation, EditPermissionMutationVariables>(
    EditPermissionDocument,
    options,
  );
}
export type EditPermissionMutationHookResult = ReturnType<typeof useEditPermissionMutation>;
export type EditPermissionMutationResult = Apollo.MutationResult<EditPermissionMutation>;
export type EditPermissionMutationOptions = Apollo.BaseMutationOptions<
  EditPermissionMutation,
  EditPermissionMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email) {
      email
      id
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const DeleteUserCodeConfirmationDocument = gql`
  mutation DeleteUserCodeConfirmation($id: String!, $code: String!) {
    deleteUserCodeConfirmation(id: $id, code: $code)
  }
`;
export type DeleteUserCodeConfirmationMutationFn = Apollo.MutationFunction<
  DeleteUserCodeConfirmationMutation,
  DeleteUserCodeConfirmationMutationVariables
>;

/**
 * __useDeleteUserCodeConfirmationMutation__
 *
 * To run a mutation, you first call `useDeleteUserCodeConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCodeConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCodeConfirmationMutation, { data, loading, error }] = useDeleteUserCodeConfirmationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useDeleteUserCodeConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserCodeConfirmationMutation,
    DeleteUserCodeConfirmationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserCodeConfirmationMutation,
    DeleteUserCodeConfirmationMutationVariables
  >(DeleteUserCodeConfirmationDocument, options);
}
export type DeleteUserCodeConfirmationMutationHookResult = ReturnType<
  typeof useDeleteUserCodeConfirmationMutation
>;
export type DeleteUserCodeConfirmationMutationResult =
  Apollo.MutationResult<DeleteUserCodeConfirmationMutation>;
export type DeleteUserCodeConfirmationMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserCodeConfirmationMutation,
  DeleteUserCodeConfirmationMutationVariables
>;
export const DeleteUserTokenConfirmationDocument = gql`
  mutation DeleteUserTokenConfirmation($token: String!) {
    deleteUserTokenConfirmation(token: $token)
  }
`;
export type DeleteUserTokenConfirmationMutationFn = Apollo.MutationFunction<
  DeleteUserTokenConfirmationMutation,
  DeleteUserTokenConfirmationMutationVariables
>;

/**
 * __useDeleteUserTokenConfirmationMutation__
 *
 * To run a mutation, you first call `useDeleteUserTokenConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserTokenConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserTokenConfirmationMutation, { data, loading, error }] = useDeleteUserTokenConfirmationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDeleteUserTokenConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserTokenConfirmationMutation,
    DeleteUserTokenConfirmationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserTokenConfirmationMutation,
    DeleteUserTokenConfirmationMutationVariables
  >(DeleteUserTokenConfirmationDocument, options);
}
export type DeleteUserTokenConfirmationMutationHookResult = ReturnType<
  typeof useDeleteUserTokenConfirmationMutation
>;
export type DeleteUserTokenConfirmationMutationResult =
  Apollo.MutationResult<DeleteUserTokenConfirmationMutation>;
export type DeleteUserTokenConfirmationMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserTokenConfirmationMutation,
  DeleteUserTokenConfirmationMutationVariables
>;
export const SignInWithIdTokenDocument = gql`
  mutation SignInWithIdToken($idToken: String!) {
    signInWithIdToken(idToken: $idToken) {
      __typename
      ... on SignInWithIdTokenSuccessResult {
        accessToken
        sessionId
      }
      ... on UserNotFoundError {
        message
        statusCode
        i18nKey
      }
      ... on ErrorReturn {
        message
        statusCode
        i18nKey
      }
    }
  }
`;
export type SignInWithIdTokenMutationFn = Apollo.MutationFunction<
  SignInWithIdTokenMutation,
  SignInWithIdTokenMutationVariables
>;

/**
 * __useSignInWithIdTokenMutation__
 *
 * To run a mutation, you first call `useSignInWithIdTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithIdTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithIdTokenMutation, { data, loading, error }] = useSignInWithIdTokenMutation({
 *   variables: {
 *      idToken: // value for 'idToken'
 *   },
 * });
 */
export function useSignInWithIdTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInWithIdTokenMutation,
    SignInWithIdTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInWithIdTokenMutation, SignInWithIdTokenMutationVariables>(
    SignInWithIdTokenDocument,
    options,
  );
}
export type SignInWithIdTokenMutationHookResult = ReturnType<typeof useSignInWithIdTokenMutation>;
export type SignInWithIdTokenMutationResult = Apollo.MutationResult<SignInWithIdTokenMutation>;
export type SignInWithIdTokenMutationOptions = Apollo.BaseMutationOptions<
  SignInWithIdTokenMutation,
  SignInWithIdTokenMutationVariables
>;
export const VerifyMagicLinkDocument = gql`
  mutation VerifyMagicLink($code: String!, $uuid: String!) {
    verifyMagicLink(code: $code, uuid: $uuid) {
      ... on VerifyMagicLinkReturn {
        __typename
        accessToken
        sessionId
      }
      ... on ErrorReturn {
        __typename
        message
      }
    }
  }
`;
export type VerifyMagicLinkMutationFn = Apollo.MutationFunction<
  VerifyMagicLinkMutation,
  VerifyMagicLinkMutationVariables
>;

/**
 * __useVerifyMagicLinkMutation__
 *
 * To run a mutation, you first call `useVerifyMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMagicLinkMutation, { data, loading, error }] = useVerifyMagicLinkMutation({
 *   variables: {
 *      code: // value for 'code'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useVerifyMagicLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMagicLinkMutation,
    VerifyMagicLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyMagicLinkMutation, VerifyMagicLinkMutationVariables>(
    VerifyMagicLinkDocument,
    options,
  );
}
export type VerifyMagicLinkMutationHookResult = ReturnType<typeof useVerifyMagicLinkMutation>;
export type VerifyMagicLinkMutationResult = Apollo.MutationResult<VerifyMagicLinkMutation>;
export type VerifyMagicLinkMutationOptions = Apollo.BaseMutationOptions<
  VerifyMagicLinkMutation,
  VerifyMagicLinkMutationVariables
>;
export const SignUpWithIdTokenDocument = gql`
  mutation SignUpWithIdToken($idToken: String!) {
    signupWithIdToken(idToken: $idToken) {
      __typename
      ... on User {
        id
      }
    }
  }
`;
export type SignUpWithIdTokenMutationFn = Apollo.MutationFunction<
  SignUpWithIdTokenMutation,
  SignUpWithIdTokenMutationVariables
>;

/**
 * __useSignUpWithIdTokenMutation__
 *
 * To run a mutation, you first call `useSignUpWithIdTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWithIdTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWithIdTokenMutation, { data, loading, error }] = useSignUpWithIdTokenMutation({
 *   variables: {
 *      idToken: // value for 'idToken'
 *   },
 * });
 */
export function useSignUpWithIdTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpWithIdTokenMutation,
    SignUpWithIdTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpWithIdTokenMutation, SignUpWithIdTokenMutationVariables>(
    SignUpWithIdTokenDocument,
    options,
  );
}
export type SignUpWithIdTokenMutationHookResult = ReturnType<typeof useSignUpWithIdTokenMutation>;
export type SignUpWithIdTokenMutationResult = Apollo.MutationResult<SignUpWithIdTokenMutation>;
export type SignUpWithIdTokenMutationOptions = Apollo.BaseMutationOptions<
  SignUpWithIdTokenMutation,
  SignUpWithIdTokenMutationVariables
>;
export const SendPasswordResetLinkDocument = gql`
  mutation SendPasswordResetLink($email: String!) {
    sendPasswordResetLink(email: $email) {
      __typename
      ... on SendPasswordResetLinkSuccessResult {
        email
      }
      ... on ErrorReturn {
        message
        statusCode
        i18nKey
      }
    }
  }
`;
export type SendPasswordResetLinkMutationFn = Apollo.MutationFunction<
  SendPasswordResetLinkMutation,
  SendPasswordResetLinkMutationVariables
>;

/**
 * __useSendPasswordResetLinkMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetLinkMutation, { data, loading, error }] = useSendPasswordResetLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPasswordResetLinkMutation,
    SendPasswordResetLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>(
    SendPasswordResetLinkDocument,
    options,
  );
}
export type SendPasswordResetLinkMutationHookResult = ReturnType<
  typeof useSendPasswordResetLinkMutation
>;
export type SendPasswordResetLinkMutationResult =
  Apollo.MutationResult<SendPasswordResetLinkMutation>;
export type SendPasswordResetLinkMutationOptions = Apollo.BaseMutationOptions<
  SendPasswordResetLinkMutation,
  SendPasswordResetLinkMutationVariables
>;
