import { useEffect } from "react";

import { MeQuery, useAppRefreshMutation } from "@/generated/graphql";
import LocalStorage from "@/utils/localStorage/LocalStorage";
import SessionStorage from "@/utils/sessionStorage/SessionStorage";

const REFRESH_INTERVAL = 1000 * 60 * 5;

export const useRefresh = (me?: MeQuery["me"]) => {
  const [appRefreshMutation, result] = useAppRefreshMutation();

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const refresh = async () => {
      const currentTime = new Date().valueOf();
      const lastRefresh = SessionStorage.lastRefresh;

      if (currentTime - lastRefresh < REFRESH_INTERVAL) {
        return;
      }

      const sessionId = LocalStorage.getSessionId;

      if (sessionId) {
        try {
          const { data, errors } = await appRefreshMutation({ variables: { sessionId } });

          if (data?.appRefresh.__typename === "AppRefreshReturn") {
            SessionStorage.setLastRefresh(currentTime);
            SessionStorage.setAccesToken(data.appRefresh.accessToken);
            LocalStorage.setSessionId(data.appRefresh.sessionId);
          } else if (errors?.length) {
            console.error("Token refresh failed:", errors);
          }
        } catch (error) {
          console.error("Token refresh failed:", error);
        }
      }
    };

    if (me?.__typename === "User") {
      refresh();

      interval = setInterval(() => {
        refresh();
      }, REFRESH_INTERVAL);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [me, appRefreshMutation]);

  return result;
};
